import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ArrowForward, ArrowUpward } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {
  Alert,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_LEAD_CONTACTS, GET_LEADS_BY_STEP } from '../../../graphql/queries/lead';
import AutoCallerCard from './AutocallerCard';
import { useTwilioCall } from '../../../context/TwilioCallContext';
import useCallTimer from '../../../pages/twilio-caller/hooks/useCallTimer';
import { setTargetLeads } from '../../../redux/service.slice';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function SequenceAutoCallerCard({
  lead,
  type,
  defaultContacts = [],
  setSelectedCompanyContacts,
  selectedCompanyContacts,
}) {
  console.log('Lead....', lead);
  // const [getLeadContacts, { loading: loadingGettingContacts, data: contactsData }] = useLazyQuery(GET_LEAD_CONTACTS);

  // const [allContacts, setAllContacts] = useState(defaultContacts);

  // useEffect(() => {
  //   getLeadContacts({
  //     variables: {
  //       company: lead?.simplifiedCompanyName,
  //     },
  //   });
  // }, [lead, getLeadContacts]);

  // useEffect(() => {
  //   if (contactsData?.getLeadContacts) {
  //     setAllContacts(contactsData?.getLeadContacts);
  //   }
  // }, [contactsData?.getLeadContacts, lead, loadingGettingContacts]);

  const isCurrentLead = useMemo(() => type === 'Current Lead', [type]);
  return (
    <Card sx={{ p: 2, height: '25vh', overflowY: 'auto' }}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" spacing={2}>
          <CorporateFareIcon />
          <Typography variant="h6">{lead?.simplifiedCompanyName}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          {type === 'Current Lead' ? <ArrowUpward /> : <ArrowForward />}
          <Typography variant="h5">{type}</Typography>
        </Stack>
      </Stack>
      <Typography variant="body1">{lead?.simplifiedCompanyLocation}</Typography>
      <Typography variant="body2">{lead?.industry}</Typography>
      {isCurrentLead ? (
        <>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Contacts
          </Typography>
          <Divider />
          {lead?.contacts
            ?.filter((contact) => contact?.company === lead?.simplifiedCompanyName)
            ?.slice(0, 1)
            ?.map((contact) => (
              <Stack key={contact._id} spacing={1}>
                <Typography variant="body1">
                  <span style={{ color: '#282833', fontWeight: 600 }}>Prospect Name:</span>{' '}
                  <b>
                    {contact?.firstName} {contact?.lastName}
                  </b>
                </Typography>
                <Typography variant="body1">
                  <span style={{ color: '#282833', fontWeight: 600 }}>Prospect Number:</span>{' '}
                  <b>{contact?.prospectPhoneNumber}</b>
                </Typography>
                {contact?.prospectDesignation && (
                  <Typography variant="body1">
                    <span style={{ color: '#282833', fontWeight: 600 }}>Prospect Designation:</span>{' '}
                    <b>{contact?.prospectDesignation}</b>
                  </Typography>
                )}
                {contact?.timeZone && (
                  <Typography variant="body1">
                    <span style={{ color: '#282833', fontWeight: 600 }}>Prospect timeZone:</span>{' '}
                    <b>{contact?.timeZone}</b>
                  </Typography>
                )}
              </Stack>
            ))}
        </>
      ) : (
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Contacts</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="female"
            value={typeof selectedCompanyContacts !== 'undefined' ? selectedCompanyContacts[lead?._id] || 0 : 0}
            onChange={(e) =>
              setSelectedCompanyContacts({ ...selectedCompanyContacts, [`${lead?._id}`]: parseInt(e.target.value, 10) })
            }
            name="radio-buttons-group"
          >
            {!isCurrentLead &&
              lead?.contacts
                ?.filter((contact) => contact?.company === lead?.simplifiedCompanyName)
                ?.slice(0, 1)
                ?.map((contact, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={<Radio />}
                    label={`${contact?.firstName} ${contact?.lastName} - ${contact?.prospectDesignation} - ${contact?.prospectPhoneNumber}`}
                  />
                ))}
          </RadioGroup>
        </FormControl>
      )}
    </Card>
  );
}

const PAGE_SIZE = 50;
const PRELOAD_THRESHOLD = 1;
function SequenceAutoCaller({ open, handleClose }) {
  const dispatch = useDispatch();
  const { sequenceId } = useParams();
  const { leads, step } = useSelector((state) => state.lead);
  const [offset, setOffset] = useState(0);
  const { data, loading, error, fetchMore } = useQuery(GET_LEADS_BY_STEP, {
    variables: { step, sequenceId, limit: PAGE_SIZE, offset },
    skip: !step,
    notifyOnNetworkStatusChange: true,
  });

  const { nextCall } = useTwilioCall();
  const { startTimer, stopTimer, resetTimer, formattedTime } = useCallTimer();
  const [activeStep, setActiveStep] = useState(0);
  const [activeLeadIndex, setActiveLeadIndex] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [selectedCompanyContacts, setSelectedCompanyContacts] = useState({});
  const [activeContacts, setActiveContacts] = useState([]);
  const [activeContact, setActiveContact] = useState(null);
  const [openSequence, setOpenSequence] = useState(false);
  const [getLeadContacts, { loading: loadingGettingContacts, data: contactsData }] = useLazyQuery(GET_LEAD_CONTACTS);
  const currentLead = useMemo(() => leads[activeStep], [leads, activeStep]);
  const nextLead = useMemo(() => leads[activeStep + 1] || null, [leads, activeStep]);

  useEffect(() => {
    if (data && data.getLeadsByStep && activeStep < 1) {
      dispatch(setTargetLeads(data.getLeadsByStep));
      if (data.getLeadsByStep.length < PAGE_SIZE) {
        setIsLastStep(true);
      }
    }
  }, [data, dispatch]);

  const callNextPerson = () => {
    const remainingSteps = leads.length - (activeLeadIndex + 1);

    if (remainingSteps <= PRELOAD_THRESHOLD) {
      if (!isLastStep) {
        console.log('Leads_STATS...Fetching More Data');
        fetchMore({
          variables: {
            offset: offset + PAGE_SIZE,
            step,
            limit: PAGE_SIZE,
          },
        })
          .then(({ data }) => {
            const newLeads = data.getLeadsByStep || [];
            const combinedLeads = [...leads, ...newLeads];
            dispatch(setTargetLeads(combinedLeads));
  
            if (newLeads.length < PAGE_SIZE) {
              setIsLastStep(true);
            }
            setOffset((prevOffset) => prevOffset + PAGE_SIZE);
          })
          .catch((err) => {
            console.error('Error fetching more leads:', err);
          });
      }
    }

    if (leads[activeStep + 1]) {
      setActiveStep((prev) => prev + 1);
      setActiveLeadIndex(activeLeadIndex + 1);
      setIsLastStep(false);
      startTimer();
    } else {
      setIsLastStep(true);
    }
  };

  // useEffect(() => {
  //   if (data && data.getLeadsByStep) {
  //     dispatch(setTargetLeads(data.getLeadsByStep));
  //   }
  // }, [data, dispatch]);

  // const callNextPerson = () => {
  //   if (leads[activeStep + 1]) {
  //     setActiveStep((prev) => prev + 1);
  //     setIsLastStep(false);
  //     startTimer();
  //   } else {
  //     setIsLastStep(true);
  //   }
  // };

  const handleMoveToNext = () => {
    setOpenSequence(false);
    setActiveContacts([]);
    callNextPerson();
  };

  useEffect(() => {
    setActiveContacts([]);
    setActiveStep(0);
    setActiveLeadIndex(1);
  }, [open]);

  useEffect(() => {
    getLeadContacts({
      variables: {
        company: currentLead?.simplifiedCompanyName,
      },
    });
  }, [currentLead, getLeadContacts]);

  useEffect(() => {
    if (contactsData?.getLeadContacts) {
      setActiveContacts(contactsData?.getLeadContacts);
    }
  }, [contactsData?.getLeadContacts, loadingGettingContacts]);

  useEffect(() => {
    if (activeContacts?.length > 0) {
      setActiveContact(activeContacts[0]);
      setOpenSequence(true);
    } else {
      setActiveContact([]);
      setOpenSequence(false);
    }
  }, [activeContacts]);

  useEffect(() => {
    if (nextCall) {
      handleMoveToNext();
    }
  }, [nextCall]);

  return (
    <div>
      <BootstrapDialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Auto Dialer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* {error && <Grid item xs={12} sm={12} md={6}> <Alert severity="error"><pre>{JSON.stringify(error)}</pre></Alert></Grid>} */}
            {currentLead && (
              <Grid item xs={12} sm={12} md={6}>
                <SequenceAutoCallerCard
                  lead={currentLead}
                  type="Current Lead"
                  defaultContacts={activeContacts}
                  setSelectedCompanyContacts={setSelectedCompanyContacts}
                  selectedCompanyContacts={selectedCompanyContacts}
                />
              </Grid>
            )}
            {nextLead && (
              <Grid item xs={12} sm={12} md={6}>
                <SequenceAutoCallerCard
                  lead={nextLead}
                  type="Next Lead"
                  setSelectedCompanyContacts={setSelectedCompanyContacts}
                  selectedCompanyContacts={selectedCompanyContacts}
                />
              </Grid>
            )}
            {loadingGettingContacts ||
              (loading && (
                <Grid item xs={12} sm={12} md={12}>
                  <LinearProgress />
                </Grid>
              ))}
            {!loadingGettingContacts && !loading && (
              <Grid item xs={12} sm={12} md={12}>
                <Divider>
                  <Chip label="Active Contact" size="small" />
                </Divider>
              </Grid>
            )}
            {openSequence && activeContact && (
              <Grid item xs={12} sm={12} md={12}>
                <AutoCallerCard
                  open={openSequence}
                  leads={leads}
                  activeStep={activeStep}
                  formattedTimeParent={formattedTime}
                  stopTimerParent={stopTimer}
                  activeContact={activeContact}
                  handleMoveToNext={handleMoveToNext}
                />
              </Grid>
            )}
            {isLastStep && (
              <Stack spacing={1} direction="column" sx={{ p: 4 }}>
                <Typography variant="h5">No more leads to call 👽</Typography>
              </Stack>
            )}
            {!openSequence && activeContact && !isLastStep && !loadingGettingContacts && !loading && (
              <Stack spacing={1} direction="column" sx={{ p: 4 }}>
                <Typography variant="h5">This lead doesn't have a valid contact😢</Typography>
                <Grid item xs={12} sm={6} md={6}>
                  <Button variant="contained" onClick={handleMoveToNext} sx={{ bgcolor: '#080708' }}>
                    Skip this Contact
                  </Button>
                </Grid>
              </Stack>
            )}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

SequenceAutoCaller.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SequenceAutoCaller;
