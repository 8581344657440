import { Helmet } from 'react-helmet-async';
// @mui
import {
  Badge,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { AttachFile, CheckCircle } from '@mui/icons-material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections

import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Loader from '../components/loader';
import { GET_ALL_SEQUENCE, UPDATE_SEQUENCE } from '../graphql/queries/sequence';
import { LABELS, SEQUENCE_TEMPLATE } from '../utils/constants';
import TemplateDrawer from '../sections/@dashboard/sequence/TemplateDrawer';
import { transformTemplates } from '../utils/helperFunctions';

export default function ManageSequencePage() {
  const { enqueueSnackbar } = useSnackbar();
  const { sequenceId, sequenceTitle, totalSteps } = useParams();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { data: sequenceData, loading: loadingSequenceData } = useQuery(GET_ALL_SEQUENCE, {
    variables: { sequenceId },
    skip: !sequenceId,
    onError: (error) => {
      enqueueSnackbar('Failed to load sequence data.', { variant: 'error' });
      console.error('Error loading sequence data:', error);
    },
  });

  const [updateSequenceMultiple, { data: updateData, loading: loadingUpdate }] = useMutation(UPDATE_SEQUENCE);
  const connnectTypeOptions = [
    {
      value: 'LINKEDINCONNECT',
      label: 'LinkedIn Connect',
    },
    {
      value: 'LINKEDINMESSAGE',
      label: 'LinkedIn Message',
    },
    {
      value: 'EMAIL',
      label: 'Email',
    },
    {
      value: 'CALL',
      label: 'Call',
    },
    {
      value: 'SMS',
      label: 'Sms',
    },
    {
      value: 'SLACK',
      label: 'Slack',
    },
    {
      value: 'VOICEMAIL',
      label: 'Voicemail',
    },
    {
      value: 'SOCIAL',
      label: 'Social',
    },
  ];

  const [data, setData] = useState([
    { step: 1, stage: 'Stage 1', title: 'Title 1', description: 'Description 1', type: 'LINKEDINCONNECT' },
    { step: 2, stage: 'Stage 2', title: 'Title 2', description: 'Description 2', type: 'EMAIL' },
    // Add more initial data as needed
  ]);

  const handleClick = (event) => {
    event.preventDefault();

    navigate(`/sequence/sequence-overview/${sequenceId}/${sequenceTitle}/${totalSteps}`);
  };

  const handleEdit = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const handleUseTemplate = () => setData(SEQUENCE_TEMPLATE);

  const handleAddRow = () => {
    const newRow = {
      _id: String(data.length + 1),
      step: data?.length + 1,
      stage: '',
      title: '',
      description: '',
      type: '',
    };
    setData([...data, newRow]);
  };

  const handleDeleteRow = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };
  

  useEffect(() => {
    if (sequenceData?.getSequence?.length) {
      const data = sequenceData.getSequence.map(({ _id, __typename, ...rest }) => rest);
  
      const attachedTemplates = sequenceData?.getSequence?.[0]?.sequenceTemplate?.steps || [];
      const transformedTemplates = transformTemplates(attachedTemplates);
      setData(data);
      setSelectedTemplates(transformedTemplates);
    }
  }, [sequenceData?.getSequence]);
  

  useEffect(() => {
    if (updateData?.updateSequenceMultiple) {
      enqueueSnackbar('Sequence updated successfully', { variant: 'success' });
      const data = updateData?.updateSequenceMultiple.map(({ _id, __typename, ...rest }) => rest);
      if (data?.length) {
        setData(data);
      }
    }
  }, [updateData?.updateSequenceMultiple, enqueueSnackbar]);

  const handleSubmit = () => {
    const input = data.map(({ _id, sequenceTemplate, ...rest }) => rest); 
  
    updateSequenceMultiple({ variables: { input, sequenceId, attachedTemplates: selectedTemplates } });
  };
  

  const handleAttachTemplate = (index, type) => {
    setSelectedStep(index);
    setSelectedType(type);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleTemplateSelect = (selectedIds) => {
    setSelectedTemplates((prevSelected) => {
      const step = data[selectedStep].step; 
      const existingEntry = prevSelected.find((item) => item.step === step);

      if (existingEntry) {
        return prevSelected.map((item) => (item.step === step ? { ...item, selectedTemplates: selectedIds } : item));
      }

      return [...prevSelected, { step, selectedTemplates: selectedIds }];
    });
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#" onClick={handleClick}>
      Sequence Overview
    </Link>,
    <Typography key="3" color="text.primary">
      Manage
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Manage Sequence | Sales App </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">{LABELS.ManageSequence}</Typography>
                <Typography variant="caption">{LABELS.ManageSequenceDescription}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<img src="/assets/icons/ic_leads.svg" alt="import leads" />}
                  onClick={handleUseTemplate}
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    border: '1px solid rgba(145, 158, 171, 0.24)',
                  }}
                >
                  Use template
                </Button>
              </Stack>
            </Stack>
          </Grid>
          {loadingSequenceData && (
            <Grid item xs={12} sm={12} md={12}>
              {' '}
              <Loader />
            </Grid>
          )}
          {!loadingSequenceData && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Step</TableCell>
                        <TableCell>Stage</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row, index) => (
                        <TableRow key={row.step}>
                          <TableCell>
                            <TextField value={row.step} onChange={(e) => handleEdit(index, 'step', e.target.value)} />
                          </TableCell>
                          <TableCell>
                            <TextField value={row.stage} onChange={(e) => handleEdit(index, 'stage', e.target.value)} />
                          </TableCell>
                          <TableCell>
                            <TextField value={row.title} onChange={(e) => handleEdit(index, 'title', e.target.value)} />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={row.description}
                              onChange={(e) => handleEdit(index, 'description', e.target.value)}
                            />
                          </TableCell>
                          <TableCell>
                            <Select
                              value={row.type}
                              sx={{ width: 200 }}
                              onChange={(e) => handleEdit(index, 'type', e.target.value)}
                            >
                              {connnectTypeOptions.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" gap={0}>
                              <Tooltip
                                title={
                                  selectedTemplates.find((item) => item.step === row.step)?.selectedTemplates.length > 0
                                    ? 'Templates Attached'
                                    : 'Attach Templates'
                                }
                              >
                                <IconButton
                                  onClick={() => handleAttachTemplate(index, row.type)}
                                  sx={{
                                    color:
                                      selectedTemplates.find((item) => item.step === row.step)?.selectedTemplates
                                        .length > 0
                                        ? 'green'
                                        : 'inherit',
                                  }}
                                >
                                  {selectedTemplates.find((item) => item.step === row.step)?.selectedTemplates.length >
                                  0 ? (
                                    <CheckCircle />
                                  ) : (
                                    <AttachFile />
                                  )}
                                  {selectedTemplates.find((item) => item.step === row.step)?.selectedTemplates.length >
                                    0 && (
                                    <Badge
                                      badgeContent={
                                        selectedTemplates.find((item) => item.step === row.step)?.selectedTemplates
                                          .length
                                      }
                                      color="secondary"
                                      sx={{ marginLeft: 0.5 }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete">
                                <IconButton onClick={() => handleDeleteRow(index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <Button onClick={handleAddRow} variant="contained" color="primary" sx={{ m: 2 }}>
                    Add Row
                  </Button>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={loadingUpdate}
                    onClick={handleSubmit}
                    sx={{ textTransform: 'none' }}
                  >
                    {loadingUpdate ? 'Saving...' : 'Save Changes'}
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
        {drawerOpen &&  <TemplateDrawer
          open={drawerOpen}
          onClose={handleCloseDrawer}
          onSelect={handleTemplateSelect}
          selectedTemplates={
            selectedTemplates.find((item) => item.step === data[selectedStep].step)?.selectedTemplates || []
          }
          selectedType={selectedType}
        />}
      </Container>
    </>
  );
}
