import { gql } from '@apollo/client';

export const GET_CALL_SUMMARY_STATS = gql`
  query GetCallSummaryStats($input: DateRangeInput) {
    getCallSummaryStats(input: $input) {
      totalSuccessfulCalls
      totalConnectedCallsByManual
      totalConnectedCallsByAmd
      totalVoiceMailCalls
    }
  }
`;

export const GET_CALL_CONNECTIONS_STATS = gql`
  query GetCallConnectionStats($range: String!) {
    getCallConnectionStats(range: $range) {
      stats {
        range
        label
        connectedCalls
        voicemailCalls
      }
    }
  }
`;

export const GET_AGENTS_WITH_MOST_CONNECTIONS = gql`
  query GetAgentWithMostConnections($input: DateRangeInput) {
    getAgentWithMostConnections(input: $input) {
      agentName
      totalConnectedCalls
    }
  }
`;

export const GET_CALL_POOL_VISIBILITY = gql`
  query Query($input: DateRangeInput) {
    getCallPoolVisibility(input: $input)
  }
`;

export const GET_CONNECTED_CALLS_LOG = gql`
  query GetConnectedCallsLog($input: DateRangeInput, $limit: Float, $skip: Float) {
    getConnectedCallsLog(input: $input, limit: $limit, skip: $skip) {
      callSid
      callRecordingUrl
      duration
      createdAt
      direction
      to
      amdStatus
      isManualEnd
      callDialType
      agentName
    }
  }
`;
