import { useEffect, useState } from 'react';
import { VictoryPie, VictoryAnimation, VictoryLabel } from 'victory';

export const getData = (contactedLeadsPercent) => {
    return [
        { x: 'Contacted Leads', y: contactedLeadsPercent },
        { x: 'Uncontacted Leads', y: 100 - contactedLeadsPercent },
    ];
};

const SequencePieChart = ({ contactedLeads, totalLeads }) => {
    const [contactedLeadsPercent, setContactedLeadsPercent] = useState(
        (contactedLeads / totalLeads) * 100 || 0
    );

    useEffect(() => {
        setContactedLeadsPercent((contactedLeads / totalLeads) * 100 || 0);
    }, [contactedLeads, totalLeads]);

    return (
        <div
            style={{
                width: 80,
                height: 60,
                border: '0px solid red',
            }}
        >
            <svg
                viewBox="0 50 320 200"
                width="100%"
                height="100%"
                style={{ border: '0px solid green' }}
            >
                <VictoryPie
                    standalone={false}
                    animate={{ duration: 1000 }}
                    width={300}
                    height={300}
                    data={getData(contactedLeadsPercent)}
                    innerRadius={80}
                    cornerRadius={10}
                    labels={() => null}
                    style={{
                        data: {
                            fill: ({ datum }) => {
                                let color = 'red';
                                if (datum.y >= 30 && datum.y < 60) {
                                    color = '#ffbe2f';
                                } else if (datum.y >= 60) {
                                    color = '#C7F4C2';
                                }
                                return datum.x === 'Contacted Leads' ? color : '#F0F0F0';
                            },
                        },
                    }}
                />
                <VictoryAnimation
                    duration={1000}
                    data={{ percent: contactedLeadsPercent }}
                >
                    {(newProps) => {
                        return (
                            <VictoryLabel
                                textAnchor="middle"
                                verticalAnchor="middle"
                                x={150}
                                y={150}
                                text={`${Math.round(contactedLeadsPercent)}%`}
                                style={{
                                    fontSize: 60,
                                    fontWeight: 600,
                                    lineHeight: 21.77,
                                }}
                            />
                        );
                    }}
                </VictoryAnimation>
            </svg>
        </div>
    );
};

export default SequencePieChart;
