import { gql } from '@apollo/client';

export const CREATE_CORE_SEQUENCE = gql`
  mutation CreateCoreSequence($input: CoreSequenceInput!) {
    createCoreSequence(input: $input) {
      _id
      title
      description
    }
  }
`;

export const UPDATE_CORE_SEQUENCE = gql`
  mutation UpdateCoreSequence($input: CoreSequenceInput!) {
    updateCoreSequence(input: $input) {
      _id
      title
      type
      tag
      description
    }
  }
`;

export const ADD_AGENT_TO_SEQUENCE = gql`
  mutation AddAgentToSequence($input: AgentToSeqInput!) {
    addAgentToSequence(input: $input) {
      _id
      title
      type
      members {
        _id
        firstName
        lastName
      }
    }
  }
`;

export const REMOVE_AGENT_TO_SEQUENCE = gql`
  mutation RemoveAgentFromSequence($input: AgentToSeqInput!) {
    removeAgentFromSequence(input: $input) {
      _id
      title
    }
  }
`;

export const TOGGLE_FAVORITE_SEQUENCE = gql`
  mutation ToggleFavoriteSequence($sequenceId: String!) {
  toggleFavoriteSequence(sequenceId: $sequenceId) {
    _id
    title
  }
}
`;

export const TOGGLE_ARCHIVE_SEQUENCE = gql`
 mutation ToggleArchiveSequence($sequenceId: String!) {
  toggleArchiveSequence(sequenceId: $sequenceId) {
    _id
  }
}
`;
