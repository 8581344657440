import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GET_USER } from '../graphql/queries/user';
import useLogout from './use-logout';
import { setUser } from '../redux/auth.slice';

const useCheckAuth = (socket2, user) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, error, refetch } = useQuery(GET_USER, {
    fetchPolicy: 'network-only',
    skip: !user,
  });

  const handleLogout = useLogout(socket2, user);

  useEffect(() => {
    if (data && !data.getUser) {
      handleLogout();
    }else if (data && data.getUser) {
     dispatch(setUser(data.getUser));
    }
  }, [data, handleLogout]);

  useEffect(() => {
    if (user) {
      refetch();
    }
  }, [location.pathname, user, refetch]);

  return { user: data?.getUser };
};

export default useCheckAuth;
