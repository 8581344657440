import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack } from '@mui/material';
// GraphQL
import { CREATE_TRIGGER, UPDATE_TRIGGER } from '../../../graphql/mutations/trigger';
// components
import { FormProvider } from '../../../components/hook-form';

//
import TriggerFormHeader from './trigger-form/TriggerFromHeader';
import TriggerFormDetails from './trigger-form/TriggerFormDetails';

// ----------------------------------------------------------------------

CreateTriggers.propTypes = {
  isEdit: PropTypes.bool,
  currentTrigger: PropTypes.object,
};


export default function CreateTriggers({ isEdit, currentTrigger }) {
  const { enqueueSnackbar } = useSnackbar();
  const [createTrigger, { loading }] = useMutation(CREATE_TRIGGER);
  const [updateTrigger, { loading: updateLoading }] = useMutation(UPDATE_TRIGGER);

  const NewTriggerSchema = Yup.object().shape({
    triggerName: Yup.string().required('Trigger Name is required'),
    items: Yup.array()
      .of(
        Yup.object().shape({
          service: Yup.string().required('Service is required'),
          operator: Yup.string().required('Operator is required'),
          value: Yup.string().required('Value is required'),
          action: Yup.string().required('Action is required'),
        })
      )
      .required('At least one trigger condition is required'),
  });

  const defaultValues = useMemo(() => {
    if (isEdit && currentTrigger) {
      return {
        triggerName: currentTrigger.triggerName || '',
        items: currentTrigger.conditions.map(condition => ({
          service: condition.fieldName,  // Mapping correctly
          operator: condition.operator,
          value: condition.value,
          action: condition.action,
        })) || [{ service: '', operator: '', value: '', action: '' }],
      };
    }
    return {
      triggerName: '',
      items: [{ service: '', operator: '', value: '', action: '' }],
    };
  }, [isEdit, currentTrigger]);

  const methods = useForm({
    resolver: yupResolver(NewTriggerSchema),
    defaultValues,
  });

  const { reset, watch, handleSubmit, formState: { isSubmitting } } = methods;
  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleCreateAndSend = async () => {
    try {
      const { triggerName, items } = values;
      const conditions = items.map(item => ({
        fieldName: item.service,
        operator: item.operator,
        value: item.value,
        action: item.action,
      }));

      if (isEdit) {
        await updateTrigger({
          variables: {
            triggerId: currentTrigger._id, 
            input: {
              triggerName,
              conditions,
            },
          },
        });
        enqueueSnackbar('Trigger updated successfully', { variant: 'success' });
      } else {
        await createTrigger({
          variables: {
            input: {
              triggerName,
              conditions,
            },
          },
        });
        enqueueSnackbar('Trigger created successfully', { variant: 'success' });
      }
      reset();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error occurred while saving trigger', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods}>
      <Card>
        <TriggerFormHeader />
        <TriggerFormDetails />
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <LoadingButton
          size="large"
          variant="contained"
          loading={loading}
          onClick={handleSubmit(handleCreateAndSend)}
        >
          {isEdit ? 'Update' : 'Save'} Trigger
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
