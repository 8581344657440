import React from 'react';
import { calculatePxToPercentage } from '../../../utils/cssHelper';


const MailAppIcon = () => {
  return (
      <svg
          width={calculatePxToPercentage(60)}
          height={calculatePxToPercentage(60)}
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <rect width="60" height="60" rx="30" fill="white" />
          <rect width="60" height="60" rx="30" fill="#DCF6FF" />
          <path
              d="M46.385 24.665V42.4808C46.3881 42.5371 46.3881 42.5935 46.385 42.6497C46.3644 43.0042 46.2708 43.3506 46.1099 43.6672C45.949 43.9837 45.7244 44.2636 45.4502 44.4892C44.9841 44.8766 44.3977 45.0898 43.7917 45.0923H16.5975C16.254 45.0929 15.9138 45.0249 15.5969 44.8923C15.28 44.7598 14.9927 44.5653 14.752 44.3203L30.1915 34.7731L45.5708 24.2368L45.6432 24.1946C45.6914 24.174 45.7421 24.1598 45.794 24.1523H45.8603H45.9689C46.0875 24.1751 46.1944 24.239 46.2705 24.3328C46.3466 24.4265 46.3872 24.5442 46.385 24.665Z"
              fill="#35C4F4"
          />
          <path
              d="M15.1635 23.2585H45.2224C45.3383 23.2606 45.4519 23.225 45.5459 23.1571C45.64 23.0893 45.7095 22.9928 45.7442 22.8821C45.7788 22.7714 45.7766 22.6525 45.738 22.5431C45.6994 22.4338 45.6264 22.3399 45.53 22.2755L30.8021 12.1855C30.6241 12.0646 30.414 12 30.199 12C29.9839 12 29.7738 12.0646 29.5958 12.1855L14.8559 22.2755C14.7594 22.3399 14.6864 22.4338 14.6478 22.5431C14.6092 22.6525 14.607 22.7714 14.6417 22.8821C14.6763 22.9928 14.7459 23.0893 14.8399 23.1571C14.934 23.225 15.0475 23.2606 15.1635 23.2585Z"
              fill="#2FBBEE"
          />
          <path
              d="M14.8142 24.237L30.1935 34.7793L14.7539 44.3265C14.2756 43.8453 14.0049 43.1957 14 42.5172V24.6652C13.9996 24.5703 14.0253 24.4772 14.0742 24.3959C14.1231 24.3146 14.1933 24.2483 14.2772 24.2042C14.3612 24.16 14.4556 24.1397 14.5503 24.1455C14.645 24.1513 14.7363 24.1829 14.8142 24.237Z"
              fill="#35C4F4"
          />
          <path
              d="M46.3868 24.6689V42.6596C46.3637 43.0142 46.2683 43.3603 46.1065 43.6766C45.9447 43.993 45.7199 44.2728 45.446 44.4991L30.1934 34.777L45.5666 24.2407L45.639 24.1985C45.6872 24.1779 45.7379 24.1637 45.7897 24.1562H45.8561H45.9647C46.0618 24.174 46.1507 24.2226 46.218 24.295C46.2709 24.3418 46.3134 24.3994 46.3425 24.4638C46.3716 24.5283 46.3867 24.5982 46.3868 24.6689Z"
              fill="#0E8BDC"
          />
      </svg>
  );
};

export default MailAppIcon;