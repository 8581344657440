import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk';

const ProgrammaticVoiceCall = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [callStatus, setCallStatus] = useState('');
    const [incomingCall, setIncomingCall] = useState(null);
    const [acceptedCall, setAcceptedCall] = useState(null);
    const callingToken = useRef(null);
    const device = useRef(null);
    const callInstance = useRef(null);
    const userId = 'starr'; // This should be unique per user/device
  
    useEffect(() => {
      // Fetch authentication token from the server
      const fetchToken = async () => {
        try {
          const response = await axios.post('http://localhost:5000/api/v1/twilio-v3/token', { identity: userId });
          callingToken.current = response.data.token;
  
          // Initialize Device
          device.current = new Device(callingToken.current, {
            codecPreferences: ['opus', 'pcmu']
          });
  
          // Register Device event listeners
          device.current.on('ready', () => {
            console.log('Device is ready');
            setCallStatus('Device is ready');
          });
  
          device.current.on('error', (error) => {
            console.error('Device error:', error);
            setCallStatus(`Device error: ${error.message}`);
          });
  
          device.current.on('incoming', (call) => {
            console.log('Incoming call from:', call.parameters.From);
            setIncomingCall(call);
            setCallStatus('Incoming call');
          });
  
          // Register device
          device.current.register();
        } catch (error) {
          console.error('Error fetching token:', error);
          setCallStatus('Error fetching token');
        }
      };
  
      fetchToken();
    }, []);
  
    const handleProgrammaticDial = async () => {
      try {
        const params = {
          to: phoneNumber,
          from: '+19033286481', // Your Twilio phone number
          clientIdentity: userId,
        };
  
        const response = await axios.post('http://localhost:5000/api/v1/twilio-v3/make-call', params);

        setCallStatus(`Calling ${phoneNumber}...`);
      } catch (error) {
        console.error('Error making call:', error);
        setCallStatus(`Error making call: ${error.message}`);
      }
    };
  
    const handleAcceptIncomingCall = () => {
      if (incomingCall) {
        incomingCall.accept();
        setAcceptedCall(incomingCall);
        setCallStatus('Incoming call accepted');
        setIncomingCall(null);
      }
    };
  
    const handleRejectIncomingCall = () => {
      if (incomingCall) {
        incomingCall.reject();
        setCallStatus('Incoming call rejected');
        setIncomingCall(null);
      }
    };
  
    const handleEndCall = () => {
      if (acceptedCall) {
        acceptedCall.disconnect();
        setCallStatus('Call ended');
        setAcceptedCall(null);
      }
    };
  
    return (
      <div>
        <h1>Make a Voice Call</h1>
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter phone number"
        />
        <button onClick={handleProgrammaticDial}>Programmatic Dial</button>
        <p>Call Status: {callStatus}</p>
  
        {incomingCall && (
          <div>
            <p>Incoming call from: {incomingCall.parameters.From}</p>
            <button onClick={handleAcceptIncomingCall}>Accept</button>
            <button onClick={handleRejectIncomingCall}>Reject</button>
          </div>
        )}
  
        {acceptedCall && (
          <button onClick={handleEndCall}>End Call</button>
        )}
      </div>
    );
  };

export default ProgrammaticVoiceCall;
