import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Card, CardActions, CardMedia, Container, Grid, Link, Stack, Typography } from '@mui/material';
// components
// mock
import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import EmptyContent from '../components/empty-content/empty-content';
import Iconify from '../components/iconify';
import Loader from '../components/loader';
import { DELETE_ASSETS } from '../graphql/mutations/assets';
import { GET_ALL_ASSETS } from '../graphql/queries/assets';
import AddAssetsDialog from '../sections/@dashboard/assets/AddAssetsDialog';
import AssetDeleteDialog from '../sections/@dashboard/assets/AssetDeleteDialog';
import { LABELS } from '../utils/constants';

// ---------------------------------------------------------------------- 

export default function AssetsPage() {
    const [open, setOpen] = useState(false);
    const { data, loading } = useQuery(GET_ALL_ASSETS)
    const [confirmDelete, {data:deletedAsset, loading: loadingDeleteAsset}] = useMutation(DELETE_ASSETS)
    const {enqueueSnackbar} = useSnackbar();
    const [docs, setDocs] = useState([])
    const [seletedAsset, setSelectedAsset] = useState(null);
    const [deleteAsset, setDeleteAsset] = useState(false);
    
    useEffect(() => {
        if (data?.getAssets) {
            setDocs(data.getAssets);
        }
    }, [data]);

    useEffect(() => {
        if (deletedAsset) {
            enqueueSnackbar('Asset deleted successfully', { variant: 'success' });
            const newDocs = docs.filter((doc) => doc._id !== deletedAsset.deleteAssets._id);
            setDocs(newDocs);
        }
    }
    , [deletedAsset, enqueueSnackbar]);

    const handleAssetsAdded = useCallback((newAssets) => {
        setDocs([...docs, newAssets]);
    }, [docs]);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleCloseDelete = useCallback(() => {
        setDeleteAsset(false);
        setSelectedAsset(null);
    }
    , []);

    const handleOpenDelete = (asset) => {
        setSelectedAsset(asset);
        setDeleteAsset(true);
    }

    const handleDeleteAssetsConfirm = () => {
        confirmDelete({ variables: { _id: seletedAsset } });
        setDeleteAsset(false);
        setSelectedAsset(null);
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Assets
        </Typography>,
    ];

    const renderEmpty = (
        <EmptyContent
          title={`Nothing in Assets`}
          description="This folder is empty"
          imgUrl="/assets/icons/empty/ic_folder_empty.svg"
          sx={{
            borderRadius: 1.5,
            bgcolor: 'background.default',
          }}
        />
      );
    

    return (
        <>
            <Helmet>
                <title> Sequence | Sales App </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Stack direction="column" spacing={0} alignItems="flex-start">
                                <Typography variant="h3">{LABELS.Assets}</Typography>
                                <Typography variant="caption">{LABELS.AssetsDescription}</Typography>
                            </Stack>
                            <Button variant="contained" onClick={handleClickOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                                New Asset
                            </Button>
                        </Stack>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {loading && <Loader />}
                        {!loading && docs.length === 0 && renderEmpty}
            
                    </Grid>
                    {docs.map((pdf, index) => (
                        <Grid item sm={12} md={3} key={index}>
                            <Card sx={{p: 2}}>
                                {pdf?.url?.endsWith('.pdf') ? (
                                    <iframe title={`PDF ${index + 1}`} src={pdf.url} style={{ width: '100%', height: '390px' }} />
                                ) : (
                                    <CardMedia
                                        component="img"
                                        alt="Placeholder"
                                        height="400"
                                        image="/assets/icons/file-minus-alt-svgrepo-com.svg"
                                    />
                                )}
                                <Typography variant="body1" align="center">
                                    {pdf?.title}
                                </Typography>
                                <CardActions
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button size="small" onClick={() => window.open(pdf.url, '_blank')}>
                                        View
                                    </Button>
                                    <Button size="small" onClick={() => window.open(pdf.url, '_blank')}>
                                        Download
                                    </Button>
                                    <LoadingButton size="small" color='error' loading={loadingDeleteAsset} onClick={() => handleOpenDelete(pdf._id)}>
                                        Delete
                                    </LoadingButton>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <AddAssetsDialog open={open} handleClose={handleClose} handleAssetsAdded={handleAssetsAdded} />
            <AssetDeleteDialog openDelete={deleteAsset} handleCloseDelete={handleCloseDelete} handleDeleteAssetsConfirm={handleDeleteAssetsConfirm} />
        </>
    );
}
