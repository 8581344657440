// import PropTypes from 'prop-types';
// // @mui
// import { alpha } from '@mui/material/styles';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// //
// import { Box } from '@mui/material';
// import { StyledEditor } from './styles';
// import Toolbar, { formats } from './toolbar';

// // ----------------------------------------------------------------------

// export default function Editor({ id = 'minimal-quill', error, simple = false, helperText, sx, ...other }) {
//   const modules = {
//     toolbar: {
//       container: `#${id}`,
//     },
//     history: {
//       delay: 500,
//       maxStack: 100,
//       userOnly: true,
//     },
//     syntax: false,
//     clipboard: {
//       matchVisual: false,
//     },
//   };

//   return (
//     <>
//       <StyledEditor
//         sx={{
//           ...(error && {
//             border: (theme) => `solid 1px ${theme.palette.error.main}`,
//             '& .ql-editor': {
//               bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
//             },
//           }),
//           ...sx,
//         }}
//       >
//        <Toolbar id={id} isSimple={simple} />
//         <ReactQuill modules={modules} formats={formats} placeholder="Write something awesome..." {...other} />
//       </StyledEditor>

//       {helperText && helperText}
//     </>
//   );
// }

// Editor.propTypes = {
//   error: PropTypes.bool,
//   helperText: PropTypes.object,
//   id: PropTypes.string,
//   simple: PropTypes.bool,
//   sx: PropTypes.object,
// };


import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// 
import { Box } from '@mui/material';
import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';
import { scrollbarStyle } from '../../utils/cssStyles';


// ----------------------------------------------------------------------

export default function Editor({ id = 'minimal-quill', error, simple = false, helperText, sx, ...other }) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          position: 'relative',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Box sx={{ position: 'sticky', top: 0, zIndex: 1, bgcolor: 'background.paper' }}>
          <Toolbar id={id} isSimple={simple} />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto', 
            ...scrollbarStyle.scrollbar, 
          }}
        >
          <ReactQuill
            modules={modules}
            formats={formats}
            placeholder="Write something awesome..."
            {...other}
          />
        </Box>
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}

Editor.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.object,
  id: PropTypes.string,
  simple: PropTypes.bool,
  sx: PropTypes.object,
};
