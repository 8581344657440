import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import {
  Tab,
  Tabs,
  Card,
  Stack,
  Divider,
  Container,
  Breadcrumbs,
  Typography,
  Link,
} from '@mui/material';
import { NavigateNextOutlined } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { GET_EMAIL_STATS } from '../graphql/queries/email';
import Label from '../components/label/Label';

import Scrollbar from '../components/scrollbar/Scrollbar';
import useTabs from '../hooks/useTabs';
// sections
import StepAnalytic from '../sections/step/StepAnalytic';
import ABTestEmails from '../sections/step/ab-test-emails';


export default function StepsOverviewPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const { sequenceId, sequenceTitle, totalSteps } = useParams();
  const { data, loading, error } = useQuery(GET_EMAIL_STATS, {
    variables: { sequenceId: sequenceId || null },
  });

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('A/B Testing');

  const handleGoBack = () => {
    navigate(`/sequence/sequence-overview/${sequenceId}/${sequenceTitle}/${totalSteps}`);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" onClick={handleGoBack}>
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Overview
    </Typography>,
  ];

  const TABS = [
    { value: 'A/B Testing', label: 'A/B Testing', color: 'info', count: 12 },
    { value: 'Leads Report', label: 'Leads Report', color: 'success', count: 1 },
    // { value: 'unpaid', label: 'Unpaid', color: 'warning', count: 5 },
    // { value: 'overdue', label: 'Overdue', color: 'error', count: 9 },
    // { value: 'draft', label: 'Draft', color: 'default', count: 4 },
  ];

  const emailStats = data?.getEmailStats || {
    totalEmails: 0,
    successfulDeliveries: 0,
    failedDeliveries: 0,
    openRate: '0%',
    clickThroughRate: '0%',
    spamReports: 0,
    totalTemplates: 0,
  };

  return (
    <>
      <Helmet>
        <title> Steps Overview | Auto Caller </title>
      </Helmet>
      <Container maxWidth={'lg'}>
        <Breadcrumbs separator={<NavigateNextOutlined fontSize="small" />} aria-label="breadcrumb" sx={{cursor: 'pointer'}}>
          {breadcrumbs}
        </Breadcrumbs>
        <Stack gap={2}>
          <Card sx={{ mt: 2 }}>
            <Scrollbar>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                sx={{ py: 2 }}
              >
                <StepAnalytic
                  title="Total Email Sent"
                  total={emailStats.totalEmails}
                  percent={100}
                  icon="ic:round-receipt"
                  color={theme.palette.info.main}
                  loading={loading}
                />
                <StepAnalytic
                  title="Successful Deliveries"
                  total={emailStats.successfulDeliveries}
                  percent={(emailStats.successfulDeliveries / emailStats.totalEmails) * 100 || 0}
                  icon="eva:checkmark-circle-2-fill"
                  color={theme.palette.success.main}
                  loading={loading}
                />
                <StepAnalytic
                  title="Failed Deliveries"
                  total={emailStats.failedDeliveries}
                  percent={(emailStats.failedDeliveries / emailStats.totalEmails) * 100 || 0}
                  icon="eva:alert-triangle-fill"
                  color={theme.palette.error.main}
                  loading={loading}
                />
                <StepAnalytic
                  title="Open Rate"
                  total={parseFloat(emailStats.openRate)}
                  percent={parseFloat(emailStats.openRate)}
                  icon="eva:checkmark-circle-fill"
                  color={theme.palette.success.main}
                  loading={loading}
                />
              </Stack>
            </Scrollbar>
          </Card>
          {/* Card 2 */}
          <Card sx={{ mb: 5 }}>
            <Scrollbar>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
                sx={{ py: 2 }}
              >
                <StepAnalytic
                  title="Click-Through Rate"
                  total={parseFloat(emailStats.clickThroughRate)}
                  percent={parseFloat(emailStats.clickThroughRate)}
                  icon="eva:arrow-forward-fill"
                  color={theme.palette.text.secondary}
                  loading={loading}
                />
                <StepAnalytic
                  title="Spam Reports"
                  total={emailStats.spamReports}
                  percent={(emailStats.spamReports / emailStats.totalEmails) * 100 || 0}
                  icon="ic:baseline-report-problem"
                  color={theme.palette.success.light}
                  loading={loading}
                />
                <StepAnalytic
                  title="Lead Progression"
                  total={0}
                  percent={0}
                  icon="eva:trending-up-fill"
                  color={theme.palette.success.main}
                  loading={loading}
                />
                <StepAnalytic
                  title="Total Template"
                  total={emailStats.totalTemplates}
                  percent={100}
                  icon="eva:file-text-fill"
                  color={theme.palette.error.light}
                  loading={loading}
                />
              </Stack>
            </Scrollbar>
          </Card>
        </Stack>
  
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                label={
                  <Stack spacing={1} direction="row" alignItems="center">
                    <div>{tab.label}</div> <Label color={tab.color}> {tab.count} </Label>
                  </Stack>
                }
              />
            ))}
          </Tabs>
  
          <Divider />
  
          <Card sx={{ mt: 2, p: 2 }}>
            {filterStatus === 'A/B Testing' && <ABTestEmails />}
            {filterStatus === 'Leads Report' && <div>Hello Tab 2</div>}
          </Card>
        </Card>
      </Container>
    </>
  );
}