import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { UPDATE_CORE_SEQUENCE } from '../../../graphql/mutations/core.sequence'; 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minHeight: '300px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: '500px', 
  },
}));

function UpdateCoreSequenceDialog({ open, handleClose, sequence, refetch }) {
  const [updateCoreSequence, { data: updateSequenceData, loading, error }] = useMutation(UPDATE_CORE_SEQUENCE);
  const { handleSubmit, register, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    updateCoreSequence({
      variables: {
        input: { ...data, id: sequence._id }, 
      },
    });
    enqueueSnackbar('Updated successfully!', { variant: 'success' });
  };

  useEffect(() => {
    if (updateSequenceData && updateSequenceData.updateCoreSequence) {
      refetch();
      handleClose();
    }
  }, [updateSequenceData, refetch]);

  useEffect(() => {
    if (sequence) {
      reset({
        title: sequence.title,
        description: sequence.description,
        tag: sequence.tag,
        type: sequence.type,
      });
    }
  }, [sequence, reset]);

  return (
    <div>
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Update Core Sequence
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {error && <Alert severity="error">{error?.message}</Alert>}
              
              <TextField
                name="title"
                label="Core Sequence Title"
                required
                {...register('title', { required: true })}
              />
              
              <TextField
                name="description"
                label="Description"
                multiline
                rows={4}
                {...register('description')}
              />
{/*   
              <TextField
                name="tag"
                label="Tags"
                {...register('tag')}
              />
               */}
              <LoadingButton loading={loading} type="submit" variant="contained">
                Update Sequence
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

UpdateCoreSequenceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sequence: PropTypes.object.isRequired, 
  refetch: PropTypes.func.isRequired,
};

export default UpdateCoreSequenceDialog;
