import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Chip,
  Divider,
  Tooltip,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_PROMPTS } from '../../../graphql/queries/prompt';
import { UPDATE_PROMPT, DELETE_PROMPT, TOGGLE_ACTIVE_PROMPT } from '../../../graphql/mutations/prompt';
import Iconify from '../../../components/iconify/Iconify';

export default function SequencePromptList() {
  const { sequenceId, sequenceTitle, totalSteps } = useParams();
  const navigate = useNavigate();
  const [prompts, setPrompts] = useState([]);
  const [activePromptId, setActivePromptId] = useState(null);
  const [editingPrompt, setEditingPrompt] = useState(null);
  const [textareaValue, setTextareaValue] = useState('');
  const [expanded, setExpanded] = useState(null);
  const [copiedPromptId, setCopiedPromptId] = useState(null);

  // Fetching prompts
  const { loading, error, data } = useQuery(GET_PROMPTS, {
    variables: { sequenceId, skip: 0, limit: 100 },
    onCompleted: (data) => setPrompts(data.getPrompts),
  });

  const [updatePrompt] = useMutation(UPDATE_PROMPT);
  const [deletePrompt] = useMutation(DELETE_PROMPT);
  const [toggleActivePrompt] = useMutation(TOGGLE_ACTIVE_PROMPT);

  const handleSetActive = async (id) => {
    try {
      await toggleActivePrompt({ variables: { sequenceId, promptId: id } });
      setPrompts((prev) =>
        prev.map((prompt) => ({
          ...prompt,
          isActive: prompt._id === id,
        }))
      );
      setActivePromptId(id);
    } catch (error) {
      console.error('Error setting active prompt:', error);
    }
  };

  const handleEdit = (prompt) => {
    navigate(`/sequence/create-update-prompt/${sequenceId}/${sequenceTitle}/${totalSteps}`, {
      state: { isEdit: true, currentPrompt: prompt },
    });
  };

  const handleDelete = async (id) => {
    try {
      await deletePrompt({ variables: { promptId: id } });
      setPrompts((prev) => prev.filter((prompt) => prompt._id !== id));
    } catch (error) {
      console.error('Error deleting prompt:', error);
    }
  };

  const handleSave = async () => {
    try {
      const response = await updatePrompt({
        variables: {
          input: { title: editingPrompt.title, prompt: textareaValue },
          promptId: editingPrompt._id,
        },
      });
      setPrompts((prev) =>
        prev.map((prompt) =>
          prompt._id === response.data.updatePrompt._id ? { ...editingPrompt, prompt: textareaValue } : prompt
        )
      );
    } catch (error) {
      console.error('Error updating prompt:', error);
    }
    setEditingPrompt(null);
  };

  const handleCopy = (content, id) => {
    navigator.clipboard.writeText(content);
    setCopiedPromptId(id);
    setTimeout(() => {
      setCopiedPromptId(null);
    }, 3000);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 4, minHeight: '100vh', color: '#000' }}>
      {/* Prompt Editor */}
      {editingPrompt && (
        <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 8px 16px rgba(0,0,0,0.3)', backgroundColor: '#f5f5f5' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {editingPrompt.id ? 'Edit Prompt' : 'Create New Prompt'}
          </Typography>
          <TextField
            value={textareaValue}
            onChange={(e) => setTextareaValue(e.target.value)}
            multiline
            rows={8}
            variant="outlined"
            fullWidth
            placeholder="Enter your prompt here..."
            sx={{ mb: 3 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {editingPrompt.id ? 'Save Changes' : 'Create Prompt'}
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setEditingPrompt(null)}>
              Cancel
            </Button>
          </Box>
        </Paper>
      )}

      {/* Prompts List */}
      <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 8px 16px rgba(0,0,0,0.3)' }}>
        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: 'black' }}>
          Available Prompts
        </Typography>
        <List>
          {loading && <Typography>Loading...</Typography>}
          {error && <Typography color="error">Error fetching prompts.</Typography>}
          {!loading && !error && prompts.length === 0 && (
            <Typography>No prompts available. Please create a new prompt.</Typography>
          )}
          {prompts.map((prompt) => (
            <React.Fragment key={prompt._id}>
              <Accordion expanded={expanded === `panel${prompt._id}`} onChange={handleChange(`panel${prompt._id}`)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography sx={{ flexShrink: 0, fontWeight: 500, color: '#212B36' }}>{prompt.title}</Typography>
                  {prompt.isActive && (
                    <Chip
                      label="Active"
                      color="success"
                      size="small"
                      sx={{ ml: 2, borderRadius: 1, bgcolor: '#10b981', color: 'white', fontWeight: 'bold' }}
                    />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ bgcolor: '#333333', p: 2, borderRadius: 1, position: 'relative' }}>
                    <Typography
                      variant="body1"
                      sx={{ color: '#d1d5db' }}
                      dangerouslySetInnerHTML={{ __html: prompt.prompt }}
                    />
                    <Tooltip
                      title={copiedPromptId === prompt._id ? 'Copied' : 'Copy Prompt'}
                      open={copiedPromptId === prompt._id}
                    >
                      <IconButton
                        onClick={() => handleCopy(prompt.prompt, prompt._id)}
                        sx={{ position: 'absolute', top: 10, right: 10, color: '#f3f4f6' }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Divider sx={{ my: 1, borderColor: '#444' }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 0 }}>
                    <Tooltip title="Set as Active">
                      <IconButton
                        color={prompt.isActive ? 'success' : 'default'}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSetActive(prompt._id);
                        }}
                      >
                        <Iconify icon="eva:checkmark-circle-2-outline" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Prompt">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(prompt);
                        }}
                      >
                        <Iconify icon="eva:edit-outline" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Prompt">
                      <IconButton
                        color="error"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(prompt._id);
                        }}
                      >
                        <Iconify icon="eva:trash-outline" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Divider sx={{ borderColor: '#eee' }} />
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
}
