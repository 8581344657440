import { gql } from '@apollo/client';

export const GET_ALL_SEQUENCE = gql`
  query GetSequence($sequenceId: String!) {
    getSequence(sequenceId: $sequenceId) {
      _id
      step
      stage
      title
      description
      type
      sequenceTemplate {
        _id
        status
        steps {
          step
          selectedTemplates
        }
      }
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation UpdateSequenceMultiple($sequenceId: String!, $input: [SequenceInput!]!, $attachedTemplates: [AttachedTemplateInput!]) {
  updateSequenceMultiple(sequenceId: $sequenceId, input: $input, attachedTemplates: $attachedTemplates) {
    _id
      step
      stage
      title
      description
      type
    }
  }
`;
