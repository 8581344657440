import { CancelOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

const fields = [
  'Job Source',
  'Job Post Title',
  'Job Type',
  'Job Category',
  'Job Post Date',
  'Job Location',
  'Company ID',
  'Simplified Company Name',
  'Company Domain Name',
  'Company Linkedin Profile',
  'Company Phone Number',
  'Company Email',
  'Simplified Company Location',
  'About Company',
  'Minimum Company Size',
  'Maximum Company Size',
  'Simplified Company Industry',
  'Generated Company Description',
  'Website Traffic Analysis',
  'Recommendation',
  'Nearby Location 1',
  'Nearby Location 2',
  'First Name',
  'Last Name',
  'Prospect Designation',
  'Prospect Simplified Designation',
  'Email Address',
  'Prospect Phone Number',
  'Prospect Direct Phone Number',
  'Prospect Mobile Phone Number',
  'Prospect HQ Phone Number',
  'Prospect Location',
  'Prospect Simplified Location',
  'Prospect Linkedin Profile',
  'Prospect Description',
  'Contact Owner',
  'Contact Owner ID',
  'Job Post URL',
  'Website URL',
  'Type',
  'Year Founded',
  'Industry',
  'Time Zone',
  'UX Analysis Link',
  'Recommendation on Traffic Analysis',
  'Company Logo',
  'Industry Tags',
  'Maximum Annual Revenue',
  'Punch Company Description',
  'Website Screenshot',
  'Job Title',
  'Prospect Designation Description',
  'Places Nearby 1',
  'Places Nearby 2',
  'Minimum Annual Revenue',
  'Traffic Month 1',
  'Traffic Value 1',
  'Traffic Month 2',
  'Traffic Value 2',
  'Traffic Month 3',
  'Traffic Value 3',
  'Traffic Month 4',
  'Traffic Value 4',
  'Traffic Month 5',
  'Traffic Value 5',
  'Traffic Month 6',
  'Traffic Value 6',
  'Expert Document Link',
  'Step',
  'Stage',
  'Post 1 TEXT',
  'Post 1 Date',
  'Post 2 TEXT',
  'Post 2 Date',
  'Post 3 TEXT',
  'Post 3 Date',
  'Post 4 TEXT',
  'Post 4 Date',
  'Post 5 TEXT',
  'Post 5 Date',
  'Posts Summary',
];

function Step2({ columns, setColumnKeys, agreeToSelectedColumns, setAgreeToSelectedColumns }) {
  const [keyValuePair, setKeyValuePair] = useState({});

  const handleValueUpdate = (key, value) => {
    setKeyValuePair((prev) => ({ ...prev, [key]: value }));
  };

  const keyValueObject = useMemo(() => {
    const newKeyValueObject = {};
    columns?.forEach((element) => {
      newKeyValueObject[element] = fields?.includes(element) ? element : '';
    });
    return { ...newKeyValueObject, ...keyValuePair };
  }, [columns, keyValuePair]);

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2, pb: 20 }}>
      <Typography variant="h6">Map columns in your file to properties</Typography>
      <Typography>
        These properties store specific info about contacts, companies, and more. Each column header below should be
        mapped to an object's property. Some of these have already been mapped based on their names. Anything that
        hasn't been mapped yet can be manually mapped to a contact property with the dropdown menu. You can always
        create a new property or choose "Don't import column".
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Mapped</TableCell>
              <TableCell>Column Headers From File</TableCell>
              <TableCell>Import as</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columns?.map((row) => (
              <TableRow key={row}>
                <TableCell component="th" scope="row">
                  {fields.includes(row) ? (
                    <img src="/assets/icons/ic_mapped.svg" width="18px" alt="hubspot" />
                  ) : (
                    <CancelOutlined width="15px" height="15px" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    options={fields}
                    value={keyValueObject[row] || null}
                    onChange={(_, value) => handleValueUpdate(row, value)}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" spacing={0} sx={{ mb: 10 }} alignItems="center">
        <Checkbox
          checked={agreeToSelectedColumns}
          onChange={(e) => {
            setAgreeToSelectedColumns(e.target.checked);
            setColumnKeys(keyValueObject);
          }}
        />
        <Typography variant="body2">I have selected all the matching fields</Typography>
      </Stack>
    </Stack>
  );
}

Step2.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setColumnKeys: PropTypes.func.isRequired,
  agreeToSelectedColumns: PropTypes.bool.isRequired,
  setAgreeToSelectedColumns: PropTypes.func.isRequired,
};

export default Step2;
