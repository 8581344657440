import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Box,
  IconButton,
  TextField,
} from '@mui/material';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';

// Static prompt data
const staticPromptText = `Prompt to ChatGPT Model:
"Generate enriched information about the following lead data:
- Name: John Doe
- Company: Acme Corp
- Email: john.doe@acme.com
- Phone: (555) 123-4567
- Status: Interested

Please provide insights on the company, potential needs, and relevant industry trends that could enhance my engagement with this lead."`;

// ----------------------------------------------------------------------

PromptDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function PromptDialog({ open, onClose }) {
  const [promptText, setPromptText] = useState(staticPromptText);
  const [response, setResponse] = useState('');
  const [showToast, setShowToast] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(promptText);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleGenerate = () => {
    setResponse('Generating response...');
    // Simulate a delay for response generation
    setTimeout(() => {
      setResponse(`Response generated based on: \n\n${promptText}`);
    }, 2000); // 2-second delay to simulate the generation process
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        sx={{
          zIndex: 2000,
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
            overflow: 'hidden',
            backgroundColor: 'background.paper',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            transition: '0.3s ease-in-out',
          },
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'primary.main',
            color: 'white',
            p: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Iconify icon="mdi:message-text" sx={{ mr: 1, fontSize: 30 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Prompt Overview
          </Typography>
          <IconButton onClick={onClose} sx={{ color: 'white' }}>
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>

        <Scrollbar
          sx={{
            p: 2,
            maxHeight: 500,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              p: 3,
              borderRadius: 1,
              bgcolor: '#1e1e1e', // Dark background only for prompt area
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
              transition: 'box-shadow 0.1s ease-in-out',
            }}
          >
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
              sx={{
                fontFamily: 'Source Code Pro, monospace',
                color: '#d1d5db',
                lineHeight: 1.75,
                whiteSpace: 'pre-line',
                overflowWrap: 'break-word',
                '& .MuiInputBase-root': {
                  color: '#d1d5db',
                  cursor: 'text', // Indicate it's editable
                },
                '& .MuiOutlinedInput-root': {
                  bgcolor: 'transparent',
                  borderColor: '#3a3a3a',
                  borderWidth: '2px', // Thicker border to make it obvious
                },
                '& .MuiOutlinedInput-root:hover': {
                  borderColor: '#999', // Lighter on hover to show interaction
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#555',
                },
              }}
            />

            {/* Copy icon positioned like ChatGPT */}
            <IconButton
              onClick={handleCopy}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: '#d1d5db',
                bgcolor: '#333',
                '&:hover': {
                  bgcolor: '#555',
                },
              }}
            >
              <Iconify icon="mdi:content-copy" />
            </IconButton>
          </Box>

          {/* Label to indicate that the prompt is editable */}
          <Typography
            variant="caption"
            sx={{ color: '#aaa', display: 'block', mt: 1, ml: 3 }}
          >
            You can edit this prompt before generating a response.
          </Typography>

          {/* Response area */}
          {response && (
            <Box
              sx={{
                mt: 3,
                p: 2,
                borderRadius: 1,
                bgcolor: '#f5f5f5',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  whiteSpace: 'pre-line',
                  fontFamily: 'Source Code Pro, monospace',
                }}
              >
                {response}
              </Typography>
            </Box>
          )}
        </Scrollbar>

        <DialogActions
          sx={{
            p: 2,
            borderTop: '1px solid',
            borderColor: 'divider',
            bgcolor: 'background.paper',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            sx={{
              borderRadius: 2,
              px: 3,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.1s ease',
              '&:hover': {
                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            Generate
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            sx={{
              borderRadius: 2,
              px: 3,
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.1s ease',
              '&:hover': {
                boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)',
                bgcolor: 'primary.dark',
                color: 'white',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
