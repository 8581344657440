import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, Box, Divider, Stack } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

const ConfirmationModal = ({ open, onClose, onConfirm, title, description, subDescription }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs" 
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center">
          <WarningIcon sx={{ color: 'error.main', mr: 1 }} />
          <Stack>
          <Typography variant="h6">{title} {subDescription && <span style={{fontSize: '12px'}}>({subDescription})</span>}</Typography>
          </Stack>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center', color: '#292933'}}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
