
export const truncateText = (text, length) => {
    if (text?.length <= length) {
        return text;
    }
    return `${text?.slice(0, length)}...`;
  }
  

  export const truncateHtmlText = (html, length) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
  
    const textContent = tempElement.textContent || tempElement.innerText || "";

    return textContent.length > length ? `${textContent.slice(0, length)}...` : textContent;
  };
  