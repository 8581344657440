import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Autocomplete, Box, Chip, Divider, Stack, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Editor from '../../../components/editor/editor';
import { UPDATE_PITCH } from '../../../graphql/mutations/pitch';
import { CREATE_PITCH } from '../../../graphql/queries/pitch';
import { useBoolean } from '../../../hooks/use-boolean';
import { COMPANYFIELDS, TEMPLATES_TYPE } from '../../../utils/constants';
import { capitalizeFirstLetter, convertToCamelCase } from '../../../utils/helperFunctions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function AddTemplateDialog({ open, handleClose, contacts, setPitches, activePitch, type }) {
  const [createTemplate, { data: pitchData, loading, error }] = useMutation(CREATE_PITCH);
  const [updatePitch, { data: updatePitchData, loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PITCH);
  const [jobTitle, setJobTitle] = useState([]);
  const [notes, setNotes] = useState('');
  const { handleSubmit, register, setValue, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [create, setCreate] = useState(false);
  const fullScreen = useBoolean();
  const [uniqueProspectDesignations, setUniqueProspectDesignations] = useState([]);
  const validDesignations = uniqueProspectDesignations.filter((option) => option !== null && option !== undefined);

  useEffect(() => {
    if (pitchData && pitchData.createPitch && create) {
      enqueueSnackbar('Template created successfully', { variant: 'success' });
      setPitches(pitchData.createPitch);
      handleClose();
      setCreate(false);
    }
  }, [pitchData, enqueueSnackbar, handleClose, create, setPitches]);

  useEffect(() => {
    if (updatePitchData && updatePitchData.updatePitch && create) {
      enqueueSnackbar('Pitch updated successfully', { variant: 'success' });
      setPitches(updatePitchData.updatePitch);
      handleClose();
      setCreate(false);
    }
  }, [updatePitchData, enqueueSnackbar, handleClose, create, setPitches]);

  useEffect(() => {
    if (contacts?.length) {
      const uniqueProspectDesignations = [
        ...new Set(contacts.map((contact) => contact?.prospectDesignation).filter(Boolean)),
      ];
      setUniqueProspectDesignations(uniqueProspectDesignations);
    }
  }, [contacts]);

  useEffect(() => {
    if (activePitch) {
      setJobTitle(activePitch?.jobTitle?.split(','));
      setNotes(activePitch?.notes);
      setValue('title', activePitch?.title || '');
      setValue('sms', activePitch?.sms);
      setValue('subject', activePitch?.subject);
    }
  }, [activePitch, setValue]);

  useEffect(() => {
    if (activePitch) {
      setJobTitle(activePitch?.jobTitle?.split(','));
      setNotes(activePitch?.notes);
      setValue('title', activePitch?.title || '');
      setValue('sms', activePitch?.sms);
      setValue('subject', activePitch?.subject);
    } else {
      reset({
        title: '',
        sms: '',
        subject: '',
      });
      setJobTitle([]);
      setNotes('');
    }
  }, [activePitch, open, setValue, reset]);

  const handleChangeNotes = useCallback((value) => {
    setNotes(value);
  }, []);

  const onSubmit = (data) => {
    const myJobTitle = jobTitle.join(',');
    setCreate(true);
    if (activePitch) {
      updatePitch({
        variables: { _id: activePitch._id, input: { ...data, jobTitle: myJobTitle, notes } },
      });
    } else {
      createTemplate({
        variables: { input: { ...data, jobTitle: myJobTitle, notes, type } },
      });
    }
  };

  return (
    <div>
      <BootstrapDialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {activePitch
            ? `Edit ${capitalizeFirstLetter(type)} Template`
            : `Add New ${capitalizeFirstLetter(type)} Template`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {(error || updateError) && <Alert severity="error">{error?.message || updateError.message}</Alert>}

              <TextField name="title" required label="Template title" {...register('title', { required: true })} />

              <Autocomplete
                multiple
                id="tags-filled"
                options={validDesignations.map((option) => option)}
                freeSolo
                value={jobTitle}
                onChange={(event, newValue) => {
                  setJobTitle(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => <TextField {...params} label="Designation" placeholder="Designation" />}
              />

              <Alert severity="info">
                <Typography variant="h6">Dynamic Tags:</Typography>
                <Typography variant="caption" color="text.secondary">
                  {COMPANYFIELDS.map((option) => convertToCamelCase(option)).join(', ')}
                </Typography>
                <Typography variant="h6">Example:</Typography>
                <Typography variant="caption" color="text.secondary">
                  {`Example: Hi {{firstName}} {{lastName}}, I am reaching out to you because {{Reason For Outreach}}. I believe that {{SimplifiedCompanyName}} could benefit from {{ProductO Service}}. I would love to connect and discuss how we can work together.`}
                </Typography>
              </Alert>
                {type === TEMPLATES_TYPE.EMAIL && <TextField name="subject" required label="Subject" {...register('subject', { required: true })} />}

              {type === TEMPLATES_TYPE.SMS && (
                <TextField
                  name="sms"
                  multiline
                  rows={6}
                  required
                  label="SMS pitch"
                  {...register('sms', { required: true })}
                />
              )}

              {type === TEMPLATES_TYPE.EMAIL && (
                <>
                  {' '}
                  <Divider> Email Pitch</Divider>
                  <Editor
                    simple
                    id="compose-mail"
                    value={notes}
                    onChange={handleChangeNotes}
                    placeholder="Type a pitch..."
                    sx={{
                      '& .ql-editor': {},
                      ...(fullScreen.value && {
                        height: 1,
                        '& .quill': {
                          height: 1,
                        },
                        '& .ql-editor': {
                          maxHeight: 'unset',
                        },
                      }),
                    }}
                  />
                </>
              )}
              <Box>
                <LoadingButton loading={loading || updateLoading} type="submit" variant="contained">
                  Save
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

AddTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPitches: PropTypes.func.isRequired,
  activePitch: PropTypes.object || null,
  contacts: PropTypes.array,
  type: PropTypes.string,
};

export default AddTemplateDialog;
