export const LABELS = {
  Sequence: 'Outreach',
  Manage: 'Manage your flow for this reach campaigns',
  Users: 'Users',
  ManageUsers: 'Manage your users',
  Companies: 'Manage all companies',
  CompanyDescription: 'Manage your companies',
  Leaderboard: 'Leaderboard',
  LeaderboardDescription: 'Your workspace activity are here for you to analyze',
  Appointments: 'Appointments',
  AppointmentsDescription: 'Your appointments that you have set for yourself',
  ManageSequence: 'Manage Sequence flow',
  ManageSequenceDescription: 'Manage your sequence flow',
  Assets: 'Assets',
  AssetsDescription: 'Your assets are here for you to manage',
  Calls: 'Calls',
  CallsDescription: 'Your calls are here for you to manage',
  Scripts: 'Scripts',
  ScriptsDescription: 'Your scripts are here for you to manage',
  LearningCampus: 'Learning Camp',
  LearningCampusDescription: 'Your learning campus is here for you to learn',
  CallPolling: 'Call Polling',
  CallPollingDescription: 'Your call polling is here for you to poll',
  Campaigns: 'Campaigns',
  CampaignsDescription: 'Your campaigns are here for you to manage',
};
export const INTERRACTION_TYPES = {
  APPOINTMENT: 'APPOINTMENT',
  LINKEDINCONNECT: 'LINKEDINCONNECT',
  LINKEDINMESSAGE: 'LINKEDINMESSAGE',
  SLACK: 'SLACK',
  EMAIL: 'SLACK',
  CALL: 'CALL',
  SMS: 'SMS',
  TEXT: 'TEXT',
  TASK: 'TASK',
  VOICEMAIL: 'VOICEMAIL',
  SOCIAL: 'SOCIAL',
};
export const SEQUENCE_ICONS = {
  LINKEDINCONNECT: '/assets/icons/ic_linkedin_connect.svg',
  LINKEDINMESSAGE: '/assets/icons/ic_linkedin_message.svg',
  SLACK: '/assets/icons/ic_slack.svg',
  EMAIL: '/assets/icons/ic_email.svg',
  CALL: '/assets/icons/ic_call.svg',
  TEXT: '/assets/icons/ic_sms.svg',
  SMS: '/assets/icons/ic_sms.svg',
  TASK: '/assets/icons/ic_task.svg',
  VOICEMAIL: '/assets/icons/ic_voicemail.svg',
  SOCIAL: '/assets/icons/ic_social.svg',
};
export const SEQUENCE_MESSAGE = {
  LINKEDINCONNECT: 'Connections Due',
  LINKEDINMESSAGE: 'Messages Due',
  SLACK: 'Connect Due',
  EMAIL: 'Emails Due',
  CALL: 'Calls Due',
  TEXT: 'SMS Due',
};
export const STEPS = [
  {
    step: 1,
    stage: 'Day 1',
    title: 'Call - Day 1',
    description: 'Select the file to upload',
    tag: '',
    type: 'Call',
  },
  {
    step: 2,
    stage: 'Day 1',
    title: 'Manual Email - Day 1',
    description: 'Details: Meeting with {{first_name}} Hey {{first_name}}. I just did a local search f..',
    tag: '',
    type: 'Email',
  },
  {
    step: 3,
    stage: 'Day 1',
    title: 'Call Back',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 4,
    stage: 'Day 2',
    title: 'A. Copy of Automated Email - Day 2',
    description: 'Re: Subject from Day 1: Step 2 - Email Hi {{first_name}}. Are you free for a cha..',
    tag: 'Delay 5 mins',
    type: 'Email Started',
  },
  {
    step: 4,
    stage: 'Day 2',
    title: 'B. Copy of Automated Email - Day 2',
    description: 'Re: Subject from Day 1: Step 2 - Email Hi {{first_name}}. Are you free for a cha..',
    tag: 'Delay 5 mins',
    type: 'Email Started',
  },
  {
    step: 5,
    stage: 'Day 2',
    title: 'Call',
    description: 'Call',
    tag: '',
    type: 'Call',
  },
  {
    step: 6,
    stage: 'Day 3',
    title: 'Call - Day 3',
    description: 'Call',
    tag: '',
    type: 'Call',
  },
  {
    step: 7,
    stage: 'Day 3',
    title: 'Call',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 8,
    stage: 'Day 4',
    title: 'A. Auto Email - Day 4',
    description: 'Re: Subject from Day 2: Step 4 - Email Hey {{first_name}}. The best business..',
    tag: "At a specific time: 7:25AM person's timezone",
    type: 'Email Started',
  },
  {
    step: 8,
    stage: 'Day 4',
    title: 'B. Auto Email Day 4',
    description: 'Re: Subject from Day 2: Step 4 - Email Hey {{first_name}}. The best business..',
    tag: "At a specific time: 7:25AM person's timezone",
    type: 'Email Started',
  },
  {
    step: 9,
    stage: 'Day 4',
    title: 'Call',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 10,
    stage: 'Day 5',
    title: 'Call - Day 5',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 11,
    stage: 'Day 6',
    title: 'A. Automated Email - Day 6',
    description: 'Re: Subject from Day 4: Step 8 - Email Hey {{first_name}}. Have spoken with a..',
    tag: 'Time Delay: 5 mins',
    type: 'Call',
  },
  {
    step: 12,
    stage: 'Day 7',
    title: 'Call - Day 7',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 13,
    stage: 'Day 9',
    title: 'Call - Day 9',
    description: '',
    tag: '',
    type: 'Call',
  },
  {
    step: 14,
    stage: 'Day 9',
    title: 'A. Automated Email - Day 9',
    description: 'Could {{company}} grow as fast as Unstoppable Auto Group Hey {{first_name}}..',
    tag: 'Time Delay: 5 mins',
    type: 'Email Started',
  },
  {
    step: 14,
    stage: 'Day 9',
    title: 'B. Copy of Automated Email - Day 9',
    description: 'Meeting with {{first_name}} Hey {{first_name}}, <https://www.podium.com/cus',
    tag: 'Time Delay: 5 mins',
    type: 'Email Started',
  },
  {
    step: 15,
    stage: 'Day 11',
    title: 'Call - Day 11',
    description: 'VOICEMAIL!I VOICEMAIL!!!! VOICEMAIL!!!! VOICEMAIL!!!! VOICEMAILI!I VOICEMAIL!I! VOICEMAIL!!!',
    tag: 'Time Delay: 5 mins',
    type: 'Call',
  },
  {
    step: 16,
    stage: 'Day 11',
    title: 'Automated Email - Day 11 Automated',
    description: "Re: Subject from Day 9 Step 14 - Email Hey ([First_Name)), Unfortunately, we haven'...",
    tag: 'Time Delay: 5 mins',
    type: 'Email Started',
  },
  {
    step: 17,
    stage: 'Day 12',
    title: 'Call - Day 12',
    description: '',
    tag: '',
    type: 'Call',
  },
];

export const GROUPED_STEPS = {
  'Day 1': [
    {
      step: 1,
      stage: 'Day 1',
      title: 'Call - Day 1',
      description: 'Select the file to upload',
      tag: '',
      type: 'Call',
    },
    {
      step: 2,
      stage: 'Day 1',
      title: 'Manual Email - Day 1',
      description: 'Details: Meeting with {{first_name}} Hey {{first_name}}. I just did a local search f..',
      tag: '',
      type: 'Email',
    },
    {
      step: 3,
      stage: 'Day 1',
      title: 'Call Back',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 2': [
    {
      step: 4,
      stage: 'Day 2',
      title: 'A. Copy of Automated Email - Day 2',
      description: 'Re: Subject from Day 1: Step 2 - Email Hi {{first_name}}. Are you free for a cha..',
      tag: 'Delay 5 mins',
      type: 'Email Started',
    },
    {
      step: 4,
      stage: 'Day 2',
      title: 'B. Copy of Automated Email - Day 2',
      description: 'Re: Subject from Day 1: Step 2 - Email Hi {{first_name}}. Are you free for a cha..',
      tag: 'Delay 5 mins',
      type: 'Email Started',
    },
    {
      step: 5,
      stage: 'Day 2',
      title: 'Call',
      description: 'Call',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 3': [
    {
      step: 6,
      stage: 'Day 3',
      title: 'Call - Day 3',
      description: 'Call',
      tag: '',
      type: 'Call',
    },
    {
      step: 7,
      stage: 'Day 3',
      title: 'Call',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 4': [
    {
      step: 8,
      stage: 'Day 4',
      title: 'A. Auto Email - Day 4',
      description: 'Re: Subject from Day 2: Step 4 - Email Hey {{first_name}}. The best business..',
      tag: "At a specific time: 7:25AM person's timezone",
      type: 'Email Started',
    },
    {
      step: 8,
      stage: 'Day 4',
      title: 'B. Auto Email Day 4',
      description: 'Re: Subject from Day 2: Step 4 - Email Hey {{first_name}}. The best business..',
      tag: "At a specific time: 7:25AM person's timezone",
      type: 'Email Started',
    },
    {
      step: 9,
      stage: 'Day 4',
      title: 'Call',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 5': [
    {
      step: 10,
      stage: 'Day 5',
      title: 'Call - Day 5',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 6': [
    {
      step: 11,
      stage: 'Day 6',
      title: 'A. Automated Email - Day 6',
      description: 'Re: Subject from Day 4: Step 8 - Email Hey {{first_name}}. Have spoken with a..',
      tag: 'Time Delay: 5 mins',
      type: 'Call',
    },
  ],
  'Day 7': [
    {
      step: 12,
      stage: 'Day 7',
      title: 'Call - Day 7',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
  'Day 9': [
    {
      step: 13,
      stage: 'Day 9',
      title: 'Call - Day 9',
      description: '',
      tag: '',
      type: 'Call',
    },
    {
      step: 14,
      stage: 'Day 9',
      title: 'A. Automated Email - Day 9',
      description: 'Could {{company}} grow as fast as Unstoppable Auto Group Hey {{first_name}}..',
      tag: 'Time Delay: 5 mins',
      type: 'Email Started',
    },
    {
      step: 14,
      stage: 'Day 9',
      title: 'B. Copy of Automated Email - Day 9',
      description: 'Meeting with {{first_name}} Hey {{first_name}}, <https://www.podium.com/cus',
      tag: 'Time Delay: 5 mins',
      type: 'Email Started',
    },
  ],
  'Day 11': [
    {
      step: 15,
      stage: 'Day 11',
      title: 'Call - Day 11',
      description: 'VOICEMAIL!I VOICEMAIL!!!! VOICEMAIL!!!! VOICEMAIL!!!! VOICEMAILI!I VOICEMAIL!I! VOICEMAIL!!!',
      tag: 'Time Delay: 5 mins',
      type: 'Call',
    },
    {
      step: 16,
      stage: 'Day 11',
      title: 'Automated Email - Day 11 Automated',
      description: "Re: Subject from Day 9 Step 14 - Email Hey ([First_Name)), Unfortunately, we haven'...",
      tag: 'Time Delay: 5 mins',
      type: 'Email Started',
    },
  ],
  'Day 12': [
    {
      step: 17,
      stage: 'Day 12',
      title: 'Call - Day 12',
      description: '',
      tag: '',
      type: 'Call',
    },
  ],
};


export const SEQUENCE_TEMPLATE = [
  {
    step: 1,
    stage: "Day 1",
    title: "Call the prospect",
    description: "Call the prospect",
    type: "CALL",
  },
  {
    step: 2,
    stage: "Day 1",
    title: "Personalized Manual Email",
    description: "",
    type: "EMAIL",
  },
  {

    step: 3,
    stage: "Day 1",
    title: "CALL",
    description: "",
    type: "CALL",
  },
  {

    step: 4,
    stage: "Day 2",
    title: "Call the prospect again",
    description: "",
    type: "CALL",
  },
  {

    step: 5,
    stage: "Day 2",
    title: "Leave a text message",
    description: "",
    type: "TEXT",
  },
  {

    step: 6,
    stage: "Day 3",
    title: "Send the Email",
    description: "",
    type: "EMAIL",

  },
  {

    step: 7,
    stage: "Day 3",
    title: "Connect on Linkedin with a Personalized message",
    description: "",
    type: "LINKEDINCONNECT",
  },
  {

    step: 8,
    stage: "Day 3",
    title: "Call the prospect again",
    description: "",
    type: "CALL",
  },
  {

    step: 9,
    stage: "Day 5",
    title: "Call the prospect again",
    description: "",
    type: "CALL",
  },
  {

    step: 10,
    stage: "Day 5",
    title: "Leave a Text Message",
    description: "",
    type: "TEXT",
  },
  {

    step: 11,
    stage: "Day 7",
    title: "Send the Email",
    description: "",
    type: "EMAIL",
  },
  {

    step: 12,
    stage: "Day 7",
    title: "Call the prospect again",
    description: "",
    type: "CALL",
  },
  {

    step: 13,
    stage: "Day 9",
    title: "Call the prospect again.",
    description: "",
    type: "CALL",
  },
  {

    step: 14,
    stage: "Day 9",
    title: "Send a message on Linkedin",
    description: "",
    type: "LINKEDINMESSAGE",
  },
  {

    step: 15,
    stage: "Day 10",
    title: "Call the prospect again",
    description: "",
    type: "CALL",
  },
  {

    step: 16,
    stage: "Day 10",
    title: "Leave a text message",
    description: "",
    type: "TEXT",
  },
  {

    step: 17,
    stage: "Day 12",
    title: "Call Final Attempt",
    description: "",
    type: "CALL",
  },
  {
    step: 18,
    stage: "Day 12",
    title: "Send the final email",
    description: "",
    type: "EMAIL",
  }
]

export const CALL_STATUSES = [
  {
    value: 'ANSWERED',
    label: 'Answered',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
  },
  {
    value: 'REMOTE_REJECT',
    label: 'Remote Reject',
  },
  {
    value: 'LOCAL_REJECT',
    label: 'Local Reject',
  },
  {
    value: 'ENDED',
    label: 'Ended',
  }
]

export const COMPANYFIELDS = [
  'Job Source',
  'Job Post Title',
  'Job Type',
  'Job Post Date',
  'Job Location',
  'Company ID',
  'Simplified Company Name',
  'Company Domain Name',
  'Company Linkedin Profile',
  'Company Phone Number',
  'Company Email',
  'Simplified Company Location',
  'About Company',
  'Minimum Company Size',
  'Maximum Company Size',
  'Simplified Company Industry',
  'Generated Company Description',
  'Website Traffic Analysis',
  'Recommendation',
  'Nearby Location 1',
  'Nearby Location 2',
  'First Name',
  'Last Name',
  'Prospect Designation',
  'Prospect Simplified Designation',
  'Email Address',
  'Prospect Phone Number',
  'Prospect Location',
  'Prospect Simplified Location',
  'Prospect Linkedin Profile',
  'Prospect Description',
  'Contact Owner',
  'Contact Owner ID',
  'Job Post URL',
  'Website URL',
  'Type',
  'Year Founded',
  'Industry',
  'Time Zone',
  'UX Analysis Link',
  'Recommendation on Traffic Analysis',
  'Company Logo',
  'Industry Tags',
  'Maximum Annual Revenue',
  'Punch Company Description',
  'Website Screenshot',
  'Job Title',
  'Prospect Designation Description',
  'Places Nearby 1',
  'Places Nearby 2',
  'Minimum Annual Revenue',
  'Traffic Month 1',
  'Traffic Value 1',
  'Traffic Month 2',
  'Traffic Value 2',
  'Traffic Month 3',
  'Traffic Value 3',
  'Traffic Month 4',
  'Traffic Value 4',
  'Traffic Month 5',
  'Traffic Value 5',
  'Traffic Month 6',
  'Traffic Value 6',
  'Expert Document Link',
  'Step',
  'Stage'
];

export const TEMPLATES_TYPE = {
  CALL: 'CALL',
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  TEXT: 'TEXT'
}

export const callResposes = [
 {
   intent: [
     'why are you calling'
   ],
   answer: [
     'You dont know me ',
     'I am calling from punch'
   ],
  }

]