import { useLazyQuery, useMutation } from '@apollo/client';
import { Delete, Download, Edit, RefreshOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_TRIGGERS } from '../../../graphql/queries/trigger';
import { DELETE_TRIGGER } from '../../../graphql/mutations/trigger';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { fDateTime } from '../../../utils/formatTime';

export default function TriggersList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState(null);
  const [triggerToDelete, setTriggerToDelete] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [state, setState] = useState({
    page: 0,
    limit: 100,
    skip: 0,
  });

  const [getTriggers, { loading, data }] = useLazyQuery(GET_TRIGGERS);
  const [deleteTrigger] = useMutation(DELETE_TRIGGER, {
    onCompleted: () => {
      setOpenConfirmDialog(false);
      getTriggers({
        variables: {
          limit: state.limit,
          skip: state.skip,
          ...(debouncedSearch ? { search: debouncedSearch } : {}),
        },
      });
    },
    onError: (error) => {
      console.error('Error deleting trigger:', error);
    },
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    getTriggers({
      variables: {
        limit: state.limit,
        skip: state.skip,
        ...(debouncedSearch ? { search: debouncedSearch } : {}),
      },
    });
  }, [state, debouncedSearch]);

  const dataCount = useMemo(() => data?.getTriggers?.length || 0, [data]);

  const handleEditTrigger = (trigger) => {
    navigate('/sequence/create-trigger', {
      state: { isEdit: true, currentTrigger: trigger },
    });
  };

  const handleDeleteTrigger = (triggerId) => {
    setTriggerToDelete(triggerId);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = () => {
    if (triggerToDelete) {
      deleteTrigger({ variables: { triggerId: triggerToDelete } });
    }
  };
  return (
    <Grid item container xs={12} sm={12}>
      <>
        <Grid item xs={12} sm={12}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`Page -${state.skip / 100 + 1}`}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TextField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                placeholder="Search"
                variant="outlined"
              />
              <IconButton
                disabled={state.skip < 100 || loading}
                onClick={() => setState({ ...state, skip: state.skip - 100 })}
                aria-label="refresh"
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                onClick={() => setState({ ...state, skip: state.skip + 100 })}
                disabled={dataCount < 100 || loading}
                aria-label="refresh"
              >
                <ArrowForwardIosIcon />
              </IconButton>
              <IconButton onClick={() => getTriggers({ variables: { limit: state.limit, skip: state.skip } })}>
                <RefreshOutlined />
              </IconButton>
              {loading && <CircularProgress />}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Trigger Name</TableCell>
                  <TableCell>Conditions</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getTriggers?.map((trigger) => (
                  <TableRow key={trigger._id}>
                    {/* Trigger Name */}
                    <TableCell>{trigger.triggerName}</TableCell>

                    {/* Conditions */}
                    <TableCell>
                      {trigger.conditions.map((condition, index) => (
                        <div key={index}>
                          <Typography variant="body2">
                            <strong>Field:</strong> {condition.fieldName}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Operator:</strong> {condition.operator}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Value:</strong> {condition.value}
                          </Typography>
                        </div>
                      ))}
                    </TableCell>

                    {/* To (Action) */}
                    <TableCell>
                      {trigger.conditions.map((condition, index) => (
                        <Typography key={index} variant="body2">
                          <strong>Action:</strong> {condition.action}
                        </Typography>
                      ))}
                    </TableCell>

                    {/* Created At */}
                    <TableCell>{fDateTime(trigger.createdAt)}</TableCell>

                    {/* Actions (Edit/Delete) */}
                    <TableCell>
                      <IconButton onClick={() => handleEditTrigger(trigger)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteTrigger(trigger._id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Confirmation Dialog */}
        {openConfirmDialog && (
        <ConfirmationModal
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={confirmDelete}
          title="Delete Confirmation"
          description="Are you sure you want to delete this trigger?"
          subDescription="This action cannot be undone."
        />
      )}
      </>
    </Grid>
  );
}
