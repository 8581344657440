import React, { useState } from 'react';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import {
  Card,
  Typography,
  Stack,
  Divider,
  Grid,
  Box,
  IconButton,
  Collapse,
} from '@mui/material';
import { CheckCircle, MailOutline, Visibility, ExpandMore, ExpandLess } from '@mui/icons-material'; 
import useResponsive from '../../../hooks/useResponsive';
import { BaseOptionChart } from '../../../components/call-analytics/chart';
import { fNumber } from '../../../utils/formatNumber';

const CHART_SIZE = { width: 106, height: 106 };

const VERSION_A = {
  templateName: "Template A (New Pitch Finale)",
  message: "Welcome to our service! Enjoy a 20% discount on your first purchase. Shop now!",
  totalEmailsSent: 6,
  openRate: 0,
  clickThroughRate: 0,
  conversionRate: 0,
};

const VERSION_B = {
  templateName: "Template B (KDI Template)",
  message: "Get started today with a special offer just for you! Sign up now and receive 30% off.",
  totalEmailsSent: 3,
  openRate: 0,
  clickThroughRate: 0,
  conversionRate: 0,
};

// Chart data for conversion rates
const CHART_DATA_VERSION_A = [0];
const CHART_DATA_VERSION_B = [0];

const TEMPLATE_A_COLOR = '#4CAF50'; 
const TEMPLATE_B_COLOR = '#FF5722'; 

const ABTestEmails = () => {
  const [showMessageA, setShowMessageA] = useState(false);
  const [showMessageB, setShowMessageB] = useState(false);
  const isDesktop = useResponsive('up', 'sm');

  const chartOptionsVersionA = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    colors: [TEMPLATE_A_COLOR], 
    grid: {
      padding: { top: -9, bottom: -9 },
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '60%' }, // Adjust hollow size for more visual impact
        track: { 
          margin: 5, 
          background: '#e0e0e0' // Set the unfilled color to a grey shade
        },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: '16px',
            formatter: (val) => `${val}%`, // Ensure value is formatted correctly
          },
        },
      },
    },
  });
  
  
  
  const chartOptionsVersionB = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    colors: [TEMPLATE_B_COLOR],
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '60%' }, 
        track: {
          margin: 5,
          background: '#e0e0e0' 
        },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: '16px',
            formatter: (val) => `${val}%`,
          },
        },
      },
    },
  });
  
  

  return (
    <Card sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: 3 }}>
      <Typography variant="h5" align="center" sx={{ mb: 2, fontWeight: 'bold', backgroundColor: '#1976d2', color: '#fff', padding: '10px', borderRadius: '5px' }}>
        A/B Testing Comparison: {VERSION_A.templateName} vs {VERSION_B.templateName}
      </Typography>
      
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          {/* Version A Stats */}
          <Grid item xs={12} sm={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ mb: 1, color: TEMPLATE_A_COLOR, textAlign: 'center', backgroundColor: '#e8f5e9', padding: '8px', borderRadius: '5px' }}>
              {VERSION_A.templateName}
            </Typography>
            <ReactApexChart 
              type="radialBar" 
              series={CHART_DATA_VERSION_A} 
              options={chartOptionsVersionA} 
              {...CHART_SIZE} 
            />
            <Stack spacing={1} sx={{ mt: 1, textAlign: 'left', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#e1f5fe' }}>
                <MailOutline sx={{ color: '#1976d2', mr: 1 }} />
                <Typography variant="body1">Total Emails Sent: {fNumber(VERSION_A.totalEmailsSent)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#e1f5fe' }}>
                <Visibility sx={{ color: TEMPLATE_A_COLOR, mr: 1 }} />
                <Typography variant="body1">Open Rate: {VERSION_A.openRate}%</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#e1f5fe' }}>
                <CheckCircle sx={{ color: TEMPLATE_A_COLOR, mr: 1 }} />
                <Typography variant="body1">Click-Through Rate: {VERSION_A.clickThroughRate}%</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#e1f5fe' }}>
                <CheckCircle sx={{ color: TEMPLATE_A_COLOR, mr: 1 }} />
                <Typography variant="body1">Conversion Rate: {VERSION_A.conversionRate}%</Typography>
              </Box>
            </Stack>
            <Divider sx={{ my: 1 }} />
            <IconButton onClick={() => setShowMessageA(!showMessageA)}>
              {showMessageA ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <Collapse in={showMessageA}>
              <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                "{VERSION_A.message}"
              </Typography>
            </Collapse>
          </Grid>

          {/* Decorative Cross Line */}
          <Grid item xs={12} sm={2} sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Divider orientation="vertical" sx={{ borderStyle: 'dashed', height: '80%', borderColor: 'grey.500', position: 'absolute' }} />
            <Divider orientation="horizontal" sx={{ borderStyle: 'dashed', width: '80%', borderColor: 'grey.500', position: 'absolute', transform: 'translateY(50%)' }} />
          </Grid>

          {/* Version B Stats */}
          <Grid item xs={12} sm={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ mb: 1, color: TEMPLATE_B_COLOR, textAlign: 'center', backgroundColor: '#ffebee', padding: '8px', borderRadius: '5px' }}>
              {VERSION_B.templateName}
            </Typography>
            <ReactApexChart
              type="radialBar"
              series={CHART_DATA_VERSION_B}
              options={chartOptionsVersionB}
              {...CHART_SIZE}
            />
            <Stack spacing={1} sx={{ mt: 1, textAlign: 'left', width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#ffe0b2' }}>
                <MailOutline sx={{ color: TEMPLATE_B_COLOR, mr: 1 }} />
                <Typography variant="body1">Total Emails Sent: {fNumber(VERSION_B.totalEmailsSent)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#ffe0b2' }}>
                <Visibility sx={{ color: TEMPLATE_B_COLOR, mr: 1 }} />
                <Typography variant="body1">Open Rate: {VERSION_B.openRate}%</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#ffe0b2' }}>
                <CheckCircle sx={{ color: TEMPLATE_B_COLOR, mr: 1 }} />
                <Typography variant="body1">Click-Through Rate: {VERSION_B.clickThroughRate}%</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', backgroundColor: '#ffe0b2' }}>
                <CheckCircle sx={{ color: TEMPLATE_B_COLOR, mr: 1 }} />
                <Typography variant="body1">Conversion Rate: {VERSION_B.conversionRate}%</Typography>
              </Box>
            </Stack>
            <Divider sx={{ my: 1 }} />
            <IconButton onClick={() => setShowMessageB(!showMessageB)}>
              {showMessageB ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <Collapse in={showMessageB}>
              <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                "{VERSION_B.message}"
              </Typography>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ABTestEmails;
