import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CALL_POOL_VISIBILITY } from '../../graphql/queries/call.analytics';

const useCallPoolVisibilityStats = (dateInterval) => {
  const [getCallPoolVisibility, { data, loading, error }] = useLazyQuery(GET_CALL_POOL_VISIBILITY);
  useEffect(() => {
    if (dateInterval) {
      getCallPoolVisibility({ variables: { input: dateInterval } });
    }
  }, [dateInterval, getCallPoolVisibility]);

  return { data: data?.getCallPoolVisibility, loading, error };
};

export default useCallPoolVisibilityStats;
