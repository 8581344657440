import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import ManualConnectionDialog from '../components/call-analytics/ManualConnectionDialog';

const formatTime = (seconds) => {
  if (seconds === 0) return '0';
  if (seconds < 60 && seconds >= 1) return 'Less than a minute';
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  
  return `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}, ` : ''}${minutes} min`;
};


const MoreAnalyticsVisibility = ({ callPoolVisibility, callPoolVisibilityLoading }) => {
  const { user } = useSelector((state) => state.auth);
  const agentId = user?.twilioAgent?._id;
  const [selectedDate, setSelectedDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [openDialog, setOpenDialog] = useState(false);
  const [manualConnection, setManualConnection] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    timeSlot: '3 PM - 4 PM',
    connections: 0,
    agentId,
  });

  if (callPoolVisibilityLoading || !callPoolVisibility) {
    return <Typography>Loading...</Typography>;
  }

  const agentsData = callPoolVisibility.agents || [];
  const generalStats = callPoolVisibility.generalStats || {};

  const handleOpenDialog = () => {
    setManualConnection({ ...manualConnection, date: selectedDate });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = () => {
    console.log('Manual connection added:', manualConnection);
    handleCloseDialog();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setManualConnection((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        {/* Date Picker */}
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add Manual Connection
        </Button>
      </div>

     <ManualConnectionDialog
        open={openDialog}
        onClose={handleCloseDialog}
        manualConnection={manualConnection}
        selectedDate={selectedDate}
        onInputChange={handleInputChange}
        onSubmit={handleSubmit}
        agentId={agentId}
      />


      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6">Summary</Typography>
          <Typography>
            Total Connections: <b>{generalStats.totalConnections}</b>
          </Typography>
          <Typography>
            Total Time spent in pool: <b>{formatTime(generalStats.totalTimeSpent)}</b>
          </Typography>
          <Typography>
            Average Connections Per Hour: <b>{generalStats.avgConnectionsPerHour}</b>
          </Typography>
        </CardContent>
      </Card>

      {agentsData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 1800, borderCollapse: 'collapse' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                <TableCell sx={{ fontWeight: 'bold', width: '200px', border: '1px solid #ddd' }}>Sales Rep</TableCell>
                {[
                  '12 PM - 1 PM',
                  '1 PM - 2 PM',
                  '2 PM - 3 PM',
                  '3 PM - 4 PM',
                  '4 PM - 5 PM',
                  '5 PM - 6 PM',
                  '6 PM - 7 PM',
                  '7 PM - 8 PM',
                  '8 PM - 9 PM',
                ].map((time) => (
                  <TableCell
                    key={time}
                    sx={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      width: '190px',
                      border: '1px solid #ddd',
                    }}
                  >
                    {time}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    width: '250px',
                    border: '1px solid #ddd',
                    backgroundColor: '#e0f7fa',
                    textAlign: 'center',
                  }}
                >
                  Total Connections
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    width: '300px',
                    border: '1px solid #ddd',
                    backgroundColor: '#e0f7fa',
                    textAlign: 'center',
                  }}
                >
                  Total Time in Pool
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold',
                    width: '250px',
                    border: '1px solid #ddd',
                    backgroundColor: '#e0f7fa',
                    textAlign: 'center',
                  }}
                >
                  Average Connections
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agentsData.map((agent, index) => (
                <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                  <TableCell sx={{ width: '150px', border: '1px solid #ddd' }}>
                    {`${agent.agentDetails.firstName} ${agent.agentDetails.lastName}`}
                  </TableCell>
                  {agent.hourlyConnections.map((connections, hourIndex) => (
                    <TableCell key={hourIndex} sx={{ textAlign: 'center', width: '120px', border: '1px solid #ddd' }}>
                      {connections}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      width: '150px',
                      border: '1px solid #ddd',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      backgroundColor: '#e0f7fa',
                    }}
                  >
                    {agent.totalConnections}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '120px',
                      border: '1px solid #ddd',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      backgroundColor: '#e0f7fa',
                    }}
                  >
                    {formatTime(agent.totalTime)}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '150px',
                      border: '1px solid #ddd',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      backgroundColor: '#e0f7fa',
                    }}
                  >
                    {agent.averageConnections}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No data available for the selected date.</Typography>
      )}
    </Paper>
  );
};

export default MoreAnalyticsVisibility;
