import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { logOutUser } from '../redux/auth.slice';
import { LOGOUT_QUERY } from '../graphql/queries/auth';

const useLogout = (socket2, user) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logout] = useMutation(LOGOUT_QUERY);

  const handleLogout = useCallback(async () => {
    if (socket2) {
      socket2.emit('logout', user?.punchId);
    }

    dispatch(logOutUser());
    await logout();

    const env = process.env.REACT_APP_ENVIRONMENT;

    if (env !== 'local') {
      window.location.href = process.env.REACT_APP_DEFAULT_LOGIN_URL;
    }else {
      navigate('/login');
    }
  }, [dispatch, navigate, socket2, user]);

  return handleLogout;
};

export default useLogout;
