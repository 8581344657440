import { gql } from '@apollo/client';

export const GET_ALL_CALL_LOGS = gql`
  query {
    getCallLogs {
      _id
      user {
        firstName
        lastName
        email
        role
      }
      callDone
      voicemailDone
      talkDone
      recordingUrl
      name
      phoneNumber
      createdAt
    }
  }
`;

export const GET_PAGINATED_CALL_LOGS = gql`
  query getCallLogsPaginate($input: CallLogFilterInput!) {
    getCallLogsPaginate(input: $input) {
      _id
      user {
        firstName
        lastName
        email
        role
      }
      callDone
      voicemailDone
      talkDone
      recordingUrl
      name
      phoneNumber
      createdAt
    }
  }
`;

export const GET_PAGINATED_EMAIL_LOGS = gql`
  query getEmailLogsPaginate($limit: Float!, $skip: Float!) {
    getEmailLogsPaginate(limit: $limit, skip: $skip) {
      _id
      user {
        firstName
        lastName
        email
        role
      }
      emailSent
      emailPersonalized
      name
      email
      createdAt
    }
  }
`;

export const GET_ALL_EMAIL_LOGS = gql`
  query {
    getEmailLogs {
      _id
      user {
        firstName
        lastName
        email
        role
      }
      emailSent
      emailPersonalized
      name
      email
      createdAt
    }
  }
`;

export const GET_TOTAL_LOG_COUNT_BY_USER_BY_STEP = gql`
  query getTotalLogCountByUserByStep($user: String!, $step: String!) {
    getTotalLogCountByUserByStep(user: $user, step: $step)
  }
`;

export const GET_TOTAL_LOG_COUNT_BY_STEP = gql`
  query getTotalLogCountByStep($step: String!, $sequenceId: String!) {
    getTotalLogCountByStep(step: $step, sequenceId: $sequenceId)
  }
`;

export const GET_TOTAL_LOG_COUNT_FOR_ALL_AGENTS = gql`
  query getAgentLogCountByDateRange($from: DateTime!, $to: DateTime!) {
    getAgentLogCountByDateRange(from: $from, to: $to) {
      agentLogCountByDateRange {
        name
        email
        emailLogCount
        callLogCount
        smsLogCount
        linkedinMessageLogCount
        linkedinConnectLogCount
        totalLogCount
        timeLogged
      }
      callRecordings {
        _id
        count
      }
    }
  }
`;

export const GET_TOTAL_LOG_COUNT_FOR_ALL_AGENTS_T = gql`
  query getAgentLogCountByDateRangeT($from: DateTime!, $to: DateTime!) {
    getAgentLogCountByDateRangeT(from: $from, to: $to) {
      agentLogCountByDateRange {
        name
        email
        emailLogCount
        callLogCount
        smsLogCount
        linkedinMessageLogCount
        linkedinConnectLogCount
        totalLogCount
        timeLogged
      }
      callRecordings {
        _id
        count
      }
    }
  }
`;

export const GET_AGENT_PERFORMANCE_SUMMARY = gql`
  query {
    getBestPerformingAgentsByDateRange {
      bestPerformerOfYear
      bestPerformerOfMonth
      bestPerformerOfSprint
      bestPerformerOfQuarter
      totalAgentCount
    }
  }
`;

export const USER_ANALYTICS = gql`
  query getUserAnalyticsSummary($from: DateTime!, $to: DateTime!) {
    getUserAnalyticsSummary(from: $from, to: $to) {
      totalLeadsNotContacted
      totalDaysRemainingInMonth
      totalDaysRemainingInSprint
    }
  }
`;

export const GET_CALL_RECORDING_BY_PHONE_NUMBER = gql`
  query getRecordingsForPhoneNumber($phoneNumber: String!) {
    getRecordingsForPhoneNumber(phoneNumber: $phoneNumber) {
      recordingUuid
      s3Url
      recordingUrl
    }
  }
`;

export const GET_SMS_LOGS_PAGINATE = gql`
  query getSMSLogsPaginate($input: SMSLogFilterInput!) {
    getSMSLogsPaginate(input: $input) {
      _id
      user {
        firstName
        lastName
        email
      }
      phoneNumber
      name
      content
      messageId
      smsDone
      createdAt
    }
  }
`;

export const GET_CHAT_COMPLETION = gql`
  query completeChat($input: ChatCompletionInput!) {
    completeChat(input: $input)
  }
`;

export const GET_CONTACT_BY_KEYWORD = gql`
  query getContactsByKeyword($keyword: String!) {
    getContactsByKeyword(keyword: $keyword) {
      firstName
      lastName
      prospectPhoneNumber
      company
      prospectDesignation
      prospectDirectPhoneNumber
    }
  }
`;

export const GET_CALL_POLLING_LOG = gql`
  query getCallRecordingLogsPaginate($limit: Float!, $skip: Float!) {
    getCallRecordingLogsPaginate(limit: $limit, skip: $skip) {
      _id
      from
      direction
      to
      createdAt
      status
      conversationUuid
      recordingUrl
      recordingUuid
    }
  }
`;

export const GET_TWILIO_CALL_LOG = gql`
  query GetTwilioCallLogs($skip: Float!, $limit: Float!, $search: String) {
    getTwilioCallLogs(skip: $skip, limit: $limit, search: $search) {
      _id
      agentId {
        _id
      }
      callSid
      callRecordingUrl
      direction
      duration
      from
      to
      status
      createdAt
      leadId {
        _id
        callError {
          errorFlag
          errorMessage
        }
        firstName
        lastName
        prospectPhoneNumber
      }
    }
  }
`;

export const GET_PAGINATED_TWILIO_CALL_LOGS = gql`
  query GetTwilioCallLogsPaginate($input: CallLogFilterInput!) {
    getTwilioCallLogsPaginate(input: $input) {
      _id
      agentId {
        _id
        user {
          _id
          firstName
          lastName
        }
      }
      callSid
      callRecordingUrl
      direction
      duration
      from
      to
      status
      createdAt
      leadId {
        _id
        firstName
        lastName
        prospectPhoneNumber
      }
    }
  }
`;

export const GET_RETRYABLE_CALLS = gql`
  query FetchRetryableCalls($input: CallLogFilterInput!) {
    fetchRetryableCalls(input: $input) {
      _id
      callSid
      callRecordingUrl
      duration
      isManualEnd
      retryCall
      transcript
      to
      updatedAt
      createdAt
      agentId {
        _id
        name
      }
      leadId {
        _id
        company
        emailAddress
        firstName
        lastName
        prospectPhoneNumber
        timeZone
      }
    }
  }
`;
