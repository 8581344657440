import { io } from 'socket.io-client';

const recrowdlyApiUrl =
    process.env.REACT_APP_WS_RECROWDLY_APP_SERVER ||
    'https://stagingserverconvert.zwilt.com';


export const socket = io(
    `ws${
        recrowdlyApiUrl.includes('https') ? 's' : ''
    }://${recrowdlyApiUrl.replace(/https?:\/\//g, '')}`,
    {
        path: '/my-custom-path/',
    },
);


// export const closeSocket = () => {
//     if (socket) {
//         socket.disconnect();
//         socket = null; 
//     }
// };
