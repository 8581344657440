import { Helmet } from 'react-helmet-async';
// @mui
import { Breadcrumbs, Button, Container, Grid, Box, Link, Stack, Typography } from '@mui/material';
import HubIcon from '@mui/icons-material/Hub';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections
import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoreSequenceOverview from '../sections/@dashboard/core-sequence/CoreSequenceOverview';
import ReportConfirmationDialog from '../components/confirmation-dialog/reportConfirmationDialog';
import Loader from '../components/loader';
import { GET_CORE_SEQUENCE } from '../graphql/queries/core.sequence';
import SequenceAutoCaller from '../sections/@dashboard/sequence/SequenceAutoCaller';
import { LABELS } from '../utils/constants';
import { isAdmin } from '../utils/helperFunctions';
import CreateCoreSequenceDialog from '../sections/@dashboard/core-sequence/CreateCoreSequenceDialog';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: coreSequenceData,
    loading: coreSequenceLoading,
    error: coreSequenceError,
    refetch,
  } = useQuery(GET_CORE_SEQUENCE);
  const { user } = useSelector((state) => state.auth);
  const userIsAdmin = isAdmin(user.role);
  const [userHaveOptions, setUserHaveOptions] = useState(true);
  const [openCreateSeq, setOpenCreateSeq] = useState(false);
  const [allCoreSequence, setAllCoreSequence] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 100;
  const [open, setOpen] = useState(false);
  const [openSequenceAutoCaller, setOpenSequenceAutoCaller] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Overview
    </Typography>,
  ];

  const handleClose = () => {
    setOpen(false);
    setOpenCreateSeq(false);
  };

  const handleCloseSequenceAutoCaller = () => {
    setOpenSequenceAutoCaller(false);
  };

  useEffect(() => {
    if (!coreSequenceLoading && !coreSequenceError) {
      const newSequence = coreSequenceData?.getCoreSequence || [];
      setAllCoreSequence(newSequence);
    }
  }, [coreSequenceData]);

  const noCoreSequenceData = !allCoreSequence || allCoreSequence.length === 0;

  return (
    <>
      <Helmet>
        <title> Sequence | Sales App </title>
      </Helmet>
      {coreSequenceLoading ? (
        <Loader />
      ) : (
        <Container maxWidth="xl" sx={{ minHeight: '100vh' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing={0} alignItems="flex-start">
                  <Typography variant="h3">{`${user?.organization?.name} Outreach`}</Typography>
                  <Typography variant="caption">{LABELS.Manage}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  {userHaveOptions && (
                    <>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: '#08070833',
                          color: '#080708',
                        }}
                        size="small"
                        onClick={() => navigate('/sequence/archived-core-sequence')}
                        startIcon={<img src="/assets/icons/navbar/ic_sequence.svg" width="28" alt="sequence" />}
                      >
                        Archived Sequence
                      </Button>
                      {userIsAdmin && (
                        <Button
                          variant="outlined"
                          sx={{
                            borderColor: '#2065D1',
                            color: '#2065D1',
                            minHeight: 35,
                          }}
                          size="small"
                          onClick={() => setOpenCreateSeq(true)}
                          startIcon={<HubIcon />}
                        >
                          Create Sequence
                        </Button>
                      )}
                    </>
                  )}
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              {noCoreSequenceData ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 4,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#f9f9f9',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="h5" color="textSecondary" gutterBottom>
                    No Core Sequences Found
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    It seems like there are no core sequences available at the moment. You can create a new sequence to
                    get started.
                  </Typography>
                  {userIsAdmin && (
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: '#2065D1',
                        color: '#2065D1',
                        minHeight: 35,
                        marginTop: 2,
                      }}
                      onClick={() => setOpenCreateSeq(true)}
                      startIcon={<HubIcon />}
                    >
                      Create New Sequence
                    </Button>
                  )}
                </Box>
              ) : (
                <CoreSequenceOverview allCoreSequence={allCoreSequence} refetch={refetch} />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      <CreateCoreSequenceDialog
        open={openCreateSeq}
        handleClose={handleClose}
        allCoreSequence={allCoreSequence}
        setAllCoreSequence={setAllCoreSequence}
        refetch={refetch}
      />
      <ReportConfirmationDialog open={open} handleClose={handleClose} />
      {openSequenceAutoCaller && (
        <SequenceAutoCaller open={openSequenceAutoCaller} handleClose={handleCloseSequenceAutoCaller} />
      )}
    </>
  );
}
