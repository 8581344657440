import { gql } from '@apollo/client';

export const GET_CORE_SEQUENCE = gql`
  query GetCoreSequence {
    getCoreSequence {
      _id
      isFavorited
      members {
        _id
        firstName
        lastName
      }
      title
      description
      type
      tag
      createdAt
      leadStats {
        totalSteps
        totalLeads
        contactedLeads
        remainingLeads
      }
    }
  }
`;

export const GET_CORE_SEQUENCE_FOR_MEMBERS = gql`
  query GetCoreSequenceForMembers {
    getCoreSequenceForMembers {
      _id
      isFavorited
      members {
        _id
        firstName
        lastName
      }
      title
      description
      type
      tag
      createdAt
      leadStats {
        totalLeads
        contactedLeads
        remainingLeads
      }
    }
  }
`;

export const GET_ARCHIVED_CORE_SEQUENCE = gql`
  query GetArchivedCoreSequence {
    getArchivedCoreSequence {
      _id
      isFavorited
      members {
        _id
        firstName
        lastName
      }
      title
      description
      type
      tag
      createdAt
      leadStats {
        totalLeads
        contactedLeads
        remainingLeads
      }
    }
  }
`;
