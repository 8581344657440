import { Helmet } from 'react-helmet-async';
// @mui
import { Breadcrumbs, Button, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections

import { useLazyQuery, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import Loader from '../components/loader';
import { GET_ALL_CONTACTS, GET_ALL_LEADS } from '../graphql/queries/lead';
import { GET_ALL_PITCH } from '../graphql/queries/pitch';
import TemplatesTableView from '../sections/mail/templates/TemplatesTableView';
import { TEMPLATES_TYPE } from '../utils/constants';
import AddTemplateDialog from '../sections/mail/templates/AddTemplateDialog';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
         {children}
        </>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SmsTemplatePage() {
  const { data: allLeads, loading: loadinAllLeads } = useQuery(GET_ALL_LEADS);
  const { data: allContacts, loading: loadingAllContacts } = useQuery(GET_ALL_CONTACTS);
  const [getPitches, { data: allPitches, loading: loadingAllPitches }] = useLazyQuery(GET_ALL_PITCH);
  const [leads, setLeads] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newPitch, setNewPitch] = useState(null);
  const [pitches, setPitches] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [activePitch, setActivePitch] = useState(null);

  useEffect(() => {
    if (allLeads?.getAllLeads) {
      setLeads(allLeads?.getAllLeads);
    }
  }, [allLeads]);

  useEffect(() => {
    if (allContacts?.getAllContacts) {
      setContacts(allContacts?.getAllContacts);
    }
  }, [allContacts]);

  useEffect(() => {
    if (allPitches?.getPitch) {
      setPitches(allPitches?.getPitch);
    }
  }, [allPitches]);


  useEffect(() => {
    getPitches({ variables: { type: TEMPLATES_TYPE.SMS } }); 
  }, [newPitch, getPitches]);

  const handleUpdatePitch = useCallback((pitch) => {
    setActivePitch(pitch);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAddNewTemplate = () => {
    setOpen(true);
    setActivePitch(null);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/sequence/sms">
      Sms
    </Link>,
    <Typography key="3" color="text.primary">
      All Companies
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Sms Templates | Sales App </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">Manage Sms Templates</Typography>
                <Typography variant="caption">Add, edit, delete templates</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
              <Button
                  startIcon={<img width="30" src="/assets/icons/speaker-svgrepo-com.svg" alt="pitch" />}
                  onClick={handleAddNewTemplate}
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    border: '1px solid rgba(145, 158, 171, 0.24)',
                  }}
                >
                  Add New SMS Template
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ width: '100%' }}>
              {loadingAllPitches ? (
                  <Loader />
                ) : (
                  <TemplatesTableView pitches={pitches} handleUpdatePitch={handleUpdatePitch} type={TEMPLATES_TYPE.SMS}/>
                )}
            </Box>
          </Grid>
        </Grid>
        <AddTemplateDialog open={open} handleClose={handleClose} contacts={contacts} setPitches={setNewPitch} activePitch={activePitch} type={TEMPLATES_TYPE.SMS}/>
      </Container>
    </>
  );
}
