export function getZwiltStoreDomain() {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return 'http://localhost:3001';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return 'https://staging.zwilt.com';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        return 'https://app.zwilt.com';
    }
    return '';
}

export function getSalesAppDomain() {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return 'http://localhost:3000';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return 'https://sales.zwilt.com/sequence/app';
    }
    return 'https://sales.zwilt.com/sequence/app';
}

export function getRecrowdlyAppDomain() {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return 'http://localhost:3000';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return 'https://stagingconvert.zwilt.com';
    }
    return 'https://convert.zwilt.com';
}

export function getTrackerApp() {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return 'http://localhost:3000';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return 'https://staging-tracker.zwilt.com';
    }
    return 'https://tracker.zwilt.com';
}

export function getZwiltSettingApp() {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        return 'http://localhost:3000';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        return 'https://staging-settings.zwilt.com';
    }
    return 'https://settings.zwilt.com';
}

export const MAIL_URL = "https://punch.agency:2096/cpsess1779694814/3rdparty/roundcube/index.php?_task=mail&_mbox=INBOX";