import React, { useEffect, useState, useRef } from 'react';
import { Menu, MenuItem, CircularProgress, Typography, Box, Divider, TextField, InputAdornment } from '@mui/material';
import { Group as GroupIcon, ArrowForward as ArrowForwardIcon, Search as SearchIcon } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { GET_CORE_SEQUENCE_FOR_MEMBERS } from '../../../graphql/queries/core.sequence';

const CoreSequenceMenu = ({ onJoinSequence, open, anchorEl, onClose }) => {
  const { data: coreSequenceData, loading: coreSequenceLoading, error: coreSequenceError } = useQuery(GET_CORE_SEQUENCE_FOR_MEMBERS);
  const [sequences, setSequences] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef(null); 

  useEffect(() => {
    setSequences(coreSequenceData?.getCoreSequenceForMembers || []);
  }, [coreSequenceData]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const filteredSequences = sequences.filter(sequence =>
    sequence?.title?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const handleMenuKeyDown = (event) => {
    const inputElement = inputRef.current;
    if (inputElement && inputElement.contains(document.activeElement)) {
      if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
        event.preventDefault();
      }
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxHeight: 350, width: '90%', maxWidth: '320px', padding: '10px' },
        elevation: 4,
      }}
      onKeyDown={handleMenuKeyDown}
    >
      <Box sx={{ padding: '10px', textAlign: 'center' }}>
        <Typography variant="subtitle1" fontWeight="bold" color="grey.700">
          Select a Sequence Pool to Join
        </Typography>
        <Divider sx={{ margin: '8px 0' }} />
        <TextField
          placeholder="Search sequence"
          variant="outlined"
          size="small"
          fullWidth
          autoFocus
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: '5px' }}
          onKeyDown={(event) => event.stopPropagation()}
        />
      </Box>

      {coreSequenceLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
          <CircularProgress />
        </Box>
      ) : coreSequenceError ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px', height: '150px' }}>
          <Typography variant="body2" color="error" textAlign="center">
            Error loading core sequences.
          </Typography>
        </Box>
      ) : (
        filteredSequences.map((sequence, index) => (
          <React.Fragment key={sequence._id}>
            <MenuItem
              onClick={() => {
                onJoinSequence(sequence._id);
                onClose();
              }}
              tabIndex={-1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '12px 16px',
                borderRadius: '8px',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: '#f0f4ff',
                  transform: 'translateY(-2px)',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" fontWeight="bold" color="grey.800">
                  {sequence.title}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                  <GroupIcon fontSize="small" sx={{ color: '#3f51b5', marginRight: '4px' }} />
                  <Typography variant="caption" sx={{ color: 'grey.600' }}>
                    Remaining Leads: {sequence.leadStats?.remainingLeads || 'N/A'}
                  </Typography>
                </Box>
              </Box>
              <ArrowForwardIcon sx={{ color: '#3f51b5', fontSize: '20px' }} />
            </MenuItem>
            {index < filteredSequences.length - 1 && <Divider sx={{ margin: '8px 0' }} />}
          </React.Fragment>
        ))
      )}

      {filteredSequences.length === 0 && !coreSequenceLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px', height: '150px' }}>
          <Typography variant="body2" color="textSecondary">
            No sequences found.
          </Typography>
        </Box>
      )}
    </Menu>
  );
};

export default CoreSequenceMenu;
