import { useCallback, useEffect, useState } from 'react';
// @mui
import { PlusOneOutlined, SmsOutlined } from '@mui/icons-material';
import { Avatar, Badge, Button, Divider, ListItemText } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// hooks
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useBoolean } from '../../../hooks/use-boolean';
import { useResponsive } from '../../../hooks/use-responsive';

// components
import MailList from '../mail-list';
import EmptyContent from '../../../components/empty-content';
import SMSCompose from '../sms-compose';
import { GET_ALL_SMS_BY_CONVERSATION, GET_SMS_CONVERSATION } from '../../../graphql/queries/sms';
import ChatMessageList from '../chat-message-list';
import ChatMessageInput from '../chat-message-input';
import { SEND_SMS } from '../../../graphql/mutations/sms';
import { fToNow } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

export default function SmsView() {
  const [getAllSmsByConversation, { data, loading }] = useLazyQuery(GET_ALL_SMS_BY_CONVERSATION);
  const { data: conversationData, refetch: refetchConversations } = useQuery(GET_SMS_CONVERSATION);
  const [sendSMS, { data: smsData, error, loading: smsLoading }] = useMutation(SEND_SMS);
  const [chatState, setChatState] = useState({
    loader: true,
    conversations: [],
    byId: {},
    currentConversationId: null,
    conversationsStatus: {
      loading: false,
      empty: false,
      error: null,
    },
  });

  const navigate = useNavigate();
  const [logData, setLogData] = useState(null);

  const mdUp = useResponsive('up', 'md');

  const openNav = useBoolean();

  const activeLead = null;

  const activeContact = null;

  const openMail = useBoolean();

  const openCompose = useBoolean();

  const firstMailId = '';

  const currentConversation = chatState?.byId[chatState.currentConversationId];

  const currentMessages = currentConversation?.chats ?? [];

  const onSendMessage = (message) => {
    const data = {
      to: chatState?.byId[chatState.currentConversationId]?.to,
      text: message,
    };

    sendSMS({
      variables: { input: data },
      onCompleted: () => {
        getAllSmsByConversation({ variables: { id: chatState?.currentConversationId } });
      },
    });
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Error while sending sms', { variant: 'error' });
    }
  }, [error]);

  useCallback(
    (message) => {
      const data = {
        to: chatState?.byId[chatState.currentConversationId]?.to,
        text: message,
      };

      sendSMS({ variables: { input: data } });
    },
    [sendSMS, chatState]
  );

  useEffect(() => {
    if (logData) {
      refetchConversations();
      setLogData(null);
    }
  }, [logData, refetchConversations]);

  const handleToggleCompose = useCallback(() => {
    if (openNav.value) {
      openNav.onFalse();
    }
    openCompose.onToggle();
  }, [openCompose, openNav]);

  const handleCloseInteraction = useCallback(
    (data) => {
      openMail.onFalse();
      setLogData(data);
    },
    [openMail]
  );

  const handleClickMail = useCallback(
    (mailId) => {
      if (!mdUp && mailId) {
        openMail.onFalse();
      }
    },
    [openMail, mdUp]
  );

  const onClickMail = useCallback((chatId) => {
    setChatState((prevState) => ({
      ...prevState,
      currentConversationId: chatId,
    }));
  }, []);

  const handleSmsTemplate = () => {
    navigate('/sequence/sms-templates');
  };

  useEffect(() => {
    if (conversationData?.getSmsConversation) {
      const conversations =
        conversationData?.getSmsConversation?.map(({ _id, user, to, lastSms, createdAt, updatedAt, name }) => ({
          _id,
          user,
          to,
          name,
          lastSms,
          createdAt,
          updatedAt,
          chats: [],
        })) ?? [];

      const byId = (prevState) =>
        conversations.reduce((acc, conversation) => {
          acc[conversation._id] = { ...conversation, chats: prevState?.byId[conversation._id]?.chats ?? [] };
          return acc;
        }, {});
      setChatState((prevState) => ({
        ...prevState,
        loader: false,
        currentConversationId: conversations?.[0]?._id,
        byId: byId(prevState),
        conversations,
        conversationsStatus: {
          error: null,
          empty: false,
          loading: true,
        },
      }));
    }

    return () => {};
  }, [conversationData]);

  useEffect(() => {
    if (chatState?.currentConversationId || smsData?.sendSms) {
      console.log('this code is running');
      getAllSmsByConversation({ variables: { id: chatState?.currentConversationId } });
    }
  }, [chatState?.currentConversationId, smsData, getAllSmsByConversation]);

  useEffect(() => {
    if (data?.getAllSmsByConversation?.length) {
      setChatState((prevState) => ({
        ...prevState,
        byId: {
          ...prevState.byId,
          [data.getAllSmsByConversation?.[0]?.conversation?._id]: {
            ...prevState.byId[data.getAllSmsByConversation?.[0]?.conversation?._id],
            chats: data.getAllSmsByConversation,
          },
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    if (firstMailId) {
      handleClickMail(firstMailId);
    }
  }, [firstMailId, handleClickMail]);

  useEffect(() => {
    if (openCompose.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompose.value]);

  const renderMailList = (
    <MailList
      mails={chatState.conversations}
      loading={chatState.loader}
      openMail={openMail.value}
      onCloseMail={openMail.onFalse}
      onClickMail={onClickMail}
      selectedLabelId={chatState.currentConversationId}
    />
  );

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatMessageList messages={currentMessages} />

      <ChatMessageInput
        onSendMessage={onSendMessage}
        currentConversationId={chatState?.currentConversationId}
        smsLoading={smsLoading}
        smsData={smsData}
      />
    </Stack>
  );

  const renderHead = (
    <Stack direction="row" alignItems="center" flexShrink={0} sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}>
      <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
        <Badge variant={'dot'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Avatar src={''} alt={currentConversation?.name || currentConversation?.to} />
        </Badge>

        <ListItemText
          primary={currentConversation?.name || currentConversation?.to}
          secondary={fToNow(currentConversation?.updatedAt)}
          primaryTypographyProps={{
            component: 'span',
            style: { fontSize: '0.875rem' },
          }}
          secondaryTypographyProps={{
            component: 'span',
            style: { fontSize: '0.75rem' },
            textTransform: 'capitalize',
          }}
        />
      </Stack>
    </Stack>
  );
  return (
    <>
      <Container maxWidth={'xl'}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h4"
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          >
            SMS
          </Typography>
          <Stack direction="row" gap={2}>
            <Button variant="outlined" onClick={handleSmsTemplate} startIcon={<SmsOutlined />}>
              SMS Templates
            </Button>
            <Button variant="contained" onClick={handleToggleCompose} startIcon={<PlusOneOutlined />}>
              New SMS
            </Button>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          flexGrow={1}
          sx={{
            p: 2,
            height: {
              xs: '72vh',
              md: 'calc(100vh - 100px)',
            },
          }}
        >
          {renderMailList}
          <Divider orientation="vertical" flexItem />
          {chatState.conversations?.length ? (
            <Stack
              flexGrow={1}
              sx={{
                width: 1,
                minWidth: 0,
                borderRadius: 1.5,
                bgcolor: 'background.default',
              }}
            >
              {renderHead}
              <Stack
                direction="row"
                sx={{
                  width: 1,
                  height: 1,
                  overflow: 'hidden',
                  borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                {renderMessages}
              </Stack>
            </Stack>
          ) : (
            <EmptyContent
              title="No Conversation Selected"
              description="Select a conversation to read"
              imgUrl="/assets/icons/empty/ic_email_selected.svg"
              sx={{
                borderRadius: 1.5,
                bgcolor: 'background.default',
              }}
            />
          )}
        </Stack>
        {openCompose.value && (
          <SMSCompose
            onCloseCompose={openCompose.onFalse}
            handleCloseInteraction={handleCloseInteraction}
            defaultRecipients={null}
            activeLead={activeLead}
            contact={activeContact}
          />
        )}
      </Container>
    </>
  );
}
