import { gql } from '@apollo/client';

export const USER = gql`
  fragment UserFields on User {
    _id
    punchId
    firstName
    lastName
    email
    role
    status
    hubSpotId
    created_at
    username
    signature
    organization{
      name
    }
    twilioAgent {
      _id
    }
  }
`;
