import { gql } from '@apollo/client';

export const GET_PROMPTS = gql`
  query GetPrompts($sequenceId: String!, $skip: Float!, $limit: Float!) {
    getPrompts(sequenceId: $sequenceId, skip: $skip, limit: $limit) {
      _id
      title
      prompt
      isActive
      user {
        _id
      }
      coreSequence {
        _id
      }
      createdAt
      updatedAt
    }
  }
`;
