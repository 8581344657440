import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ArrowForward, ArrowUpward, LinkedIn } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import {
  Alert,
  Avatar,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_LEAD_CONTACTS, GET_LEAD_TEMPLATE, GET_LEADS_BY_STEP } from '../../../../graphql/queries/lead';
import AutoSMSCompose from './AutoSmsCompose';
import { setTargetLeads } from '../../../../redux/service.slice';
import { useBoolean } from '../../../../hooks/use-boolean';
import Iconify from '../../../../components/iconify';
import { getEmailAddresses } from '../../../../utils/helperFunctions';
import { TEMPLATES_TYPE } from '../../../../utils/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function SequenceAutoSmsCard({
  lead,
  type,
  defaultContacts = [],
  setSelectedCompanyContacts,
  selectedCompanyContacts,
}) {
  const isCurrentLead = useMemo(() => type === 'Current Lead', [type]);

  return (
    <Card
      sx={{
        p: 2,
        height: isCurrentLead ? 'auto' : '18vh',
        overflowY: 'auto',
        borderRadius: 2,
        boxShadow: 3,
        transition: 'all 0.3s ease',
        '@media (max-width: 600px)': {
          p: 1,
          height: 'auto',
        },
      }}
    >
      {/* Header Section */}
      <Stack direction="row" justifyContent="space-between" spacing={2} alignItems="center">
        <Stack direction="row" spacing={2}>
          <CorporateFareIcon />
          <Stack>
            <Typography variant="h6">{lead?.simplifiedCompanyName}</Typography>
            <Typography variant="caption">{lead?.simplifiedCompanyLocation}</Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            src={lead?.companyLogo || 'https://via.placeholder.com/150?text=No+Logo'}
            alt="Company Logo"
            sx={{ width: 40, height: 40, borderRadius: '50%' }}
          />
          {type === 'Current Lead' ? <ArrowUpward /> : <ArrowForward />}
          <Typography variant="h5">{type}</Typography>
        </Stack>
      </Stack>

      {/* Additional Information */}
      <Typography variant="body2" sx={{ mt: 1 }}>
        <b>Industry:</b> {lead?.industry}
      </Typography>
      <Typography variant="body2">
        <b>Traffic Analysis:</b> {lead?.websiteTrafficAnalysis}
      </Typography>

      {isCurrentLead ? (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Contacts
          </Typography>

          {lead?.contacts
            ?.filter((contact) => contact?.company === lead?.simplifiedCompanyName)
            ?.slice(0, 1)
            ?.map((contact) => (
              <Stack key={contact._id} spacing={1} sx={{ mt: 1 }}>
                <Typography variant="body2">
                  <b>Prospect:</b> {contact?.firstName} {contact?.lastName}
                </Typography>
                <Typography variant="body2">
                  <b>Email:</b>{' '}
                  {contact?.emailAddress ? (
                    <a
                      href={`mailto:${contact.emailAddress}`}
                      style={{ color: 'primary.main', textDecoration: 'none' }}
                    >
                      {contact.emailAddress}
                    </a>
                  ) : (
                    'No email found'
                  )}
                </Typography>
                <Typography variant="body2">
                  <b>Phone:</b> {contact?.prospectPhoneNumber || 'No phone number'}
                </Typography>
                <Typography variant="body2">
                  <b>Time Zone:</b> {contact?.timeZone || 'Unknown'}
                </Typography>
              </Stack>
            ))}
        </>
      ) : (
        <FormControl sx={{ mt: 2 }}>
          <FormLabel>Contacts</FormLabel>
          <RadioGroup
            value={typeof selectedCompanyContacts !== 'undefined' ? selectedCompanyContacts[lead?._id] || 0 : 0}
            onChange={(e) =>
              setSelectedCompanyContacts({
                ...selectedCompanyContacts,
                [lead?._id]: parseInt(e.target.value, 10),
              })
            }
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {lead?.contacts
              ?.filter((contact) => contact?.company === lead?.simplifiedCompanyName)
              ?.map((contact, index) => (
                <FormControlLabel
                  key={index}
                  value={index}
                  control={<Radio />}
                  label={`${contact?.firstName} ${contact?.lastName} - ${contact?.prospectPhoneNumber}`}
                />
              ))}
          </RadioGroup>
        </FormControl>
      )}

      {/* Additional Links */}
      <Stack direction="row" spacing={2} justifyContent="flex-start" sx={{ mt: 2 }}>
        {/* LinkedIn Link */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <LinkedIn />
          {lead?.companyLinkedinProfile ? (
            <Typography
              variant="body2"
              component="a"
              href={
                lead.companyLinkedinProfile.startsWith('http')
                  ? lead.companyLinkedinProfile
                  : `https://${lead.companyLinkedinProfile}`
              }
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
            >
              LinkedIn
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No LinkedIn Profile
            </Typography>
          )}
        </Stack>

        {/* Job Post Link */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <Link />
          {lead?.jobPostUrl ? (
            <Typography
              variant="body2"
              component="a"
              href={lead.jobPostUrl.startsWith('http') ? lead.jobPostUrl : `https://${lead.jobPostUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', color: 'primary.main', cursor: 'pointer' }}
            >
              Job Posting
            </Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No Job Posting Available
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}

const PAGE_SIZE = 50;
const PRELOAD_THRESHOLD = 1;
function SequenceAutoSms({ open, handleClose }) {
  const dispatch = useDispatch();
  const { sequenceId } = useParams();
  const { leads, step } = useSelector((state) => state.lead);
  const [offset, setOffset] = useState(0);
  const { data, loading, error, fetchMore } = useQuery(GET_LEADS_BY_STEP, {
    variables: { step, sequenceId, limit: PAGE_SIZE, offset },
    skip: !step,
    notifyOnNetworkStatusChange: true,
  });

  const [nextSms, setNextSms] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [activeLeadIndex, setActiveLeadIndex] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const [selectedCompanyContacts, setSelectedCompanyContacts] = useState({});
  const [activeContacts, setActiveContacts] = useState([]);
  const [activeContact, setActiveContact] = useState(null);
  const [openSequence, setOpenSequence] = useState(false);
  const [activeLeadTemplate, setActiveLeadTemplate] = useState(null);
  const [getLeadContacts, { loading: loadingGettingContacts, data: contactsData }] = useLazyQuery(GET_LEAD_CONTACTS);
  const currentLead = useMemo(() => leads[activeStep], [leads, activeStep]);
  const nextLead = useMemo(() => leads[activeStep + 1] || null, [leads, activeStep]);
  const emailAddresses = getEmailAddresses(currentLead);

  const {
    data: leadTemplateData,
    loading: loadingTemplate,
    error: templateError,
    refetch,
  } = useQuery(GET_LEAD_TEMPLATE, {
    variables: { input: { sequenceId, leadId: currentLead?._id, step: currentLead?.step, type: TEMPLATES_TYPE.EMAIL } },
    skip: true,
  });

  useEffect(() => {
    if (currentLead?._id) {
      refetch({
        input: {
          sequenceId,
          leadId: currentLead._id,
          step: currentLead.step,
          type: TEMPLATES_TYPE.SMS
        },
      }).then((data) => {
        if (data?.data?.getLeadTemplate) {
          setActiveLeadTemplate(data?.data?.getLeadTemplate || null);
        } else {
          setActiveLeadTemplate(null);
        }
      });
    }
  }, [currentLead, refetch, sequenceId]);

  const testRefetch = () => {
    handleMoveToNext();
    // refetch({
    //   input: {
    //     sequenceId,
    //     leadId: currentLead._id,
    //     step: currentLead.step,
    //   }
    // });
  };

  useEffect(() => {
    if (data && data.getLeadsByStep && activeStep < 1) {
      dispatch(setTargetLeads(data.getLeadsByStep));
      if (data.getLeadsByStep.length < PAGE_SIZE) {
        setIsLastStep(true);
      }
    }
  }, [data, dispatch]);

  const emailNextPerson = () => {
    const remainingSteps = leads.length - (activeLeadIndex + 1);

    if (remainingSteps <= PRELOAD_THRESHOLD) {
      if (!isLastStep) {
        fetchMore({
          variables: {
            offset: offset + PAGE_SIZE,
            step,
            limit: PAGE_SIZE,
          },
        })
          .then(({ data }) => {
            const newLeads = data.getLeadsByStep || [];
            const combinedLeads = [...leads, ...newLeads];
            dispatch(setTargetLeads(combinedLeads));

            if (newLeads.length < PAGE_SIZE) {
              setIsLastStep(true);
            }
            setOffset((prevOffset) => prevOffset + PAGE_SIZE);
          })
          .catch((err) => {
            console.error('Error fetching more leads:', err);
          });
      }
    }

    if (leads[activeStep + 1]) {
      setActiveStep((prev) => prev + 1);
      setActiveLeadIndex(activeLeadIndex + 1);
      setIsLastStep(false);
    } else {
      setIsLastStep(true);
    }
  };

  const handleMoveToNext = () => {
    setOpenSequence(false);
    setActiveContacts([]);
    emailNextPerson();
  };

  useEffect(() => {
    setActiveContacts([]);
    setActiveStep(0);
    setActiveLeadIndex(1);
  }, [open]);

  useEffect(() => {
    getLeadContacts({
      variables: {
        company: currentLead?.simplifiedCompanyName,
      },
    });
  }, [currentLead, getLeadContacts]);

  useEffect(() => {
    if (contactsData?.getLeadContacts) {
      setActiveContacts(contactsData?.getLeadContacts);
    }
  }, [contactsData?.getLeadContacts, loadingGettingContacts]);

  useEffect(() => {
    if (activeContacts?.length > 0) {
      setActiveContact(activeContacts[0]);
      setOpenSequence(true);
    } else {
      setActiveContact([]);
      setOpenSequence(false);
    }
  }, [activeContacts]);

  useEffect(() => {
    if (nextSms) {
      handleMoveToNext();
    }
  }, [nextSms]);

  // auto-mailer states
  const openNav = useBoolean();
  const openCompose = useBoolean();
  const openMail = useBoolean();

  const handleToggleCompose = useCallback(() => {
    if (openNav.value) {
      openNav.onFalse();
    }
    openCompose.onToggle();
  }, [openCompose, openNav]);

  const handleCloseInteraction = useCallback(
    (data) => {
      openMail.onFalse();
    },
    [openMail]
  );

  return (
    <div>
      <BootstrapDialog fullScreen onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Auto Sms
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {currentLead && (
              <Grid item xs={12} sm={nextLead ? 6 : 12}>
                <SequenceAutoSmsCard
                  lead={currentLead}
                  type="Current Lead"
                  defaultContacts={activeContacts}
                  setSelectedCompanyContacts={setSelectedCompanyContacts}
                  selectedCompanyContacts={selectedCompanyContacts}
                />
              </Grid>
            )}

            {nextLead && (
              <Grid item xs={12} sm={6}>
                <SequenceAutoSmsCard
                  lead={nextLead}
                  type="Next Lead"
                  setSelectedCompanyContacts={setSelectedCompanyContacts}
                  selectedCompanyContacts={selectedCompanyContacts}
                />
              </Grid>
            )}
            {loadingGettingContacts ||
              (loading && (
                <Grid item xs={12} sm={12} md={12}>
                  <LinearProgress />
                </Grid>
              ))}
            <Grid item xs={12} sm={6} container direction="column" spacing={2} sx={{ height: '100%' }}>
              <Grid item>
                <Alert severity="info">
                  <Typography variant="h6">Dynamic Tags for Personalization:</Typography>
                  <Typography variant="body2" color="text.secondary">
                    The template below is personalized for each lead. Dynamic tags like{' '}
                    <strong>
                      {'{{firstName}}'}, {'{{lastName}}'}
                    </strong>
                    , and <strong>{'{{Reason For Outreach}}'}</strong> will be automatically replaced with the actual
                    lead's details. Make sure to review and click send when ready!
                  </Typography>
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                    Example: "Hi {'{{firstName}}'} {'{{lastName}}'}, I am reaching out to you because{' '}
                    {'{{Reason For Outreach}}'}. I believe that {'{{SimplifiedCompanyName}}'} could benefit from{' '}
                    {'{{Product or Service}}'}. I would love to connect and discuss how we can work together."
                  </Typography>
                </Alert>
              </Grid>

              {!loadingGettingContacts && !loading && (
                <Grid item sx={{ mt: 'auto', alignSelf: 'flex-end' }}>
                  <Button
                    onClick={() => {
                      testRefetch();
                    }}
                    variant="outlined"
                    startIcon={<Iconify icon="material-symbols:skip-next" />}
                  >
                    {nextLead ? 'Skip & Move to Next Lead' : 'No more leads'}
                  </Button>
                </Grid>
              )}
            </Grid>

            {openSequence && activeContact && (
              <Grid item xs={12} sm={6}>
                <AutoSMSCompose
                  onCloseCompose={openCompose.onFalse}
                  handleCloseInteraction={handleCloseInteraction}
                  defaultRecipients={null}
                  activeLead={currentLead}
                  contact={activeContact}
                  activeLeadTemplate={activeLeadTemplate}
                  setNextSms={setNextSms}
                  sequenceId={sequenceId}
                  loadingTemplate={loadingTemplate}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

SequenceAutoSms.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SequenceAutoSms;
