import { gql } from '@apollo/client';

export const ADD_MANUAL_CONNECTIONS = gql`
  mutation AddManualConnection($input: ManualConnectionLogInput) {
    addManualConnection(input: $input) {
      _id
    }
  }
`;

