import { gql } from '@apollo/client';
import { LEAD } from '../fragments/lead';

export const GET_ALL_LEADS = gql`
  ${LEAD}
  query {
    getAllLeads {
      ...LeadFields
    }
  }
`;

export const GET_ALL_ACTIVE_LEADS = gql`
  ${LEAD}
  query {
    getAllActiveLeads {
      ...LeadFields
    }
  }
`;

export const GET_ACTIVE_LEADS = gql`
  ${LEAD}
  query GetActiveLeads($limit: Int!, $offset: Int!) {
    getActiveLeads(limit: $limit, offset: $offset) {
      leads {
        ...LeadFields
      }
      totalCount
    }
  }
`;

export const GET_ACTIVE_LEADS_BY_SEQUENCE = gql`
  ${LEAD}
  query GetActiveLeadsBySequence($limit: Int!, $offset: Int!, $sequenceId: String!) {
    getActiveLeadsBySequence(limit: $limit, offset: $offset, sequenceId: $sequenceId) {
      leads {
        ...LeadFields
      }
      totalCount
    }
  }
`;

export const GET_LEADS_BY_STEP = gql`
  query Query($step: Float!, $sequenceId: String!, $limit: Int, $offset: Int) {
    getLeadsByStep(step: $step, sequenceId: $sequenceId, limit: $limit, offset: $offset)
  }
`;

export const GET_ALL_ACTIVE_LEAD_SUMMARIES = gql`
  query GetAllActiveLeadSummaries($sequenceId: String!) {
    getAllActiveLeadSummaries(sequenceId: $sequenceId) {
      totalLeads
      step
    }
  }
`;

export const GET_LEADS_BY_CONTACT_OWNER_ID = gql`
  ${LEAD}
  query getLeadsByContactOwnerId($contactOwnerId: String!) {
    getLeadsByContactOwnerId(contactOwnerId: $contactOwnerId) {
      ...LeadFields
    }
  }
`;

export const GET_ACTIVE_LEADS_BY_CONTACT_OWNER_ID = gql`
  ${LEAD}
  query getActiveLeadsByContactOwnerId($contactOwnerId: String!) {
    getActiveLeadsByContactOwnerId(contactOwnerId: $contactOwnerId) {
      ...LeadFields
    }
  }
`;

export const GET_ALL_LEADS_REPORT = gql`
  query getAllLeadsReport($input: GetLeadReportInputByDate!) {
    getAllLeadsReport(input: $input) {
      _id
      user {
        firstName
        lastName
      }
      lead {
        _id
        simplifiedCompanyName
        firstName
        lastName
        companyPhoneNumber
        emailAddress
      }
      callDone
      talkDone
      voicemailDone
      emailSent
      emailPersonalized
      created_at
    }
  }
`;

export const GET_LEAD_CONTACTS = gql`
  query getLeadContacts($company: String!) {
    getLeadContacts(company: $company) {
      _id
      company
      firstName
      lastName
      emailAddress
      prospectPhoneNumber
      prospectDirectPhoneNumber
      prospectMobilePhoneNumber
      prospectHQPhoneNumber
      prospectLocation
      prospectDesignation
      prospectSimplifiedDesignation
      prospectSimplifiedLocation
      prospectLinkedinProfile
      prospectDescription
      timeZone
      reasonForOptOut
      status
    }
  }
`;

export const GET_ALL_CONTACTS = gql`
  query getAllContacts {
    getAllContacts {
      _id
      company
      firstName
      lastName
      emailAddress
      prospectPhoneNumber
      prospectLocation
      prospectDesignation
      prospectLinkedinProfile
      prospectDescription
      timeZone
      reasonForOptOut
      status
    }
  }
`;

export const DEFAULT_COMPANY_CONTACT = gql`
  query getCompanyDefaultContact($company: String!) {
    getCompanyDefaultContact(company: $company) {
      _id
      name
      role
      email
      phone
    }
  }
`;

export const GET_LEAD_OWNER = gql`
  query getLeadOwner($contactOwnerId: String!) {
    getLeadOwner(contactOwnerId: $contactOwnerId) {
      _id
      firstName
      lastName
      email
      role
    }
  }
`;

export const OPT_AGENT_OUT = gql`
  mutation optOutContact($reasonForOptOut: String!, $_id: String!) {
    optOutContact(reasonForOptOut: $reasonForOptOut, _id: $_id) {
      _id
      company
      firstName
      lastName
      emailAddress
      prospectPhoneNumber
      prospectLocation
      prospectDesignation
      prospectSimplifiedDesignation
      prospectSimplifiedLocation
      prospectLinkedinProfile
      prospectDescription
      timeZone
      reasonForOptOut
      status
    }
  }
`;

export const GET_CONTACT_BY_PHONE = gql`
  query getContactByPhoneNumber($phoneNumber: String!) {
    getContactByPhoneNumber(phoneNumber: $phoneNumber) {
      _id
      company
      firstName
      lastName
      emailAddress
      prospectPhoneNumber
      prospectLocation
      prospectDesignation
      prospectSimplifiedDesignation
      prospectSimplifiedLocation
      prospectLinkedinProfile
      prospectDescription
      timeZone
      reasonForOptOut
      status
    }
  }
`;

export const GET_BY_UUID = gql`
  query getContactByUuid($uuid: String!) {
    getContactByUuid(uuid: $uuid) {
      firstName
      lastName
      company
      prospectDesignation
      timeZone
    }
  }
`;

export const GET_LEAD_BY_NAME = gql`
  query getLeadByName($name: String!) {
    getLeadByName(name: $name) {
      jobSource
      jobCategory
      jobPostTitle
      jobType
      jobPostDate
      jobLocation
      jobPostUrl
      simplifiedCompanyName
      companyDomainName
      companyLinkedinProfile
      companyPhoneNumber
      companyLogo
      companyEmail
      simplifiedCompanyLocation
      aboutCompany
      minimumCompanySize
      maximumCompanySize
      simplifiedCompanyIndustry
      generatedCompanyDescription
      websiteTrafficAnalysis
      recommendation
      nearbyLocation1
      nearbyLocation2
      yearFounded
      type
      industry
      industryTags
      uxAnalysisLink
      contactOwner
      contactOwnerId
      step
      stage
      optOut
      optOutReason
      minimumAnnualRevenue
      maximumAnnualRevenue
      trafficMonth1
      trafficValue1
      trafficMonth2
      trafficValue2
      trafficMonth3
      trafficValue3
      trafficMonth4
      trafficValue4
      trafficMonth5
      trafficValue5
      trafficMonth6
      trafficValue6
      expertDocumentLink
      created_at
    }
  }
`;

export const GET_LEAD_TEMPLATE = gql`
  query GetLeadTemplate($input: LeadTemplateInput!) {
    getLeadTemplate(input: $input) {
      _id
      template {
        _id
        jobTitle
        title
        subject
        sms
        notes
        type
        organization {
          _id
        }
        user {
          _id
        }
        createdAt
      }
    }
  }
`;
