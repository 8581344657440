import { useMutation } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import { Alert, Chip, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CREATE_CORE_SEQUENCE } from '../../../graphql/mutations/core.sequence';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function CreateCoreSequenceDialog({ open, handleClose, allCoreSequence, setAllCoreSequence, refetch}) {
  const [createCoreSequence, { data: createSequenceData, loading, error }] = useMutation(CREATE_CORE_SEQUENCE);
  const { handleSubmit, register, reset } = useForm();
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState('');

  const handleAddTag = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      if (inputTag.trim()) {
        setTags((prev) => [...prev, inputTag.trim()]);
        setInputTag('');
      }
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prev) => prev.filter((tag) => tag !== tagToDelete));
  };

  const onSubmit = (data) => {
    createCoreSequence({
      variables: {
        input: { ...data, tag: tags.join(',') },
      },
    });
  };

  useEffect(() => {
    if (createSequenceData && createSequenceData.createCoreSequence) {
      const newlyAddedSequence = {
        ...createSequenceData.createCoreSequence,
        members: [
          {
            "_id": uuidv4(),
            "firstName": "Admin",
            "lastName": "User"
          }
        ],
      };
  
      setAllCoreSequence([newlyAddedSequence, ...allCoreSequence]);
  
      // Refetch the core sequence data
      refetch();
  
      reset(); 
      handleClose();
    }
  }, [createSequenceData, refetch,  reset]);
  

  const handleDialogClose = () => {
    reset();
    setTags([]);
    handleClose(); 
  };

  return (
    <div>
      <BootstrapDialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Create Core Sequence
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: { sm: '100%', md: '500px' } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {error && <Alert severity="error">{error?.message}</Alert>}
              
              <TextField
                name="title"
                label="Core Sequence Title"
                required
                {...register('title', { required: true })}
              />
              
              <TextField
                name="description"
                label="Description"
                multiline
                rows={4}
                {...register('description')}
              />
  
              <Box>
                <TextField
                  label="Add Tag"
                  value={inputTag}
                  onChange={(e) => setInputTag(e.target.value)}
                  onKeyPress={handleAddTag}
                  placeholder="e.g., SMS, Call Pooling, Email Pooling"
                />
                <Box sx={{ mt: 2 }}>
                  {tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                      sx={{ margin: '4px' }}
                    />
                  ))}
                </Box>
              </Box>
  
              <LoadingButton loading={loading} type="submit" variant="contained">
                Create Sequence
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

CreateCoreSequenceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  allCoreSequence: PropTypes.array.isRequired,
  setAllCoreSequence: PropTypes.func.isRequired,
};

export default CreateCoreSequenceDialog;
