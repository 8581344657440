import { Helmet } from 'react-helmet-async';
import io from 'socket.io-client';
// @mui
import { Call, CallEndOutlined, Contacts } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Breadcrumbs, Button, Card, CardActions, CardContent, Container, Grid, Link, Stack, Typography } from '@mui/material';
// components
// mock
import { useLazyQuery, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CallContext } from '../context/CallContext';
import { GET_CHAT_COMPLETION } from '../graphql/queries/log';
import { GET_ORGANIZATION } from '../graphql/queries/organization';
import useSpeechRecognition from '../hooks/useSpeechRecognition';
import { LABELS } from '../utils/constants';
import { formatPhoneNumber } from '../utils/helperFunctions';


// ---------------------------------------------------------------------- 

export default function CallPollingPage() {
    const connectionURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
    const { user } = useSelector((state) => state.auth);
    const [usersInChat, setUserInChat] = useState([]);
    const [messages, setMessages] = useState([]);
    const socketRef = useRef();
    const messagesEndRef = useRef(null)
    const logRef = useRef(null);
    const { data: organization } = useQuery(GET_ORGANIZATION);
    const [context, setContext] = useState("");
    const [completeChat, { data: chatCompletion }] = useLazyQuery(GET_CHAT_COMPLETION);
    const [recognize, setRecognize] = useState(false);
    const [chatCompletionResponses, setChatCompletionResposes] = useState([]);
    const { recognitionText, startStreaming, stopStreaming, resetTranscription } = useSpeechRecognition();
    const { client } = useContext(CallContext);
    const [callId, setCallId] = useState(null);
    const [legId, setLegId] = useState(null);
    const [from, setFrom] = useState(null);
    const [channelType, setChannelType] = useState(null);
    const [status, setStatus] = useState(null);
    const [polling, setPolling] = useState([]);
    const [isOnCall, setIsOnCall] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    // Answer the call.
    const handleAnswer = () => {
        if (!callId) return;
        client.answer(callId)
            .then(() => {
                console.log("Success answering call.");
                setStatus("answered");
            })
            .catch(error => {
                console.error("Error answering call: ", error);
            });
    }

    // Reject the call
    const handleRejectCall = () => {
        if (!callId) return;
        client.reject(callId)
            .then(() => {
                console.log("Success rejecting call.");
                setStatus("rejected");
                setCallId(null);
                setLegId(null);
                setIsOnCall(false);
            })
            .catch(error => {
                console.error("Error rejecting call: ", error);
                setCallId(null);
                setLegId(null);
                setIsOnCall(false);
                setStatus(null);
            });
    }

    const handleEndCall = () => {
        if (callId && client) {
            client.hangup(callId)
                .then(() => {
                    setCallId(null);
                    setLegId(null);
                    setStatus(null);
                    setIsOnCall(false);
                })
                .catch((error) => {
                    console.log("Error hanging up call: ", error);
                    enqueueSnackbar('Error hanging up call', { variant: 'error' });
                    handleRejectCall();
                });
        }
    }

    const timeoutId = useRef(null);

    const handleResetTranscription = () => {
        setChatCompletionResposes([])
        resetTranscription()
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const scrollToLogBottom = () => {
        logRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    client.on('callInvite', (_callId, from, channelType) => {
        if ((callId === null || callId === '') && !isOnCall) {
            setCallId(_callId);
            setFrom(from?.substring(1));
            setChannelType(channelType);
        }
    });

    client.on('callInviteCancel', (id, reason) => {
        console.log(`Call ${callId} has been cancelled, reason: ${reason.name}`);
        if (callId === id) {
            setCallId(null);
            setLegId(null);
            setStatus(null);
            // setIsOnCall(false);
        }

    });

    client.on("callHangup", (id, callQuality, reason) => {
        console.log(`Call ${id} has hung up, callQuality:${callQuality}, reason:${reason.name}`);
        if (callId === id) {
            setCallId(null);
            setLegId(null);
            setStatus(null);
            setIsOnCall(false);
        }
    });

    useEffect(() => {
        if (callId && !isOnCall) {
            setIsOnCall(true);
            handleAnswer();
        }
    }, [callId, isOnCall]);


    useEffect(() => {
        if (organization && organization.getOrganization) {
            setContext(organization.getOrganization.system)
        }
    }, [organization]);


    useEffect(() => {
        if (recognitionText && recognitionText !== '') {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            timeoutId.current = setTimeout(() => {
                completeChat({ variables: { input: { prompt: recognitionText, context } } })
                setChatCompletionResposes(prev => [...prev, {
                    prompt: recognitionText,
                    response: "Thinking..."
                }]);
            }, 1000);
        }
    }, [recognitionText, context]);


    useEffect(() => {
        if (chatCompletion?.completeChat && chatCompletionResponses.length > 0) {
            setChatCompletionResposes(prev => {
                const last = prev.length - 1;
                prev[last] = {
                    prompt: prev[last].prompt,
                    response: chatCompletion?.completeChat
                };
                return [...prev]
            });
        }

    }, [chatCompletion, chatCompletionResponses]);

    useEffect(() => {
        scrollToBottom()
    }, [chatCompletionResponses]);

    useEffect(() => {
        const newSocket = io(`${connectionURL}`);
        socketRef.current = newSocket;
        socketRef.current.emit("joinChat", user._id);
        socketRef.current.on("userCount", (users) => {
            setUserInChat(users);
        });

        socketRef.current.on("newMessage", (message) => {
            setMessages((prev) => [...prev, message]);
            scrollToLogBottom();
        });

        socketRef.current.on("polling", (poll) => {
            setPolling((prev) => [...prev, poll]);
        });

        return () => {
            // Cleanup function to remove socket event listeners
            socketRef.current.emit("leaveChat", user._id);
            socketRef.current.off("groupChat");
            socketRef.current.off("newMessage");
            socketRef.current.off("error");
            socketRef.current.off("polling");
            socketRef.current.disconnect();
        };
    }, [user.organizationId, user._id]);


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Call Polling
        </Typography>,
    ];

    const currentContact = useMemo(() => {
        return polling?.find(poll => poll.contact === from || poll.contact === formatPhoneNumber(from, "1"));
    }, [polling, from]);

    return (
        <>
            <Helmet>
                <title> Sequence: Cal Polling | Sales App </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="column" spacing={0} alignItems="flex-start">
                            <Typography variant="h3">{LABELS.CallPolling}</Typography>
                            <Typography variant="caption">{LABELS.CallPollingDescription}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction={"row"} spacing={2} justifyContent="space-between" alignItems="center">
                            <Contacts />
                            <Typography variant="h6"> {usersInChat} User(s) in poll</Typography>
                        </Stack>
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12}>
                        <Grid item xs={12} sm={12} md={4}>
                            <Stack direction={"column"} spacing={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, height: '70vh', maxHeight: '90vh', overflowY: 'auto' }}>
                                {messages.map((message, index) => (
                                    <Typography key={index} variant="body1">{message}</Typography>
                                ))}
                                <Box ref={logRef} sx={{ marginTop: 20 }} />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Card>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, height: '60vh', maxHeight: '80vh', overflowY: 'auto' }}>
                                    {callId && (
                                        <Stack sx={{ p: 3, mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }} elevation={2}>
                                            {currentContact?.contactInfo?
                                                <Typography variant="h6" noWrap>
                                                    {currentContact?.contactInfo}
                                                </Typography> :
                                                <Typography variant="h6" noWrap>
                                                    Contact not found
                                                </Typography>
                                            }
                                            <Typography variant="body2" noWrap>
                                                {`${from} on ${channelType}`}
                                            </Typography>
                                            <Stack direction='row' spacing={2}>
                                                {status !== 'answered' ? (
                                                    <>
                                                        <Button startIcon={<Call />} onClick={handleAnswer} variant="contained" color="success">
                                                            Answer
                                                        </Button>
                                                        <Button startIcon={<CallEndOutlined />} onClick={handleRejectCall} variant="contained" color="error">
                                                            Reject
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button onClick={handleEndCall} variant="contained" color="error">
                                                        End Call
                                                    </Button>
                                                )}
                                            </Stack>
                                        </Stack>
                                    )}
                                    {chatCompletionResponses?.length > 0 &&
                                        <Typography variant='h6'>Ai Says</Typography>}
                                    {chatCompletionResponses?.map((response, index) => (
                                        <Stack key={index} direction='column' alignItems='flex-start' justifyContent='flex-start' spacing={1}>
                                            <Typography variant="caption" color='inherit' sx={{ mb: 1, fontWeight: 'bold' }}>
                                                {`${response.prompt}`}
                                            </Typography>
                                            <Typography variant="body2" color='inherit' sx={{ mb: 1 }}>
                                                {`${response.response}`}
                                            </Typography>
                                        </Stack>
                                    ))}
                                    <div ref={messagesEndRef} />
                                </CardContent>
                                <CardActions>
                                    <Stack direction='row' spacing={2} justifyContent="flex-end" alignItems="flex-end" sx={{ flex: 1, py: 2 }}>
                                        <Button color='primary' variant='contained' onClick={() => {
                                            startStreaming();
                                            setRecognize(true);
                                        }} disabled={recognize}> Start Streaming </Button>
                                        <Button color='primary' variant='outlined' onClick={() => {
                                            stopStreaming();
                                            setRecognize(false);
                                        }} disabled={!recognize}> Stop Streaming </Button>
                                        <Button color='primary' variant='outlined' onClick={handleResetTranscription}> Reset Transcription </Button>
                                    </Stack>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}