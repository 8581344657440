import { useRef, useState, useEffect, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
// components
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

export default function ChatMessageInput({ onSendMessage, currentConversationId, smsLoading,smsData }) {
  const fileRef = useRef(null);

  const [message, setMessage] = useState('');

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleChange = useCallback((event) => {
    setMessage(event.target.value);
  }, []);

  useEffect(() => {
    if (smsData?.sendSms) {
      setMessage('');
    }
  },[smsData]);

  const handleSend = useCallback(
    (event) => {
      if (event.key === 'Enter' && !smsLoading) {
        if (message) {
          onSendMessage(message);
        }
      }
    },
    [message, onSendMessage,smsLoading]
  );

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSend}
        onChange={handleChange}
        placeholder="Type a message"
        disabled={!currentConversationId}
        startAdornment={
          <IconButton>
            <Iconify icon="eva:smiling-face-fill" />
          </IconButton>
        }
        endAdornment={
          <Stack direction="row" sx={{ flexShrink: 0 }}>
            <IconButton onClick={handleAttach}>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>
            <IconButton onClick={handleAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton>
            <IconButton>
              {smsLoading ? <CircularProgress size="24px" /> : <Iconify icon="solar:microphone-bold" />}
            </IconButton>
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </>
  );
}

ChatMessageInput.propTypes = {
  onSendMessage: PropTypes.func.isRequired,
  currentConversationId: PropTypes.string.isRequired,
  smsLoading: PropTypes.bool,
  smsData: PropTypes.object,
};
