import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Box, FormControl, NativeSelect, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
// components
import { useLazyQuery, useMutation } from '@apollo/client';
import Iconify from '../../components/iconify';
import { SEND_SMS } from '../../graphql/mutations/sms';

// hooks
import { GET_ALL_PITCH } from '../../graphql/queries/pitch';
import { useBoolean } from '../../hooks/use-boolean';
import { useResponsive } from '../../hooks/use-responsive';


// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 24;


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '7px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));


export default function SMSCompose({ onCloseCompose, defaultRecipient, handleCloseInteraction, activeLead, contact }) {
  const smUp = useResponsive('up', 'sm');

  const [pitches, setPitches] = useState([]);

  const [getPitches, { data }] = useLazyQuery(GET_ALL_PITCH);

  const [sendSMS, { data: smsData,error, loading: smsLoading }] = useMutation(SEND_SMS);

  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState('');

  const [selectedPitch, setSelectedPitch] = useState('');

  const [phone, setPhone] = useState('');

  const formatPhoneNumber = (phoneNumber) => `1${phoneNumber.replace(/[^0-9]/g, '')}`;

  const replaceTagsInPitchFromContactAndActiveLead = useCallback((pitch) => {
    const combined = { ...contact, ...activeLead };
    return pitch.replace(/{{(.*?)}}/g, (match, p1) => combined[p1] || match);
  }, [activeLead, contact]);

  const fullScreen = useBoolean();

  const handleChangePhone = useCallback((event) => {
    setPhone(event.target.value);
  }, []);

  const handleChange = useCallback((event) => {
    setSelectedPitch(event.target.value);
    const pitch = pitches.find((p) => p._id === event.target.value);
    if (pitch) {
      const fullMessage = pitch.sms;
      if (fullMessage) {
        setMessage(replaceTagsInPitchFromContactAndActiveLead(fullMessage));
      } else {
        enqueueSnackbar('SMS not available for the selected pitch', { variant: 'error' });
      }
    }
  }, [pitches, replaceTagsInPitchFromContactAndActiveLead, enqueueSnackbar]);

  useEffect(() => {
    if (smsData?.sendSms) {
      enqueueSnackbar('SMS sent successfully', { variant: 'success' });
      handleCloseInteraction({
        smsDone: 'No',
        messageId: smsData.sendSms,
        content: message,
      });
      onCloseCompose();
    }
    if(error && !smsData?.sendSms ){
      console.log({error})
      enqueueSnackbar('Error while sending sms', { variant:"error"});
    }
  }, [smsData, enqueueSnackbar, onCloseCompose, handleCloseInteraction, message, error]);

  useEffect(() => {
    if (defaultRecipient) {
      setPhone(formatPhoneNumber(defaultRecipient.phone));
    }
  }, [defaultRecipient]);

  useEffect(() => {
    if (defaultRecipient) {
      getPitches();
    }
  }, [defaultRecipient, getPitches]);


  useEffect(() => {
    if (data?.getPitch) {
      setPitches(data?.getPitch);
    }
  }, [data?.getPitch]);

  const onSubmit = useCallback(() => {
    if (phone && message) {
      const data = {
        to: phone,
        text: message,
      };

      sendSMS({ variables: { input: data } });
    } else {

      enqueueSnackbar('Please fill in all fields', { variant: 'error' });
    }

  }, [message, sendSMS, enqueueSnackbar]);


  return (
    <>

      <Portal>
        {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

        <Paper
          sx={{
            right: 0,
            bottom: 0,
            borderRadius: 2,
            display: 'flex',
            position: 'fixed',
            zIndex: ZINDEX + 1,
            m: `${POSITION}px`,
            overflow: 'hidden',
            flexDirection: 'column',
            width: 550,
            boxShadow: (theme) => theme.customShadows.dropdown,
            ...(fullScreen.value && {
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
            }),
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              bgcolor: 'background.neutral',
              p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              New SMS
            </Typography>

            <IconButton onClick={fullScreen.onToggle}>
              <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
            </IconButton>

            <IconButton onClick={onCloseCompose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Stack>

          <InputBase
            placeholder="Phone number"
            value={phone}
            onChange={handleChangePhone}
            sx={{
              px: 2,
              height: 48,
              borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          />
          <Stack direction="row" alignItems="center" justifyContent='space-between' sx={{ p: 2 }}>
            <Typography>Select Pitch/Assets</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box sx={{ maxWidth: 500 }}>
                <FormControl variant="standard">
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={selectedPitch}
                    onChange={handleChange}
                    input={<BootstrapInput />}
                    size='small'
                  >
                    <option aria-label="None" value="" >
                      Select Pitch
                    </option>
                    {pitches.map((pitch) => (
                      <option key={pitch._id} value={pitch._id}>
                        {pitch.title}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Box>
            </Stack>
          </Stack>

          <TextField multiline placeholder='Type in your message...' rows={5} value={message} onChange={(e) => setMessage(e.target.value)} sx={{ p: 2 }} />

          <Stack spacing={2} direction='column' flexGrow={1} sx={{ p: 2, maxWidth: !fullScreen.value ? 550 : '100%' }}>

            <Stack direction="row" alignItems="center">
              <Stack direction="row" alignItems="center" flexGrow={1} />

              <LoadingButton
                variant="contained"
                color="primary"
                loading={smsLoading}
                onClick={onSubmit}
                endIcon={<Iconify icon="iconamoon:send-fill" />}
              >
                Send
              </LoadingButton>
            </Stack>

          </Stack>
          {/* </Box> */}
        </Paper>
      </Portal>
    </>
  );
}

SMSCompose.propTypes = {
  onCloseCompose: PropTypes.func.isRequired,
  defaultRecipient: PropTypes.object || null,
  handleCloseInteraction: PropTypes.func,
  activeLead: PropTypes.object,
  contact: PropTypes.object,
};
