import { gql } from '@apollo/client';

export const CREATE_TRIGGER = gql`
  mutation CreateTrigger($input: TriggerInput!) {
    createTrigger(input: $input) {
      _id
      user {
        _id
      }
      triggerName
      organization {
        _id
      }
      conditions {
        fieldName
        operator
        value
        action
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_TRIGGER = gql`
  mutation UpdateTrigger($triggerId: String!, $input: TriggerInput!) {
    updateTrigger(triggerId: $triggerId, input: $input) {
     _id
      user {
        _id
      }
      triggerName
      organization {
        _id
      }
      conditions {
        fieldName
        operator
        value
        action
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_TRIGGER = gql`
  mutation DeleteTrigger($triggerId: String!) {
    deleteTrigger(triggerId: $triggerId)
  }
`;