import { Helmet } from 'react-helmet-async';
// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
// components
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import EmailIcon from '@mui/icons-material/Email';
// sections
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { CallOutlined, EmailRounded, ExpandMoreOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AppointmentPrompt from '../components/calendar/AppointmentPrompt';
import LinkedinConnectPrompt from '../components/call-card/LinkedinConnectPrompt';
import LinkedinMessagePrompt from '../components/call-card/LinkedinMessagePrompt';
import { CREATE_COMPANY_DEFAULT_CONTACT } from '../graphql/mutations/lead';
import {
  GET_LEAD_CALL_LOGS,
  GET_LEAD_EMAIL_LOGS,
  LINKEDIN_CONNECT_LOG,
  LINKEDIN_MESSAGE_LOG,
  LOG_CALL,
  LOG_EMAIL,
  LOG_SMS,
} from '../graphql/mutations/log';
import { DEFAULT_COMPANY_CONTACT, GET_LEAD_CONTACTS, GET_LEAD_OWNER, GET_LEADS_BY_STEP } from '../graphql/queries/lead';
import { useBoolean } from '../hooks/use-boolean';
import { setActiveLead, setTargetLeads } from '../redux/service.slice';
import CompanyOptOutConfirmationDailog from '../sections/@dashboard/company/CompanyOptOutConfirmationDailog';
import CompanyDetails from '../sections/@dashboard/details/CompanyDetails';
import ContactDetails from '../sections/@dashboard/details/ContactDetails';
import Notes from '../sections/@dashboard/details/Notes';
import CallInitiate from '../sections/call/call-initiate';
import MailCompose from '../sections/mail/mail-compose';
import SMSCompose from '../sections/sms/sms-compose';
import { INTERRACTION_TYPES } from '../utils/constants';
import { fDateTime, fToNow } from '../utils/formatTime';
import { openLinkInNewTab } from '../utils/helperFunctions';
import { createParams, filePath, s3 } from '../utils/s3';
// ----------------------------------------------------------------------

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const PAGE_SIZE = 50;
const PRELOAD_THRESHOLD = 1;

export default function DetailsPage() {
  const navigate = useNavigate();
  const { slug, sequenceId, sequenceTitle, totalSteps } = useParams();
  const openCompose = useBoolean();
  const openPhone = useBoolean();
  const openSMS = useBoolean();
  const { leads, step, stage } = useSelector((state) => state.lead);
  const active = parseInt(useParams().active, 10);
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [activeLeadIndex, setActiveLeadIndex] = useState(1);
  const [isLastStep, setIsLastStep] = useState(false);
  const { data: leadsData, loading: leadsLoading, error, fetchMore } = useQuery(GET_LEADS_BY_STEP, {
    variables: { step, sequenceId, limit: PAGE_SIZE, offset },
    skip: !step || !sequenceId,  
    notifyOnNetworkStatusChange: true,
  });

  const [createCallLog, { loading: loadingCallLog, data: callLogData }] = useMutation(LOG_CALL);
  const [createSMSLog, { loading: loadingSmsLog, data: smsLogData }] = useMutation(LOG_SMS);
  const [getLeadContacts, { loading: loadingGettingContacts, data: contactsData }] = useLazyQuery(GET_LEAD_CONTACTS);
  const [getLeadEmailLogs, { loading: loadingGettingEmailLogs, data: emailLogs }] = useLazyQuery(GET_LEAD_EMAIL_LOGS);
  const [getLeadCallLogs, { loading: loadingGettingCallLogs, data: callLogs }] = useLazyQuery(GET_LEAD_CALL_LOGS);
  const [getCompanyDefaultContact, { loading: loadingDefaultContact, data: defaultContact }] =
    useLazyQuery(DEFAULT_COMPANY_CONTACT);
  const [findLeadOwer, { data: leadOwner }] = useLazyQuery(GET_LEAD_OWNER);
  const [createEmailLog, { loading: loadingEmailLog, data: emailLogData }] = useMutation(LOG_EMAIL);
  const [createLinkedInConnect, { loading: loadingLinkedInConnect, data: linkedInConnectData }] =
    useMutation(LINKEDIN_CONNECT_LOG);
  const [createLinkedinMessage, { loading: loadingLinkedInMessage, data: linkedInMessageData }] =
    useMutation(LINKEDIN_MESSAGE_LOG);
  const [createLeadDefaultContact, { loading: loadingCompanyDefaultContact, data: companyDefaultContact }] =
    useMutation(CREATE_COMPANY_DEFAULT_CONTACT);
  const [callLogsData, setCallLogsData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [emailLogsData, setEmailLogsData] = useState([]);
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [value, setValue] = useState(0);
  const [activeCompanyDefaultContact, setActiveCompanyDefaultContact] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const activeLead = useMemo(() => leads[active], [active, leads]);
  const isNextPossible = useMemo(() => active < leads.length - 1, [active, leads]);
  const isPrevPossible = useMemo(() => active > 0, [active]);
  const [open, setOpen] = useState(null);
  const [targetContact, setTargetContact] = useState(null);
  const [openOptOutDialog, setOpenOptOutDialog] = useState(false);
  const [openCompanyOptOutDialog, setOpenCompanyOptOutDialog] = useState(false);
  const [interactionTarget, setInteractionTarget] = useState({
    name: '',
    email: '',
    phone: '',
    linkedin: '',
  });
  const [activeContact, setActiveContact] = useState(null);
  const [interactionType, setInteractionType] = useState('');


  

  const uploadRecordingToStorage = async (file) => {

    const params = createParams(file);

    const command = new PutObjectCommand(params);

    try {

      const data = await s3.send(command);

      if (params.Key && data.$metadata.httpStatusCode === 200) {
        return filePath(params.Key)
      }

      return ''

    } catch (error) {
      enqueueSnackbar('Error uploading recording to server', { variant: 'error' });
      return ''
    }
  };
  const handleOpenCompanyOptOutDialog = () => {
    setOpenCompanyOptOutDialog(true);
  };

  const handleCloseCompanyOptOutDialog = () => {
    setOpenCompanyOptOutDialog(false);
  };

  const handleCloseOptOutDialog = () => {
    setOpenOptOutDialog(false);
    setTargetContact(null);
  };

  const handleOpenMenuOptOut = (contact) => {
    setTargetContact(contact);
    setOpenOptOutDialog(true);
  };

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate(`/sequence/sequence-overview/${sequenceId}/${sequenceTitle}/${totalSteps}`);
  };

  const handleToggleCompose = useCallback(() => {
    openCompose.onToggle();
  }, [openCompose]);

  const handleToggleCall = useCallback(() => {
    openPhone.onToggle();
  }, [openPhone]);

  const handleToggleSMS = useCallback(() => {
    openSMS.onToggle();
  }, [openSMS])

  const handleCreateOrUpdateDefaultContact = useCallback(
    (name, email = '', phone = '', role = 'N/A') => {
      createLeadDefaultContact({
        variables: {
          input: {
            company: activeLead._id,
            name,
            role,
            email,
            phone,
          },
        },
      });
    },
    [activeLead, createLeadDefaultContact]
  );

  const setActive = (target) => {
    if (target < leads.length && target >= 0) {
      navigate(`/sequence/leads/${slug}/${target}`);
    }
  };

  useEffect(() => {
    if (leadsData && leadsData.getLeadsByStep && active < 1) {
      dispatch(setTargetLeads(leadsData.getLeadsByStep));
      if (leadsData.getLeadsByStep.length < PAGE_SIZE) {
        setIsLastStep(true);
      }
    }
  }, [leadsData, dispatch]);

  useEffect(() => {
    if (callLogs?.getLeadCallLogs) {
      setCallLogsData(callLogs?.getLeadCallLogs);
    }
  }, [callLogs?.getLeadCallLogs]);

  useEffect(() => {
    if (emailLogs?.getLeadEmailLogs) {
      setEmailLogsData(emailLogs?.getLeadEmailLogs);
    }
  }, [emailLogs?.getLeadEmailLogs]);

  useEffect(() => {
    if (activeLead?.simplifiedCompanyName) {
      setOpenContactDetails(false);
      openCompose.onFalse();
      openPhone.onFalse();
      openSMS.onFalse();
      getLeadContacts({
        variables: {
          company: activeLead?.simplifiedCompanyName,
        },
      });
    }
  }, [activeLead?.simplifiedCompanyName, getLeadContacts, setOpenContactDetails]);

  useEffect(() => {
    if (activeLead) {
      getLeadEmailLogs({
        variables: {
          lead: activeLead._id,
        },
      });
    }
  }, [activeLead, getLeadEmailLogs]);

  useEffect(() => {
    if (activeLead) {
      getLeadCallLogs({
        variables: {
          lead: activeLead._id,
        },
      });
    }
  }, [activeLead, getLeadCallLogs]);

  useEffect(() => {
    if (activeLead) {
      findLeadOwer({
        variables: {
          contactOwnerId: activeLead?.contactOwnerId,
        },
      });
      getCompanyDefaultContact({
        variables: {
          company: activeLead._id,
        },
      });
    }
  }, [activeLead, getCompanyDefaultContact, active, findLeadOwer]);

  useEffect(() => {
    if (contactsData?.getLeadContacts) {
      setContacts(contactsData?.getLeadContacts);
    }
  }, [contactsData?.getLeadContacts]);

  const resetInteraction = useCallback(() => {
    setInteractionType('');
    setInteractionTarget({
      name: '',
      email: '',
      phone: '',
      linkedin: '',
    });
  }, []);

  useEffect(() => {
    if (callLogData?.createCallLog) {
      setCallLogsData((prev) => [callLogData?.createCallLog, ...prev]);
      enqueueSnackbar('Lead Report Created Successfully', { variant: 'success' });
      resetInteraction();
    }
  }, [callLogData?.createCallLog, enqueueSnackbar, resetInteraction]);

  useEffect(() => {
    if (emailLogData?.createEmailLog) {
      setEmailLogsData((prev) => [emailLogData?.createEmailLog, ...prev]);
      enqueueSnackbar('Lead Report Created Successfully', { variant: 'success' });
      resetInteraction();
    }

  }, [emailLogData?.createEmailLog, enqueueSnackbar, resetInteraction]);

  useEffect(() => {
    if (smsLogData?.createSMSLog) {
      enqueueSnackbar('Lead Report Created Successfully', { variant: 'success' });
      resetInteraction();
    }
  }, [smsLogData?.createSMSLog, enqueueSnackbar, resetInteraction]);

  useEffect(() => {
    if (linkedInConnectData?.createLinkedInConnect) {
      enqueueSnackbar('Lead Report Created Successfully', { variant: 'success' });
      resetInteraction();
    }
  }, [linkedInConnectData?.createLinkedInConnect, enqueueSnackbar, resetInteraction]);

  useEffect(() => {
    if (linkedInMessageData?.createLinkedinMessage) {
      enqueueSnackbar('Lead Report Created Successfully', { variant: 'success' });
      resetInteraction();
    }
  }, [linkedInMessageData?.createLinkedinMessage, enqueueSnackbar, resetInteraction]);

  useEffect(() => {
    if (defaultContact?.getCompanyDefaultContact) {
      setActiveCompanyDefaultContact(defaultContact?.getCompanyDefaultContact);
    } else {
      setActiveCompanyDefaultContact(null);
    }
  }, [defaultContact?.getCompanyDefaultContact]);

  useEffect(() => {
    if (companyDefaultContact?.createLeadDefaultContact) {
      setActiveCompanyDefaultContact(companyDefaultContact?.createLeadDefaultContact);
      enqueueSnackbar('Default Contact Updated!', { variant: 'success' });
    }
  }, [companyDefaultContact?.createLeadDefaultContact, enqueueSnackbar]);

  useEffect(() => {
    if (interactionType && interactionTarget?.name) {
      switch (interactionType) {
        case INTERRACTION_TYPES.CALL:
          handleToggleCall();
          break;
        case INTERRACTION_TYPES.EMAIL:
          handleToggleCompose();
          break;
        case INTERRACTION_TYPES.TEXT:
          handleToggleSMS();
          break;
        case INTERRACTION_TYPES.LINKEDINMESSAGE:
          openLinkInNewTab(interactionTarget?.linkedin);
          break;
        case INTERRACTION_TYPES.LINKEDINCONNECT:
          openLinkInNewTab(interactionTarget?.linkedin);
          break;
        default:
          break;
      }
    }
  }, [interactionType, interactionTarget]);

  useEffect(() => {
    if (interactionTarget?.phone) {
      const contact = contacts?.find((contact) => contact?.prospectPhoneNumber === interactionTarget?.phone);
      setActiveContact(contact);
    } else if (interactionTarget?.email) {
      const contact = contacts?.find((contact) => contact?.emailAddress === interactionTarget?.email);
      setActiveContact(contact);
    }
  }, [interactionTarget, contacts]);

  const lastContactedViaEmailOrPhone = useMemo(() => {
    if (!activeCompanyDefaultContact) {
      return null;
    }
    const lastContactedViaEmail =
      activeCompanyDefaultContact?.email &&
      emailLogsData?.find((emailLog) => emailLog?.email === activeCompanyDefaultContact?.email);

    const lastContactedViaPhone =
      activeCompanyDefaultContact?.phone &&
      callLogsData?.find((callLog) => callLog?.phoneNumber === activeCompanyDefaultContact?.phone);

    if (lastContactedViaEmail?.emailSent === 'Yes' && !lastContactedViaPhone) {
      return lastContactedViaEmail;
    }

    if (lastContactedViaPhone?.callDone === 'Yes' && !lastContactedViaEmail) {
      return lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaEmail?.createdAt > lastContactedViaPhone?.createdAt
        ? lastContactedViaEmail
        : lastContactedViaPhone;
    }

    if (lastContactedViaEmail?.emailSent === 'Yes' && lastContactedViaPhone?.callDone === 'No') {
      return lastContactedViaEmail;
    }

    if (lastContactedViaEmail?.emailSent === 'No' && lastContactedViaPhone?.callDone === 'Yes') {
      return lastContactedViaPhone;
    }

    return null;
  }, [callLogsData, emailLogsData, activeCompanyDefaultContact]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
      Overview
    </Link>,
    <Typography key="3" color="text.primary">
      {stage} : Step {step}
    </Typography>,
  ];

  const handleNextLead = (event) => {
    event.preventDefault();
    const remainingSteps = leads.length - (active + 1);

    if (remainingSteps <= PRELOAD_THRESHOLD) {
      if (!isLastStep) {
        fetchMore({
          variables: {
            offset: offset + PAGE_SIZE,
            step,
            limit: PAGE_SIZE,
          },
        })
          .then(({ data }) => {
            const newLeads = data.getLeadsByStep || [];
            const combinedLeads = [...leads, ...newLeads];
            dispatch(setTargetLeads(combinedLeads));
  
            if (newLeads.length < PAGE_SIZE) {
              setIsLastStep(true);
            }
            setOffset((prevOffset) => prevOffset + PAGE_SIZE);
          })
          .catch((err) => {
            console.error('Error fetching more leads:', err);
          });
      }
    }

    setActive(active + 1);
  };

  const handleViewAllCompanies = (event) => {
    event.preventDefault();
    dispatch(setActiveLead(active));
    navigate(`/sequence/leads/companies`);
  };

  const handleDefaultContactOptOut = () => {
    setOpen(null);
    const contact = contacts?.find(
      (contact) => contact?.prospectPhoneNumber === activeCompanyDefaultContact?.phone
    );
    if (contact) {
      handleOpenMenuOptOut(contact);
    }
  };

  const handleInitateInteraction = (details = null, type) => {
    setOpen(null);
    if (details) {
      setInteractionTarget(details);
    } else {
      const detailsOfTarget = contacts?.find(
        (contact) => contact?.prospectPhoneNumber === activeCompanyDefaultContact?.phone
      );
      if (detailsOfTarget) {
        setInteractionTarget({
          name: `${detailsOfTarget?.firstName} ${detailsOfTarget?.lastName}`,
          email: detailsOfTarget?.emailAddress,
          phone: detailsOfTarget?.prospectPhoneNumber,
          linkedin: detailsOfTarget?.prospectLinkedinProfile,
        });
      }
    }
    setInteractionType(type);
  };

  const handleCloseInteraction = useCallback(
    (data) => {
      openCompose.onFalse();
      openPhone.onFalse();
      openSMS.onFalse();
      if (Object.keys(data).length > 0) {
        switch (interactionType) {
          case INTERRACTION_TYPES.CALL: {
            const makeCall = async () => {
              let recordingUrl = '';
              if (data?.audioBlob) {
                recordingUrl = await uploadRecordingToStorage(data.audioBlob);
              }
              if (data.callDone === "Yes" && !activeCompanyDefaultContact?.name) {
                handleCreateOrUpdateDefaultContact(interactionTarget?.name, interactionTarget?.email, interactionTarget?.phone);
              }
              createCallLog({
                variables: {
                  input: {
                    lead: activeLead?._id,
                    step: `${step}`,
                    name: interactionTarget?.name,
                    phoneNumber: interactionTarget?.phone,
                    callDone: data.callDone,
                    talkDone: data.talkDone,
                    voicemailDone: data.voicemailDone,
                    recordingUrl
                  },
                },
              });
            }
            makeCall();
            break;
          }
          case INTERRACTION_TYPES.EMAIL: {
            const sendEmail = () => {
              if (data.emailSent === "Yes" && !activeCompanyDefaultContact?.name) {
                handleCreateOrUpdateDefaultContact(interactionTarget?.name, interactionTarget?.email, interactionTarget?.phone);
              }
              createEmailLog({
                variables: {
                  input: {
                    lead: activeLead?._id,
                    name: interactionTarget?.name,
                    step: `${step}`,
                    email: interactionTarget?.email,
                    emailSent: data.emailSent,
                    emailPersonalized: data.emailPersonalized,
                  },
                },
              });
            }
            sendEmail();
            break;
          }
          case INTERRACTION_TYPES.TEXT:
            createSMSLog({
              variables: {
                input: {
                  lead: activeLead?._id,
                  step: `${step}`,
                  name: interactionTarget?.name,
                  phoneNumber: interactionTarget?.phone,
                  smsDone: data.smsDone,
                  messageId: data.messageId,
                  content: data.content,
                },
              },
            });
            break;
          case INTERRACTION_TYPES.LINKEDINMESSAGE:
            createLinkedinMessage({
              variables: {
                input: {
                  lead: activeLead?._id,
                  step: `${step}`,
                  name: interactionTarget?.name,
                  userProfile: interactionTarget?.linkedin,
                  messageSent: data.messageSent,
                  messageCustomized: data.messageCustomized,
                },
              },
            });
            break;
          case INTERRACTION_TYPES.LINKEDINCONNECT:
            createLinkedInConnect({
              variables: {
                input: {
                  lead: activeLead?._id,
                  step: `${step}`,
                  name: interactionTarget?.name,
                  userProfile: interactionTarget?.linkedin,
                  connectRequestSent: data.connectRequestSent,
                },
              },
            });
            break;
          default:
            break;
        }
      } else {
        setInteractionType('');
        setInteractionTarget({
          name: '',
          email: '',
          phone: '',
          linkedin: '',
        });
      }
    },
    [
      interactionTarget,
      interactionType,
      activeLead,
      step,
      activeCompanyDefaultContact,
      handleCreateOrUpdateDefaultContact,
      createCallLog,
      createEmailLog,
      createSMSLog,
      createLinkedInConnect,
      createLinkedinMessage,
      uploadRecordingToStorage
    ]
  );

  const interractionContainer = useMemo(() => {
    if (loadingCallLog || loadingEmailLog || loadingSmsLog || loadingLinkedInConnect || loadingLinkedInMessage)
      return <CircularProgress />;
    switch (interactionType) {
      case INTERRACTION_TYPES.LINKEDINMESSAGE:
        return <LinkedinMessagePrompt onClose={handleCloseInteraction} interactionTarget={interactionTarget} />;
      case INTERRACTION_TYPES.LINKEDINCONNECT:
        return <LinkedinConnectPrompt onClose={handleCloseInteraction} interactionTarget={interactionTarget} />;
      case INTERRACTION_TYPES.APPOINTMENT:
        return <AppointmentPrompt onClose={handleCloseInteraction} interactionTarget={interactionTarget} activeLead={activeLead} />;
      default:
        return null;
    }
  }, [
    interactionTarget,
    interactionType,
    loadingCallLog,
    loadingEmailLog,
    loadingSmsLog,
    loadingLinkedInConnect,
    loadingLinkedInMessage,
    handleCloseInteraction,
    activeLead
  ]);

  return (
    <>
      <Helmet>
        <title> Sequence | Sales App </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
              <Stack direction="row" spacing={1}>
                <Button
                  size="small"
                  startIcon={<img src="/assets/icons/ic_calender.svg" width="20" alt="call" />}
                  onClick={handleViewAllCompanies}
                >
                  See all companies
                </Button>
                <Button
                  startIcon={<img src="/assets/icons/ic_optout_white.svg" alt="opt out" width="20" />}
                  color="error"
                  variant="contained"
                  size="small"
                  onClick={handleOpenCompanyOptOutDialog}
                >
                  {`Opt Out ${activeLead?.simplifiedCompanyName}`}
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<ArrowBackIcon />}
                  variant={isPrevPossible ? 'contained' : 'outlined'}
                  onClick={() => setActive(active - 1)}
                  disabled={!isPrevPossible}
                />
                <Stack direction="column">
                  <Typography variant="caption">Previous</Typography>
                  <Typography variant="h6">
                    {isPrevPossible ? `${leads[active - 1]?.simplifiedCompanyName}` : 'No Previous'}
                  </Typography>
                </Stack>
              </Stack>
              {leadsLoading &&  <CircularProgress />}
              <Stack direction="row" spacing={2}>
                <Stack direction="column" alignItems="flex-end">
                  <Typography variant="caption">Next</Typography>
                  <Typography variant="h6">
                    {isNextPossible ? `${leads[active + 1]?.simplifiedCompanyName}` : 'No Next'}
                  </Typography>
                </Stack>
                <Button
                  endIcon={<ArrowForwardIcon />}
                  variant={isNextPossible ? 'contained' : 'outlined'}
                  onClick={handleNextLead}
                  disabled={!isNextPossible}
                />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {interractionContainer}
          </Grid>

          {activeLead && (
            <>
              <Grid item xs={12} sm={12} md={8}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 100, height: 100 }} src={activeLead?.companyLogo} />
                  <Stack direction="column">
                    {activeLead?.simplifiedCompanyName && (
                      <Typography variant="h3">{activeLead?.simplifiedCompanyName}</Typography>
                    )}
                    <Typography variant="body1">Manage your flow for this reach campaigns</Typography>
                    {leadOwner?.getLeadOwner && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption">
                          {`Assigned to `}
                          <span style={{ color: '#3772FF' }}>
                            {` ${leadOwner?.getLeadOwner?.firstName} ${leadOwner?.getLeadOwner?.lastName}`}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Activities" {...a11yProps(1)} />
                  <Tab label="Resources" {...a11yProps(2)} />
                  <Tab label="Notes" {...a11yProps(3)} />
                </Tabs>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                {activeCompanyDefaultContact && !loadingDefaultContact && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      p: 1,
                      gap: 1,
                      borderRadius: 1,
                      border: '1px solid rgba(8, 7, 8, 0.20)',
                    }}
                  >
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                      <Stack direction="column">
                        <Typography variant="caption">{activeCompanyDefaultContact?.role === "Propspect" ? "Prospect" : activeCompanyDefaultContact?.role}</Typography>
                        <Link
                          underline="hover"
                          key="1"
                          color="inherit"
                          href="#"
                          onClick={() => {
                            setInteractionType('');
                            setInteractionTarget({
                              name: `${activeCompanyDefaultContact?.name}`,
                              email: activeCompanyDefaultContact?.email,
                              phone: activeCompanyDefaultContact?.phone,
                              linkedin: activeCompanyDefaultContact?.linkedin,
                            });
                            setOpenContactDetails(true);
                          }}
                        >
                          {activeCompanyDefaultContact?.name}
                        </Link>
                      </Stack>
                      <IconButton onClick={handleOpenMenu}>
                        <img src="/assets/icons/ic_options.svg" alt="edit" width="20" />
                      </IconButton>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                      {lastContactedViaEmailOrPhone ? (
                        <Typography variant="caption">
                          Last contacted <b>{lastContactedViaEmailOrPhone?.name}</b>{' '}
                          {fToNow(lastContactedViaEmailOrPhone?.createdAt)} via{' '}
                          {lastContactedViaEmailOrPhone?.email ? 'Email' : 'Phone'}
                        </Typography>
                      ) : (
                        <Typography variant="caption">Not contacted</Typography>
                      )}
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={1}>
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <img src="/assets/icons/ic_dafault_contact.svg" width="20" alt="call" />
                        <Typography variant="caption" color="primary">
                          Default Contact
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        {!activeCompanyDefaultContact?.phone ? (
                          <Tooltip title="Default Contact does not have a Phone number" arrow>
                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<img src="/assets/icons/ic_phone.svg" width="20" alt="call" />}
                            >
                              Call {activeCompanyDefaultContact?.name}
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            startIcon={<img src="/assets/icons/ic_phone.svg" width="20" alt="call" />}
                            onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.CALL)}
                          >
                            Call {activeCompanyDefaultContact?.name}
                          </Button>
                        )}
                        {!activeCompanyDefaultContact?.email ? (
                          <Tooltip title="Default Contact does not have an email" arrow>
                            <IconButton
                              variant="outlined"
                              size="small"
                              sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%', width: 50 }}
                            >
                              <img src="/assets/icons/ic_email.svg" width="30" alt="call" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <IconButton
                            variant="outlined"
                            size="small"
                            sx={{ border: '1px solid rgba(8, 7, 8, 0.20)', borderRadius: '20%', width: 50 }}
                            onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.EMAIL)}
                          >
                            <img src="/assets/icons/ic_email.svg" width="30" alt="call" />
                          </IconButton>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                )}
                {loadingDefaultContact && <CircularProgress />}
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Box>
                  <CustomTabPanel value={value} index={0}>
                    <CompanyDetails activeLead={activeLead} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <Stack direction="column" spacing={2}>
                      {loadingGettingCallLogs && <CircularProgress />}
                      {callLogsData?.map((log) => (
                        <Accordion key={log._id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                              <CallOutlined />
                              <Typography>
                                {log?.name} ({log?.phoneNumber})
                              </Typography>
                              <Typography>{`on ${fDateTime(log?.createdAt)}`}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="column"
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">Name: {log?.name}</Typography>
                              <Typography variant="body1">Phone Number: {log?.phoneNumber}</Typography>
                              <Typography variant="body1">Call Done: {log?.callDone}</Typography>
                              <Typography variant="body1">Talk Done: {log?.talkDone}</Typography>
                              <Typography variant="body1">Voicemail Done: {log?.voicemailDone}</Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                      {loadingGettingEmailLogs && <CircularProgress />}
                      {emailLogsData?.map((log) => (
                        <Accordion key={log._id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                              <EmailRounded />
                              <Typography>
                                {log?.name} ({log?.email})
                              </Typography>
                              <Typography>{`on ${fDateTime(log?.createdAt)}`}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="column"
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">Name: {log?.name}</Typography>
                              <Typography variant="body1">Email: {log?.email}</Typography>
                              <Typography variant="body1">Email Sent: {log?.emailSent}</Typography>
                              <Typography variant="body1">Email Personalized: {log?.emailPersonalized}</Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Stack>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Typography>Resources</Typography>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                    <Typography>Notes</Typography>
                  </CustomTabPanel>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Stack direction="column" spacing={2}>
                  <ContactDetails
                    activeLead={activeLead}
                    contacts={contacts}
                    loadingGettingContacts={loadingGettingContacts}
                    loadingCompanyDefaultContact={loadingCompanyDefaultContact}
                    callLogsData={callLogsData}
                    emailLogsData={emailLogsData}
                    handleCreateOrUpdateDefaultContact={handleCreateOrUpdateDefaultContact}
                    activeCompanyDefaultContact={activeCompanyDefaultContact}
                    setOpenContactDetails={setOpenContactDetails}
                    targetContact={targetContact}
                    openContactDetails={openContactDetails}
                    handleOpenMenuOptOut={handleOpenMenuOptOut}
                    openOptOutDialog={openOptOutDialog}
                    handleCloseOptOutDialog={handleCloseOptOutDialog}
                    setInteractionTarget={setInteractionTarget}
                    setInteractionType={setInteractionType}
                    interactionTarget={interactionTarget}
                  />
                  <Notes activeLead={activeLead} />
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.TEXT)}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_sms_icon.svg" alt="details" width="20" />
            <Typography variant="body2">SMS</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.LINKEDINCONNECT)}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_linkedin_request_icon.svg" alt="details" width="20" />
            <Typography variant="body2">Linkedin Request</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.LINKEDINMESSAGE)}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_linkedin_message.svg" alt="details" width="20" />
            <Typography variant="body2">Linkedin Message</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={() => handleInitateInteraction(null, INTERRACTION_TYPES.APPOINTMENT)}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_calender.svg" alt="details" width="20" />
            <Typography variant="body2">Book an Appointment</Typography>
          </Stack>
        </MenuItem>
        <MenuItem onClick={handleDefaultContactOptOut}>
          <Stack direction="row" spacing={1}>
            <img src="/assets/icons/ic_optout.svg" alt="opt out" width="20" />
            <Typography variant="body2">Opt out</Typography>
          </Stack>
        </MenuItem>
      </Popover>
      <CompanyOptOutConfirmationDailog
        open={openCompanyOptOutDialog}
        handleClose={handleCloseCompanyOptOutDialog}
        company={activeLead}
      />
      {openCompose.value && <MailCompose
        onCloseCompose={openCompose.onFalse}
        handleCloseInteraction={handleCloseInteraction}
        defaultRecipients={[interactionTarget?.email]}
        activeLead={activeLead}
        contact={activeContact}
      />}
      {openPhone.value && (
        <CallInitiate
          onCloseCompose={openPhone.onFalse}
          handleCloseInteraction={handleCloseInteraction}
          defaultRecipient={interactionTarget}
          activeLead={activeLead}
          contact={activeContact}
        />)}
      {openSMS.value && (
        <SMSCompose
          onCloseCompose={openSMS.onFalse}
          handleCloseInteraction={handleCloseInteraction}
          defaultRecipient={interactionTarget}
          activeLead={activeLead}
          contact={activeContact}
        />
      )}

    </>
  );
}
