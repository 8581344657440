import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_CONNECTED_CALLS_LOG } from '../../graphql/queries/call.analytics';

const useConnectedCallLogs = (dateInterval, limit, skip) => {
  const [getConnectedCallLogs, { data, loading, error }] = useLazyQuery(GET_CONNECTED_CALLS_LOG);

  useEffect(() => {
    if (dateInterval) {
      getConnectedCallLogs({ variables: { input: dateInterval, limit, skip } });
    }
  }, [dateInterval, limit, skip, getConnectedCallLogs]);
  return { data: data?.getConnectedCallsLog, loading, error, getConnectedCallLogs };
};

export default useConnectedCallLogs;
