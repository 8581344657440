import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Container, Divider, Box, Grid, Link, Stack, Tab, Tabs, Typography, Badge } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import CallPollingLogView from '../sections/call/logs/view';
import { CallView } from '../sections/call/view';
import RetryableCallsView from '../sections/call/retryable/retryable-calls';
import { LABELS } from '../utils/constants';

export default function CallPage() {
  const [value, setValue] = useState(0);
  const retryableCallsCount = 5; 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      Calls
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Dashboard: Call</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">{LABELS.Calls}</Typography>
                <Typography variant="caption">{LABELS.CallsDescription}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Call Polling Log" />
              <Tab label="Call View" />
             
                <Tab
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography sx={{fontWeight: 600}}>Retryable Calls</Typography>
                      {retryableCallsCount > 1 && (
                     <Badge
                     color="error"
                     badgeContent={retryableCallsCount}
                     invisible={retryableCallsCount <= 1}
                     sx={{
                       ml: 2,
                       '& .MuiBadge-badge': {
                         fontSize: '0.6rem',
                         minWidth: '18px',
                         height: '18px',
                         padding: '0 4px',
                       },
                     }}
                   />
                   
                    )}
                    </Box>
                  }
                />
            </Tabs>
            <Divider sx={{ mb: 2 }} />
            {value === 0 && <CallPollingLogView />}
            {value === 1 && <CallView />}
            {value === 2 && <RetryableCallsView />}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
