import React from 'react';
import { calculatePxToPercentage } from '../../../utils/cssHelper';

const ZwiltAppIcon = () => {
    return (
        <svg
            width={calculatePxToPercentage(60)}
            height={calculatePxToPercentage(60)}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="60" height="60" rx="30" fill="white" />
            <rect
                width="60"
                height="60"
                rx="30"
                fill="#FFBE2E"
                fillOpacity="0.1"
            />
            <path
                d="M31.138 30.3729C31.138 35.383 27.0733 39.4445 22.0593 39.4445C17.0452 39.4445 12.9805 35.383 12.9805 30.3729C12.9805 25.3627 17.0452 21.3012 22.0593 21.3012C27.0733 21.3012 31.138 25.3627 31.138 30.3729Z"
                fill="#FFBE2E"
            />
            <path
                d="M28.8035 40.9996V19.7461L46.9611 30.3729L28.8035 40.9996Z"
                fill="#FFBE2E"
            />
        </svg>
    );
};

export default ZwiltAppIcon;