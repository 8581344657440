import {
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
// components
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';
// sections
import { useCallback, useEffect, useState } from 'react';
import { fNumber } from '../../../utils/formatNumber';
import SequenceCard from './SequenceCard';

function SequenceOverview({ mySequence, groupedLeads, leadsCount, selectedAgent, handleOpenSequenceAutoCaller, handleOpenSequenceAutoMailer, handleOpenSequenceAutoSms,  totalSteps }) {
  const [expandedItems, setExpandedItems] = useState({});

  
  const getSequenceTotalLeadsCount = useCallback((sequence, stage) => {
    let count = 0;
    sequence[stage].forEach((step) => {
      count += groupedLeads?.[step.step] || 0;
    });
    return count;
  }, [groupedLeads]);
  
  const getSequenceTotalLeadsCountForCall = useCallback((sequence, stage) => {
    let count = 0;
    sequence[stage].forEach((step) => {
      if (step.type === 'CALL') {
        count += groupedLeads?.[step.step] || 0;
      }
    });
    return count;
  }, [groupedLeads]);
  
  const getSequenceTotalLeadsCountForEmail = useCallback((sequence, stage) => {
    let count = 0;
    sequence[stage].forEach((step) => {
      if (step.type === 'EMAIL') {
        count += groupedLeads?.[step.step] || 0;
      }
    });
    return count;
  }, [groupedLeads]);
  

  useEffect(() => {
    // Find the index of the stage with the maximum leads count
    let max = 0;
    let index = 0;
    Object.keys(mySequence).forEach((stage, i) => {
      const totalLeadsCount = getSequenceTotalLeadsCount(mySequence, stage);
      if (totalLeadsCount > max) {
        max = totalLeadsCount;
        index = i;
      }
    });

    // Set all accordion items to collapsed initially, except the one with max leads count
    const initialExpandedItems = Object.fromEntries(Object.keys(mySequence).map((stage, i) => [i, i === index]));
    setExpandedItems(initialExpandedItems);
  }, [mySequence, getSequenceTotalLeadsCount]);

  const handleAccordionChange = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Paper
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              padding: 5,
              alignItems: 'center',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                  <Typography variant="caption">Total Steps</Typography>
                  <Typography variant="h5">{totalSteps || 0}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                  <Typography variant="caption">Remaining Leads</Typography>
                  <Typography variant="h5">{fNumber(leadsCount || 0)}</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                  <Typography variant="caption">Estimated Remaining Time</Typography>
                  <Typography variant="h5">0h, 0min</Typography>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                  <Typography variant="caption">Next Step</Typography>
                  <Typography variant="h5">Email Task for this day</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Card sx={{ width: '100%' }}>
            <CardContent sx={{ padding: 0 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                sx={{ p: 2, backgroundColor: 'rgba(8, 7, 8, 0.03)' }}
              >
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Typography variant="h5">List of days</Typography>
                  <Chip label={`Total  ${Object.keys(mySequence).length}`} size="small" sx={{ backgroundColor: '#080708', color: '#FFF', padding: 2 }} />
                </Stack>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Typography variant="body1">Total Leads</Typography>
                  <Typography variant="body1">Completed</Typography>
                  <Typography variant="body1">Remaining</Typography>
                </Stack>
              </Stack>
              <Divider />
              {/* {mySequence.} */}
              {Object.keys(expandedItems).length > 0 && Object.keys(mySequence).map((stage, index) => (
                <Grid item xs={12} sm={12} md={12} key={stage}>
                  <Accordion expanded={expandedItems[index]} onChange={() => handleAccordionChange(index)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        borderBottom: Object.keys(mySequence).length - 1 !== index ? '1px solid #08070833' : 'none',
                      }}
                    >
                      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Stack direction="column" spacing={1}>
                          <Typography variant="h5">Upcoming Tasks - {stage}</Typography>
                          <Typography variant="caption">{`${getSequenceTotalLeadsCountForCall(mySequence, stage)} Calls and ${getSequenceTotalLeadsCountForEmail(mySequence, stage)} Emails due.`}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={10} sx={{ pr: 5 }}>
                          <Typography variant="body1">{getSequenceTotalLeadsCount(mySequence, stage)}</Typography>
                          <Typography variant="body1">0</Typography>
                          <Typography variant="body1">0</Typography>
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      {mySequence[stage].map((step, index) => (
                       <SequenceCard 
                         step={step}
                         groupedLeads={groupedLeads}
                         key={index}
                         selectedAgent={selectedAgent}
                          handleOpenSequenceAutoCaller={handleOpenSequenceAutoCaller}
                          handleOpenSequenceAutoMailer={handleOpenSequenceAutoMailer}
                          handleOpenSequenceAutoSms={handleOpenSequenceAutoSms}
                        />
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

SequenceOverview.propTypes = {
  mySequence: PropTypes.object,
  groupedLeads: PropTypes.object,
  handleOpenSequenceAutoCaller: PropTypes.func,
  handleOpenSequenceAutoMailer: PropTypes.func,
  leadsCount: PropTypes.number,
  selectedAgent: PropTypes.string,
};

export default SequenceOverview;

