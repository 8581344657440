import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Box, styled } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { getRecrowdlyAppDomain, getTrackerApp, getZwiltSettingApp, getZwiltStoreDomain, MAIL_URL } from '../../../config/getDomain';
import TrackerAppIcon from '../../../components/svg-icons/app-header/TrackerAppIcon';
import PunchAppIcon from '../../../components/svg-icons/app-header/PunchAppIcon';
import RecrowdlyAppIcon from '../../../components/svg-icons/app-header/RecrowdlyAppIcon';
import MailAppIcon from '../../../components/svg-icons/app-header/MailAppIcon';
import SettingsAppIcon from '../../../components/svg-icons/app-header/SettingAppIcon';
import ZwiltAppIcon from '../../../components/svg-icons/app-header/ZwiltAppIcon';
import { calculatePxToPercentage } from '../../../utils/cssHelper';


const AppBoxPopover = ({
    id,
    open,
    anchorEl,
    handleClose,
}) => {

   const handleClick = (url) => {
       window.open(url, '_blank');
       handleClose();
   };
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            TransitionComponent={Zoom}
            transitionDuration={0}
            PaperProps={{
                style: {
                    width: calculatePxToPercentage(300),
                    height: 'fit-content',
                    marginTop: calculatePxToPercentage(15),
                    marginLeft: calculatePxToPercentage(-30),
                    borderRadius: calculatePxToPercentage(20),
                    boxShadow: '0px 4px 10px rgba(80, 88, 159, 0.2)',
                },
            }}
            disableScrollLock
        >
            <Wrapper>
                <Column className="parent">
                <FlexRow>
                        <Column
                            className="child"
                            onClick={() => {
                                handleClick(getZwiltStoreDomain());
                            }}
                        >
                            <ZwiltAppIcon />
                            <CustomText className="heading-text">
                                Zwilt Store
                            </CustomText>
                        </Column>
                        <Column
                            className="child"
                            onClick={() => {
                                handleClick(getTrackerApp());
                            }}
                        >
                            <TrackerAppIcon />
                            <CustomText className="heading-text">
                                Tracker
                            </CustomText>
                        </Column>
                    </FlexRow>
                    <FlexRow>
                        <Column
                            className="child"
                            onClick={() => handleClick(getRecrowdlyAppDomain())}
                        >
                            <RecrowdlyAppIcon />
                            <CustomText className="heading-text">
                                Recrowdly
                            </CustomText>
                        </Column>
                        <Column
                            className="child"
                            onClick={() => handleClick(MAIL_URL)}
                        >
                            <MailAppIcon />
                            <CustomText className="heading-text">
                                Mail
                            </CustomText>
                        </Column>
                    </FlexRow>
                    <FlexRow>
                        <Column
                            className="child"
                            onClick={() => {
                                handleClick(getZwiltSettingApp());
                            }}
                        >
                            <SettingsAppIcon />
                            <CustomText className="heading-text">
                                Settings
                            </CustomText>
                        </Column>
                        <Column
                            className="child"
                            onClick={() =>
                                handleClick('https://www.punch.cool')
                            }
                        >
                            <PunchAppIcon />
                            <CustomText className="heading-text">
                                Punch
                            </CustomText>
                        </Column>
                    </FlexRow>
                </Column>
            </Wrapper>
        </Popover>
    );
};

export default AppBoxPopover;

const Wrapper = styled(Box)(({ theme }) => ({
    padding: calculatePxToPercentage(20),
}));
const CustomText = styled(Typography)(({ theme }) => ({
    '&.heading-text': {
        color: '#282833',
        textAlign: 'center',
        fontSize: calculatePxToPercentage(16),
        fontWeight: 500,
        lineHeight: calculatePxToPercentage(19.2),
    },
    '&.sort-text': {
        color: '#282833B2',
        textAlign: 'left',
        fontSize: calculatePxToPercentage(16),
        fontWeight: 500,
        lineHeight: calculatePxToPercentage(20.8),
    },
}));

const Column = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&.parent': {
        gap: calculatePxToPercentage(10),
        justifyContent: 'center',
    },
    '&.child': {
        justifyContent: 'center',
        alignItems: 'center',
        gap: calculatePxToPercentage(10),
        borderRadius: calculatePxToPercentage(20),
        width: calculatePxToPercentage(120),
        height: calculatePxToPercentage(120),
        transition: 'background-color 0.3s ease',
        '&:hover': {
            background: '#F4F4FA',
        },
    },
}));

const FlexRow = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}));