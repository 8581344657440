import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../components/breadcrumbs/HeaderBreadcrumbs';
import SequencePromptForm from '../sections/@dashboard/prompts/SequencePromptForm';

export default function CreateSequencePromptPage() {
  const { sequenceId, sequenceTitle, totalSteps } = useParams();
  const location = useLocation();
  const { isEdit, currentPrompt } = location.state || {}; 
  const title = `${isEdit ? "Update" : "Create"}`;
  return (
    <>
      <Helmet>
        <title>{title} Prompt | Sales App </title>
      </Helmet>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={`${title} Prompt`}
          links={[{ name: 'Prompt List', href: `/sequence/prompt/${sequenceId}/${sequenceTitle}/${totalSteps}` }, { name: `${title} Prompt`, href: '#' }]}
        />

        <SequencePromptForm isEdit={isEdit} currentPrompt={currentPrompt} sequenceId={sequenceId} />
      </Container>
    </>
  );
}
