import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { RHFTextField } from '../../../../components/hook-form';

export default function TriggerFormHeader() {
  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ p: 3, bgcolor: 'background.neutral' }}>
      <RHFTextField fullWidth name="triggerName" label="Trigger Name" />
    </Stack>
  );
}
