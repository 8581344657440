import { gql } from '@apollo/client';

export const GET_ALL_SMS = gql`
  query {
    getAllSms {
      _id
      smsDone
      name
      step
      phoneNumber
      messageId
      content
      status
      createdAt
      user {
        firstName
        lastName
        email
      }
    }
  }
`;


export const GET_ALL_SMS_BY_CONVERSATION = gql`
  query GetAllSmsByConversation($id: String!) {
    getAllSmsByConversation(id: $id) {
      _id
      user {
        firstName
        lastName
        email
      }
      conversation {
        _id
      }
      name
      phoneNumber
      messageId
      senderRole
      content
      status
      createdAt
    }
  }
`;

export const GET_SMS_CONVERSATION = gql`
  query {
    getSmsConversation {
      _id
      user {
        firstName
        lastName
        email
      }
      to
      name
      lastSms
      from
      createdAt
      updatedAt
    }
  }
`;
