import {
  Grid,
  Typography,
  CircularProgress,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ARCHIVED_CORE_SEQUENCE } from '../graphql/queries/core.sequence';
import ArchivedCoreSequenceOverview from '../sections/@dashboard/core-sequence/ArchivedCoreSequenceOverview';

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
});

const ErrorMessage = styled('div')({
  color: 'red',
  textAlign: 'center',
  margin: '20px 0',
});

const ArchivedCoreSequencePage = () => {
  const { data: archivedSeqData, loading: archiveSeqLoading, error: archSeqErr, refetch } = useQuery(GET_ARCHIVED_CORE_SEQUENCE);
  const [allArchivedSequence, setAllArchivedCoreSequence] = useState([]);

  useEffect(() => {
    if (!archiveSeqLoading && !archSeqErr) {
      const newSequence = archivedSeqData?.getArchivedCoreSequence || [];
      setAllArchivedCoreSequence(newSequence);
    }
  }, [archivedSeqData, archiveSeqLoading, archSeqErr]);

  if (archiveSeqLoading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  if (archSeqErr) {
    return (
      <ErrorMessage>
        <Typography variant="h6">Error loading archived sequences: {archSeqErr.message}</Typography>
      </ErrorMessage>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Archived Core Sequences
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Here you can view all your archived core sequences. You can restore or manage them as needed.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ArchivedCoreSequenceOverview 
          allArchivedSequence={allArchivedSequence} 
          refetch={refetch} 
        />
      </Grid>
    </Grid>
  );
};

export default ArchivedCoreSequencePage;
