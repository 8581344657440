import { gql } from '@apollo/client';

export const GET_TRIGGERS = gql`
  query GetTriggers($skip: Float!, $limit: Float!, $search: String) {
    getTriggers(skip: $skip, limit: $limit, search: $search) {
      _id
      user {
        _id
      }
      organization {
        _id
      }
      triggerName
      conditions {
        fieldName
        operator
        value
        action
      }
      createdAt
      updatedAt
    }
  }
`;
