import 'moment-timezone';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// components
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
//
import store, { persistor } from './redux/store';
// theme
import { apolloClient } from './graphql/client';
import ThemeProvider from './theme';
// routes
import { CallProvider } from './context/CallContext';
import Router from './routes';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={apolloClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SnackbarProvider>
                      <ScrollToTop />
                      <StyledChart />
                      <Router />
                  </SnackbarProvider>
                </LocalizationProvider>
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
