import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import HeaderBreadcrumbs from '../components/breadcrumbs/HeaderBreadcrumbs';
import CreateTriggers from '../sections/@dashboard/triggers/CreateTriggers';

export default function CreateTriggersPage() {
  const location = useLocation();
  const { isEdit, currentTrigger } = location.state || {}; 
  const title = `${isEdit ? "Update" : "Create"}`;
  return (
    <>
      <Helmet>
        <title>{title} Triggers | Sales App </title>
      </Helmet>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={`${title} Trigger`}
          links={[{ name: 'Triggers List', href: '/sequence/triggers' }, { name: `${title} Triggers`, href: '#' }]}
        />

        <CreateTriggers isEdit={isEdit} currentTrigger={currentTrigger} />
      </Container>
    </>
  );
}
