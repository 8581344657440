import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../services/socket';
import useLogout from './use-logout';

const useCrossAppLogoutListener = () => {
    const { user } = useSelector((state) => state.auth);
    const handleLogout = useLogout(socket, user);
    
    const lastLoggedOutUserId = useRef(null);

    useEffect(() => {
        const handleUserLoggedOut = (data) => {
            console.log("USER___", { punchId: user?.punchId, userId: data.userId });
            if (user?.punchId === data.userId && lastLoggedOutUserId.current !== data.userId) {
                lastLoggedOutUserId.current = data.userId;
                handleLogout();
            }
        };
    
        socket.once('userLoggedOut', handleUserLoggedOut);
    
        return () => {
            lastLoggedOutUserId.current = null;
        };
    }, [user]);

    return null;
};

export default useCrossAppLogoutListener;
