import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Device } from '@twilio/voice-sdk';
import { twilioApiUrl } from '../config/apiConfig';
import { setActiveLead } from '../redux/twilio.slice';

const useTwilio = (agentId, lead, socket) => {
  const [callStatus, setCallStatus] = useState('');
  const [incomingCall, setIncomingCall] = useState(null);
  const [acceptedCall, setAcceptedCall] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false); 
  const callingToken = useRef(null);
  const device = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post(`${twilioApiUrl}/token`, { identity: agentId });
        callingToken.current = response.data.token;
        
        initializeDevice(); // Initialize Twilio Device after fetching token
      } catch (error) {
        console.error('Error fetching token:', error);
        setCallStatus('Error fetching token');
      }
    };

    const initializeDevice = () => {
      device.current = new Device(callingToken.current, {
        codecPreferences: ['opus', 'pcmu'],
        // 'tokenWillExpire' event will be emitted 30 seconds before the AccessToken expires
        tokenRefreshMs: 30000
      });

      device.current.on('ready', () => {
        console.log('Device is ready');
        setCallStatus('Device is ready');
        setIsRegistered(true);
      });

      device.current.on('error', (error) => {
        console.error('Device error:', error);
        setCallStatus(`Device error: ${error.message}`);
      });

      // device.current.on('incoming', (call) => {
      //   console.log('Incoming call from:', call.parameters.From);
      //   setIncomingCall(call);
      //   setCallStatus('Incoming call');

      //   // Automatically accept the call
      //   call.accept();
      //   console.log('Call automatically accepted');
      //   setCallStatus('Call accepted');
      //   setAcceptedCall(call);
      //   setIncomingCall(null);

      //   // Listen for disconnect event on the accepted call
      //   call.on('disconnect', () => {
      //     console.log('Call disconnected');
      //     setCallStatus('Call ended');
      //     setAcceptedCall(null);
      //     dispatch(setActiveLead(null));
      //   });

      //   call.on('cancel', () => {
      //     console.log('Call canceled');
      //     setCallStatus('Call ended');
      //     setIncomingCall(null);
      //     dispatch(setActiveLead(null));
      //   });
      // });

      device.current.on('incoming', (call) => {
        console.log('Incoming call from:', call.parameters.From);
        setIncomingCall(call);
        setCallStatus('Incoming call');
        // Automatically accept the call start
        const callSid_ = call.parameters.CallSid;
        call.accept();
        socket.current.emit('callAccepted', { callSid: callSid_, agentId, leadId: lead?._id });
        // Automatically accept the call end
        
        // Add event listeners for the call
        call.on('accept', () => {
          console.log('Call accepted');
          setCallStatus('Call accepted');
          setAcceptedCall(call);
          setIncomingCall(null);

          // Listen for disconnect event on the accepted call
          call.on('disconnect', () => {
            console.log('Call disconnected');
            setCallStatus('Call ended');
            setAcceptedCall(null);
            dispatch(setActiveLead(null));
          });
        });

        call.on('cancel', () => {
          console.log('Call canceled');
          setCallStatus('Call ended');
          setIncomingCall(null);
          dispatch(setActiveLead(null));
        });
      });
      
      device.current.on('tokenWillExpire', async () => {
        try {
          const response = await axios.post(`${twilioApiUrl}/token`, { identity: agentId });
          callingToken.current = response.data.token;
          device.current.updateToken(callingToken.current); 
          console.log('Token updated');
        } catch (error) {
          console.error('Error updating token:', error);
          setCallStatus('Error updating token');
        }
      });

      device.current.register(); 
    };

    // Initial fetch of token
    if (agentId) {
      fetchToken();
    }

    return () => {
      if (device.current) {
        device.current.disconnectAll();
        if (isRegistered) {
          device.current.unregister(); 
        }
      }
    };
  }, [agentId]);

  return { device, callStatus, setCallStatus, incomingCall, setIncomingCall, acceptedCall, setAcceptedCall };
};

export default useTwilio;
