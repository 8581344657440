import { Helmet } from 'react-helmet-async';
// @mui
import { Breadcrumbs, Button, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections
import { useSnackbar } from 'notistack';
import CompanyImportStepper from '../sections/@dashboard/company/CompanyImportStepper';

export default function CompaniesImportPage() {

  const {enqueueSnackbar} = useSnackbar();

  const handleDownloadCSVTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/salesagent.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    enqueueSnackbar('Downloading CSV template', { variant: 'info' });
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/sequence/companies">
      Companies
    </Link>,
    <Typography key="3" color="text.primary">
      Import Companies
    </Typography>,

  ];

  return (
    <>
      <Helmet>
        <title> All Companies | Sales App </title>
      </Helmet>

        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing={0} alignItems="flex-start">
                  <Typography variant="h3">Import Companies</Typography>
                  <Typography variant="caption">Import Companies from CSV</Typography>
                </Stack>
                <Button
                  startIcon={<img src="/assets/icons/ic_leads.svg" alt="import leads" />}
                  onClick={handleDownloadCSVTemplate}
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    border: '1px solid rgba(145, 158, 171, 0.24)',
                  }}
                >
                  Download CSV Template
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
             <CompanyImportStepper />
             </Grid>
          </Grid>
        </Container>
    </>
  );
}
