import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Call, CallEnd, CallOutlined, RefreshOutlined } from '@mui/icons-material';
import { Box, Button, Grid, Skeleton, Stack, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import DialPad from '../../../components/dial-pad';
import {
  formatDuration,
  formatPhoneNumber,
  formatPhoneNumberFull,
  getPhoneNumber,
} from '../../../utils/helperFunctions';
import { useTwilioCall } from '../../../context/TwilioCallContext';
import useCallTimer from '../../../pages/twilio-caller/hooks/useCallTimer';
import useTwilioService from '../../../services/twilio/use.twilio.service';
import { twilioPhone } from '../../../config/apiConfig';

function AutoCallerCard({
  open,
  leads,
  formattedTimeParent,
  stopTimerParent,
  activeStep,
  activeContact,
  handleMoveToNext,
}) {
  const { fetchTwilioToken } = useTwilioService();
  const { startTimer, stopTimer, resetTimer, formattedTime } = useCallTimer();
  const { handleCall, handleEndCall, callStatus, callingToken, callInstance, nextCall, setNextCall, twilioError } =
    useTwilioCall();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const userId = user?._id;
  const [callInitiated, setCallInitiated] = useState(false);
  const [isTokenLoading, setisTokenLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activeLeadId = useMemo(() => activeContact?._id || null, [activeContact?._id, open]);
  const primaryPhone = useMemo(
    () => activeContact?.prospectPhoneNumber || null,
    [activeContact?.prospectPhoneNumber, open]
  );

  useEffect(() => {
    const isLastStep = activeStep >= leads.length - 1;
    if (!isLastStep && nextCall && !callInstance.current) {
      initiateCall();
      setIsLoading(false);
    } else {
      setNextCall('');
    }
  }, [nextCall, activeStep, leads.length, callInstance.current]);

  const initiateCall = () => {
    const formattedPhone = formatPhoneNumber(primaryPhone, '1');
    const leadId = activeLeadId;
    const callDialType = 'AUTO_DIAL';
    handleCall(formattedPhone, userId, leadId, callDialType);
    startTimer();
  };

  const handleEndCallFxn = () => {
    handleEndCall();
    stopTimer();
    resetTimer();
  };

  const handleStopAutoDialer = () => {
    handleEndCallFxn();
    setNextCall('');
    stopTimer();
    stopTimerParent();
    resetTimer();
  };

  const fetchToken = async () => {
    if (isTokenLoading) return;

    setisTokenLoading(true);

    try {
      const token = await fetchTwilioToken(userId);
      callingToken.current = token;
      enqueueSnackbar(`Token Refreshed`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to refresh token.', { variant: 'error' });
    } finally {
      setisTokenLoading(false);
    }
  };
  const sendDMF = (dmf) => {};

  useEffect(() => {
    if (primaryPhone !== 0 && callingToken.current && open && !callInitiated) {
      setCallInitiated(true);
      // handleCall();
    }
  }, [callingToken.current, open, primaryPhone, callInitiated]);


  return (
    <Stack direction="row" justifyContent="flex-start" spacing={1}>
      <DialPad sendDMF={sendDMF} />
      <Stack direction="column" justifyContent="flex-start" spacing={1}>
        <Typography variant="h5">{activeStep === 0 ? formattedTime : formattedTimeParent}</Typography>
        <Typography variant="h6" sx={{ fontWeight: 400 }}>
          Calling{' '}
          <span style={{ fontSize: '18px', fontWeight: 600 }}>
            {activeContact?.firstName ? activeContact?.firstName : 'No Contact'}
          </span>
          {' - '}
          <span style={{ fontSize: '18px', fontWeight: 600 }}>{primaryPhone ?? 'No Phone'}</span>
          {' from '}
          <span style={{ fontSize: '18px', fontWeight: 600 }}>
            {twilioPhone ? getPhoneNumber(formatPhoneNumberFull(twilioPhone)) : "No Phone"}
          </span>
        </Typography>

        <Typography variant="h6">{callStatus}</Typography>
        <Box>
          {!twilioError && (
            <ButtonContainer>
              {!callInstance.current ? (
                <Button startIcon={<Call />} variant="contained" onClick={initiateCall}>
                  Start Auto Dialer
                </Button>
              ) : isLoading ? (
                <Skeleton variant="rounded" width={100} height={60} />
              ) : (
                <ButtonContainer>
                  <Button variant="contained" color="error" onClick={handleEndCallFxn} endIcon={<CallEnd />}>
                    End
                  </Button>
                  <Button variant="outlined" color="error" onClick={handleStopAutoDialer} endIcon={<CallEnd />}>
                    Stop AutoDialer
                  </Button>
                </ButtonContainer>
              )}
            </ButtonContainer>
          )}
          {twilioError && (
            <Grid sm={12} md={10} lg={8}>
              <Stack spacing={1}>
                <Typography color="#282833" fontSize="16px">
                  Click on the Refresh token button below & Refresh your page👇🏼
                </Typography>
                <Button
                  startIcon={<RefreshOutlined />}
                  variant="contained"
                  sx={{ backgroundColor: '#282833' }}
                  onClick={fetchToken}
                  disabled={isTokenLoading}
                >
                  {isTokenLoading ? 'Fetching...' : 'Refresh Token'}
                </Button>
              </Stack>
            </Grid>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}

AutoCallerCard.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default AutoCallerCard;

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#FFF',
  '& > *:not(:last-child)': {
    marginRight: theme.spacing(2),
  },
}));
