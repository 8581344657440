import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Stack, Box, Dialog, DialogContent, DialogTitle, IconButton, Checkbox, FormControlLabel, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { REMOVE_AGENT_TO_SEQUENCE } from '../../../graphql/mutations/core.sequence';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    width: '600px',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AgentLabel = styled(Stack)(({ theme }) => ({
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

function ViewAgentsInSequence({ open, handleClose, sequence, refetch }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAgents, setSelectedAgents] = useState([]);
  
  const [removeAgents, { loading: removing, error }] = useMutation(REMOVE_AGENT_TO_SEQUENCE);

  useEffect(() => {
    setSelectedAgents([]);
  }, [open]);

  const handleSelectAgent = (agentId) => {
    setSelectedAgents((prev) => {
      if (prev.includes(agentId)) {
        return prev.filter((id) => id !== agentId);
      }
      return [...prev, agentId];
    });
  };

  const handleRemoveAgents = async () => {
    try {
     const res = await removeAgents({
        variables: {
          input: {
            sequenceId: sequence?._id,
            members: selectedAgents,
          },
        },
      });
      if(res.errors){
        enqueueSnackbar(`Error: ${res.errors[0].message}`, { variant: 'error' });
        return;
      }
      enqueueSnackbar('Agents removed successfully!', { variant: 'success' });
      refetch();
      handleClose();
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    }
  };

  return (
    <BootstrapDialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        View Agents in &nbsp;⊷&nbsp;{sequence?.title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {sequence?.members?.length === 0 ? (
            <Typography>No agents available in this sequence.</Typography>
          ) : (
            sequence.members.map((agent) => (
              <FormControlLabel
                sx={{
                  borderRadius: 2,
                  '&:hover': {
                    backgroundColor: '#F0F2F4',
                  },
                }}
                key={agent._id}
                control={
                  <Checkbox
                    checked={selectedAgents.includes(agent._id)}
                    onChange={() => handleSelectAgent(agent._id)}
                  />
                }
                label={
                  <AgentLabel direction="row" alignItems="center">
                    <Avatar alt={`${agent.firstName} ${agent.lastName}`} sx={{ marginRight: 1 }} />
                    <Typography variant="body1">{`${agent.firstName} ${agent.lastName}`}</Typography>
                  </AgentLabel>
                }
              />
            ))
          )}
        </Stack>
      </DialogContent>
      <Box sx={{ p: 2 }}>
        <LoadingButton
          loading={removing}
          onClick={handleRemoveAgents}
          variant="contained"
          color="secondary"
          disabled={selectedAgents.length === 0}
          fullWidth
        >
          Remove Selected Agents
        </LoadingButton>
      </Box>
    </BootstrapDialog>
  );
}

ViewAgentsInSequence.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sequence: PropTypes.object.isRequired, 
  refetch: PropTypes.func.isRequired,
};

export default ViewAgentsInSequence;
