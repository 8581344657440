import { Helmet } from 'react-helmet-async';
// @mui
import {
    CircularProgress,
    Container,
    Divider,
    Grid,
    Link,
    Stack,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

// components
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import EmailIcon from '@mui/icons-material/Email';
// sections
import { useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_CONTACT_BY_PHONE } from '../graphql/queries/lead';
import { GET_CHAT_COMPLETION } from '../graphql/queries/log';
import { GET_ORGANIZATION } from '../graphql/queries/organization';
import useSpeechRecognition from '../hooks/useSpeechRecognition';
// import CompanyDetails from '../sections/@dashboard/details/CompanyDetails';
// ----------------------------------------------------------------------

export default function PoolDetailsPage() {
    const navigate = useNavigate();
    const { phone } = useParams();
    const messagesEndRef = useRef(null)
    const { data: organization } = useQuery(GET_ORGANIZATION);
    const [context, setContext] = useState("");
    const [completeChat, { data: chatCompletion }] = useLazyQuery(GET_CHAT_COMPLETION);
    const [recognize, setRecognize] = useState(false);
    const [chatCompletionResponses, setChatCompletionResposes] = useState([]);
    const { recognitionText, startStreaming, stopStreaming, resetTranscription } = useSpeechRecognition();
    const [getContactByPhoneNumber, { data: contact, loading, error }] = useLazyQuery(GET_CONTACT_BY_PHONE);

    const handleFormatPhoneNumber = (phone) => {
        const cleaned = (`${phone}`).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return cleaned;
    };

    const handleClick = () => {
        navigate('/sequence');
    }

    const handleViewAllCompanies = () => {
        navigate('/sequence/companies');
    }

    const handleResetTranscription = () => {
        setChatCompletionResposes([])
        resetTranscription()
    }


    const timeoutId = useRef(null);

    useEffect(() => {
        if (phone) {
            getContactByPhoneNumber({
                variables: {
                    phoneNumber: handleFormatPhoneNumber(phone),
                }
            })
        }
    }, [phone, getContactByPhoneNumber])

    useEffect(() => {
        if (organization && organization.getOrganization) {
            setContext(organization.getOrganization.system)
        }
    }, [organization]);


    useEffect(() => {
        if (recognitionText && recognitionText !== '') {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }

            timeoutId.current = setTimeout(() => {
                completeChat({ variables: { input: { prompt: recognitionText, context } } })
                setChatCompletionResposes(prev => [...prev, {
                    prompt: recognitionText,
                    response: "Thinking..."
                }]);
            }, 1000);
        }
    }, [recognitionText, context]);


    useEffect(() => {
        if (chatCompletion?.completeChat && chatCompletionResponses.length > 0) {
            setChatCompletionResposes(prev => {
                const last = prev.length - 1;
                prev[last] = {
                    prompt: prev[last].prompt,
                    response: chatCompletion?.completeChat
                };
                return [...prev]
            });
        }

    }, [chatCompletion, chatCompletionResponses]);


    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            {phone}
        </Typography>,
    ];

    return (
        <>
            <Helmet>
                <title> Contact Details | Sales App </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    size="small"
                                    startIcon={<img src="/assets/icons/ic_calender.svg" width="20" alt="call" />}
                                    onClick={handleViewAllCompanies}
                                >
                                    See all contacts
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Card>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, height: '60vh', maxHeight: '80vh', overflowY: 'auto' }}>
                                {loading ? <CircularProgress /> : (contact?.getContactByPhoneNumber?.company ?
                                    <Stack direction='column' alignItems='flex-start' justifyContent='flex-start' spacing={1}>
                                        <Typography variant="h3" >
                                            {`${contact?.getContactByPhoneNumber?.company || ''}`}
                                        </Typography>
                                        <Typography variant="h6" >
                                            Contact Information 
                                        </Typography>

                                        <Divider style={{ backgroundColor: "#6605B8", border: '1px Solid blue', width: '100%' }} />
                                        <Typography variant="body2" >
                                            {`Name: ${contact?.getContactByPhoneNumber?.firstName || ''} ${contact?.getContactByPhoneNumber?.lastName || ''}`}
                                        </Typography>
                                        <Typography variant="body2" >
                                            {`Phone Number: ${contact?.getContactByPhoneNumber?.prospectPhoneNumber || ''}`}
                                        </Typography>
                                        <Typography variant="body2" >
                                            {`Email: ${contact?.getContactByPhoneNumber?.emailAddress || ''}`}
                                        </Typography>
                                        <Typography variant="body2" >
                                            {`Designation: ${contact?.getContactByPhoneNumber?.prospectDesignation || ''}`}
                                        </Typography>
                                        <Typography variant="body2" >
                                            {`Linkedin Profile: ${contact?.getContactByPhoneNumber?.prospectLinkedinProfile || ''}`}
                                        </Typography>
                                        <Typography variant="body2" >
                                            {`Time Zone: ${contact?.getContactByPhoneNumber?.timeZone || ''}`}
                                        </Typography>
                                    </Stack>
                                    :
                                    <Typography variant="body2" >
                                        Contact not found
                                    </Typography>
                                )}
                                <Typography variant='h6'>Transcription</Typography>
                                <Divider style={{ backgroundColor: "#6605B8", border: '1px Solid blue', width: '100%' }} />
                                {chatCompletionResponses?.length > 0 &&
                                    <Typography variant='h6'>Ai Says</Typography>}
                                {chatCompletionResponses?.map((response, index) => (
                                    <Stack key={index} direction='column' alignItems='flex-start' justifyContent='flex-start' spacing={1}>
                                        <Typography variant="caption" color='inherit' sx={{ mb: 1, fontWeight: 'bold' }}>
                                            {`${response.prompt}`}
                                        </Typography>
                                        <Typography variant="body2" color='inherit' sx={{ mb: 1 }}>
                                            {`${response.response}`}
                                        </Typography>
                                    </Stack>
                                ))}
                                <div ref={messagesEndRef} />
                            </CardContent>
                            <CardActions>
                                <Stack direction='row' spacing={2} justifyContent="flex-end" alignItems="flex-end" sx={{ flex: 1, py: 2 }}>
                                    <Button color='primary' variant='contained' onClick={() => {
                                        startStreaming();
                                        setRecognize(true);
                                    }} disabled={recognize}> Start Streaming </Button>
                                    <Button color='primary' variant='outlined' onClick={() => {
                                        stopStreaming();
                                        setRecognize(false);
                                    }} disabled={!recognize}> Stop Streaming </Button>
                                    <Button color='primary' variant='outlined' onClick={handleResetTranscription}> Reset Transcription </Button>
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>


    );
}
