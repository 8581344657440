import { Helmet } from 'react-helmet-async';
// @mui
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Container, Grid, LinearProgress, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// components
// mock
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import { UPDATE_ORGANIZATION_MODEL } from '../graphql/mutations/training';
import { GET_TRAINING_JOBS, GET_USER_ORGANIZATION } from '../graphql/queries/training';
import { LABELS } from '../utils/constants';

// ---------------------------------------------------------------------- 

export default function TrainingHistoryPage() {

    const [updateOrganizationModel, {data: trainingModel, loading:loadingTrainingModel}] = useMutation(UPDATE_ORGANIZATION_MODEL);
    const {data, loading, error} = useQuery(GET_TRAINING_JOBS);
    const [getOrganization, {data: organizationData, loading: organizationLoading}] = useLazyQuery(GET_USER_ORGANIZATION);
    const {enqueueSnackbar} = useSnackbar();

    const [activeModel, setActiveModel] = useState('gpt-3.5-turbo');

    const navigate = useNavigate();
  
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Learning
        </Typography>,
    ];

    const handleSetActiveModel = (model) => {
        updateOrganizationModel({
            variables: {
                model
            }
        });
    }

    useEffect(() => {
        if(trainingModel) {
            enqueueSnackbar("Model activated successfully", {variant: "success"});
        }
        getOrganization();
    }, [trainingModel]);

    useEffect(() => {
        if(organizationData) {
            setActiveModel(organizationData?.getOrganization?.model);
        }
    }, [organizationData]);

    return (
        <>
            <Helmet>
                <title> Sequence: Learning | Sales App </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack spacing={2} direction='row' justifyContent="space-between" alignItems="center">
                            <Stack direction="column" spacing={0} alignItems="flex-start">
                                <Typography variant="h3">{LABELS.LearningCampus}</Typography>
                                <Typography variant="caption">{LABELS.LearningCampusDescription}</Typography>
                            </Stack>
                            <Button variant='outlined' onClick={()=>navigate('/sequence/training')}>
                                    Training/Testing Camp
                            </Button>
                        </Stack>
                    </Grid>
                    {loading && <Loader />}
                    {(loadingTrainingModel || organizationLoading) && <Grid item xs={12} sm={12} md={12}><LinearProgress /></Grid>}
                    <Grid item xs={12} sm={12} md={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Model</TableCell>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>Finished At</TableCell>
                                        <TableCell>Trained Tokens</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.listFineTuningJobs.map((row) => (
                                        <TableRow key={row._id}>
                                            <TableCell>{row.status}</TableCell>
                                            <TableCell>{row.fine_tuned_model}</TableCell>
                                            <TableCell>{row.created_at}</TableCell>
                                            <TableCell>{row.finished_at}</TableCell>
                                            <TableCell>{row.trained_tokens}</TableCell>
                                            <TableCell>
                                                <Button variant="outlined" disabled={row?.fine_tuned_model === activeModel || row?.status !=="succeeded"} onClick={()=>handleSetActiveModel(row?.fine_tuned_model)}> {row?.status !=="succeeded"? row?.status :row?.fine_tuned_model === activeModel ? "Active": "Activate"}</Button>
                                             </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
