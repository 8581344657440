// @mui
import Box from '@mui/material/Box';
// components
import ChatMessageItem from './chat-message-item';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import useMessagesScroll from './hooks/use-messages-scroll';

// ----------------------------------------------------------------------


export default function ChatMessageList({ messages }) {
  const { messagesEndRef } = useMessagesScroll(messages);


  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 5, height: 1 }}>
        <Box>
          {messages.map((message) => (
            <ChatMessageItem
              key={message._id}
              message={message}
            />
          ))}
        </Box>
      </Scrollbar>
    </>
  );
}
