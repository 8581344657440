import {
  Paper,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  AvatarGroup,
  Avatar,
  Divider,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  styled,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { TOGGLE_FAVORITE_SEQUENCE, TOGGLE_ARCHIVE_SEQUENCE } from '../../../graphql/mutations/core.sequence';
import AddAgentToSequence from './AddAgentToSequence';
import ViewAgentsInSequence from './ViewAgentsInSequence';
import UpdateCoreSequenceDialog from './UpdateCoreSequenceDialog';
import SequencePieChart from './SequencePieChart';
import { fNumber } from '../../../utils/formatNumber';
import { truncateText } from '../../../utils/textHelper';
import { isAdmin } from '../../../utils/helperFunctions';

const CoreSequenceOverview = ({ allCoreSequence, refetch }) => {
  const { user } = useSelector((state) => state.auth);
  const userIsAdmin = isAdmin(user.role);
  const [toggleFavoriteSequence] = useMutation(TOGGLE_FAVORITE_SEQUENCE);
  const [toggleArchiveSequence] = useMutation(TOGGLE_ARCHIVE_SEQUENCE);
  const { enqueueSnackbar } = useSnackbar();
  const totals = allCoreSequence.reduce(
    (acc, seq) => {
      if (seq.leadStats) {
        acc.totalLeads += seq.leadStats.totalLeads || 0;
        acc.contactedLeads += seq.leadStats.contactedLeads || 0;
        acc.remainingLeads += seq.leadStats.remainingLeads || 0;
      }
      return acc;
    },
    { totalLeads: 0, contactedLeads: 0, remainingLeads: 0 }
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddAgent, setOpenAddAgent] = useState(false);
  const [openViewAgent, setOpenViewAgent] = useState(false);
  const [updateSequenceOpen, setUpdateSequenceOpen] = useState(false);
  const [sequences, setSequences] = useState(allCoreSequence || []);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [favorites, setFavorites] = useState(new Set());
  const navigate = useNavigate();
  const maxVisibleAvatars = 4;

  const getProgressPercentage = (completed, total) => (total === 0 ? 0 : (completed / total) * 100);

  useEffect(() => {
    setSequences(allCoreSequence || []);
  }, [allCoreSequence]);

  const handleManageClick = (event, sequence) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSequence(sequence);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddAgent = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setOpenAddAgent(true);
    handleMenuClose();
  };

  const handleViewAgents = () => {
    setOpenViewAgent(true);
    setAnchorEl(null);
  };

  const handleCloseViewAgents = () => {
    setOpenViewAgent(false);
  };

  const handleUpdateSequence = () => {
    setUpdateSequenceOpen(true);
    setAnchorEl(null);
  };

  const handleCloseUpdatSequence = () => {
    setUpdateSequenceOpen(false);
  };

  const handleAddLead = (e) => {
    e.stopPropagation();
    console.log(`Add lead to ${selectedSequence.title}`);
    navigate('/sequence/companies/import');
    handleMenuClose();
  };

  const handleArchiveSequence = (e) => {
    e.stopPropagation();

    setSequences((prevSequences) => prevSequences.filter((sequence) => sequence._id !== selectedSequence._id));
    enqueueSnackbar(`${selectedSequence?.title} sequence archived`, { variant: 'success' });
    toggleArchiveSequence({ variables: { sequenceId: selectedSequence._id } });

    handleMenuClose();
  };

  const handleViewSequence = (e, sequenceId, sequenceTitle, totalSteps) => {
    e.stopPropagation();
    navigate(`/sequence/sequence-overview/${sequenceId}/${sequenceTitle}/${totalSteps}`);
  };

  const handleClose = () => {
    setOpenAddAgent(false);
    setSelectedSequence(null);
  };

  const toggleFavorite = (e, sequenceId, isFavorited) => {
    e.stopPropagation();

    setSequences((prevSequences) =>
      prevSequences.map((sequence) =>
        sequence?._id === sequenceId ? { ...sequence, isFavorited: !sequence.isFavorited } : sequence
      )
    );

    enqueueSnackbar(!isFavorited ? `Sequence Favorited` : `Sequence Unfavorited`, { variant: 'success' });
    toggleFavoriteSequence({ variables: { sequenceId } });
  };

  const sortedSequences = [...sequences].sort((a, b) => {
    return a.isFavorited === b.isFavorited ? 0 : a.isFavorited ? -1 : 1;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <Paper
          sx={{
            width: '100%',
            height: 140,
            display: 'flex',
            flexDirection: 'row',
            padding: 5,
            alignItems: 'center',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Total Core Sequence</Typography>
                <Typography variant="h5">{fNumber(sequences?.length || 0)}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Total Leads</Typography>
                <Typography variant="h5">{fNumber(totals?.totalLeads || 0)}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                <Typography variant="caption">Remaining Leads</Typography>
                <Typography variant="h5">{fNumber(totals?.remainingLeads || 0)}</Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={1}>
                <Typography variant="caption">Contacted Leads out of Total Leads</Typography>
                <SequencePieChart contactedLeads={totals.contactedLeads} totalLeads={totals.totalLeads} />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', cursor: 'pointer' }}>
          <CardContent>
            <Divider />

            {sortedSequences.map((sequence, index) => {
              const progressPercentage = getProgressPercentage(
                sequence?.leadStats?.contactedLeads || 0,
                sequence?.leadStats?.totalLeads || 0
              );

              return (
                <Grid item xs={12} key={sequence?._id}>
                  <Stack
                    key={sequence?._id}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    sx={{
                      py: 2,
                      px: 3,
                      borderBottom: '1px solid #e0e0e0',
                      '&:hover': { backgroundColor: '#F9FAFB' },
                    }}
                    onClick={(e) => handleViewSequence(e, sequence?._id, sequence?.title || '', sequence?.leadStats?.totalSteps)}
                  >
                    {/* Sequence Info */}
                    <Stack direction="column" spacing={1} sx={{ width: '200px' }}>
                      {/* Sequence Title */}
                      <Tooltip title={sequence?.title || ''} arrow>
                        <Typography
                          variant="h6"
                          sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {truncateText(sequence?.title || '', 25)}
                        </Typography>
                      </Tooltip>

                      <Stack direction="row" spacing={1} alignItems="center" key={index}>
                        <AvatarGroup max={maxVisibleAvatars} sx={{ cursor: 'pointer' }}>
                          {sequence.members.slice(0, maxVisibleAvatars).map((agent) => (
                            <Tooltip key={agent?.id} title={`${agent.firstName} ${agent.lastName}`} arrow>
                              <Avatar
                                alt={`${agent.firstName} ${agent.lastName}`}
                                sx={{ width: 30, height: 30, cursor: 'pointer' }}
                              />
                            </Tooltip>
                          ))}
                          {sequence.members.length > maxVisibleAvatars && (
                            <Avatar sx={{ width: 30, height: 30, fontSize: 16 }}>
                              +{sequence.members.length - maxVisibleAvatars}
                            </Avatar>
                          )}
                        </AvatarGroup>
                      </Stack>
                    </Stack>

                    {/* Leads Stats */}
                    <Stack
                      direction="row"
                      alignItems="center"
                      spacing={3}
                      sx={{ width: 'fit-content', justifyContent: 'flex-start' }}
                    >
                      <Stack direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          Total Steps
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          <b>{fNumber(sequence?.leadStats?.totalSteps || 0)}</b>
                        </Typography>
                      </Stack>

                      <Stack direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          Total Leads
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          <b>{fNumber(sequence?.leadStats?.totalLeads || 0)}</b>
                        </Typography>
                      </Stack>

                      <Stack direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          Completed
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          <b>{fNumber(sequence?.leadStats?.contactedLeads || 0)}</b>
                        </Typography>
                      </Stack>

                      <Stack direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          Remaining
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: 14 }}>
                          <b>{fNumber(sequence?.leadStats?.remainingLeads || 0)}</b>
                        </Typography>
                      </Stack>
                    </Stack>

                    {/* Progress Bar */}
                    <Stack direction="column" spacing={1} sx={{ width: 150 }}>
                      <LinearProgress variant="determinate" value={progressPercentage} />
                      <Typography variant="caption">
                        <span>
                          <b>{fNumber(sequence?.leadStats?.contactedLeads || 0)}</b>
                        </span>{' '}
                        of{' '}
                        <span>
                          <b>{fNumber(sequence?.leadStats?.totalLeads || 0)}</b>
                        </span>{' '}
                        leads called
                      </Typography>
                    </Stack>

                    <IconButton
                      onClick={(e) => toggleFavorite(e, sequence?._id, sequence.isFavorited)}
                      sx={{ color: sequence?.isFavorited ? 'gold' : 'grey' }}
                    >
                      {sequence.isFavorited ? <StarIcon /> : <StarBorderIcon />}
                    </IconButton>

                    {/* Manage Button */}
                    {userIsAdmin && (
                      <Button
                        onClick={(event) => handleManageClick(event, sequence)}
                        variant="outlined"
                        sx={{ color: '#080708' }}
                      >
                        Manage
                      </Button>
                    )}

                    {/* Navigation Button */}
                    <IconButton onClick={(e) => handleViewSequence(e, sequence?._id, sequence?.title || '', sequence?.leadStats?.totalSteps)} sx={{ color: '#080708' }}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              );
            })}

            {/* Menu for Management Options */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleAddAgent}>Add Agent</MenuItem>
              <MenuItem onClick={handleAddLead}>Add Leads</MenuItem>
              <MenuItem onClick={handleViewAgents}>View Agents</MenuItem>
              <MenuItem onClick={handleUpdateSequence}>Update Sequence</MenuItem>
              <MenuItem onClick={handleArchiveSequence}>Archive Sequence</MenuItem>
            </Menu>
          </CardContent>
        </Card>
        {openAddAgent && (
          <AddAgentToSequence
            open={openAddAgent}
            handleClose={handleClose}
            sequence={selectedSequence}
            refetch={refetch}
          />
        )}
        {openViewAgent && (
          <ViewAgentsInSequence
            open={openViewAgent}
            handleClose={handleCloseViewAgents}
            sequence={selectedSequence}
            refetch={refetch}
          />
        )}
        {updateSequenceOpen && (
          <UpdateCoreSequenceDialog
            open={updateSequenceOpen}
            handleClose={handleCloseUpdatSequence}
            sequence={selectedSequence}
            refetch={refetch}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CoreSequenceOverview;

const Row = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  gap: 10,
  alignItems: 'center',
}));
