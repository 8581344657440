import { formatDistanceToNowStrict } from 'date-fns';
import PropTypes from 'prop-types';
// @mui
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function CallItem({ call, selected, onClickCall, sx, ...other }) {
  console.log("CALL...",call);
  return (
    <ListItemButton
      onClick={onClickCall}
      sx={{
        p: 1,
        mb: 0.5,
        borderRadius: 1,
        ...(selected && {
          bgcolor: 'action.selected',
          color: 'text.primary',
        }),
        ...sx,
      }}
      {...other}
    >
      <Avatar sx={{ mr: 2 }}>
        {call?.to?.charAt(0)?.toUpperCase()}
      </Avatar>

      <>
        <ListItemText
          primary={call?.to}
          primaryTypographyProps={{
            noWrap: true,
            variant: 'subtitle2',
          }}
          secondary={`Ramy to Test User`}
          // secondary={`${call?.agentId?.user?.firstName} to ${call.subject}`}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: call.isUnread ? 'subtitle2' : 'body2',
            color: call.isUnread ? 'text.primary' : 'text.secondary',
          }}
        />

        <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
          <Typography
            noWrap
            variant="body2"
            component="span"
            sx={{
              mb: 1.5,
              fontSize: 12,
              color: 'text.disabled',
            }}
          >
            {formatDistanceToNowStrict(new Date(call.createdAt), {
              addSuffix: false,
            })}
          </Typography>

          {call?.callDone === "COMPLETED" && (
            <Box
              sx={{
                bgcolor: 'info.main',
                width: 8,
                height: 8,
                borderRadius: '50%',
              }}
            />
          )}
        </Stack>
      </>
    </ListItemButton>
  );
}

CallItem.propTypes = {
  call: PropTypes.object,
  onClickCall: PropTypes.func,
  selected: PropTypes.bool,
  sx: PropTypes.object,
};
