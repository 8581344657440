import React from 'react';
import { calculatePxToPercentage } from '../../../utils/cssHelper';

const RecrowdlyAppIcon = () => {
    return (
        <svg
            width={calculatePxToPercentage(60)}
            height={calculatePxToPercentage(60)}
            viewBox="0 0 61 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" width="60" height="60" rx="30" fill="white" />
            <rect x="0.5" width="60" height="60" rx="30" fill="#F1F3EC" />
            <g clipPath="url(#clip0_17666_128226)">
                <path
                    d="M33.6636 19.5298L33.5014 19.4813L33.332 19.4258L33.5014 19.4743L33.6636 19.5298Z"
                    fill="#171517"
                />
                <path
                    d="M23.0872 38.7011L23.3622 38.9177L17.5593 45.7718L16.4805 44.8705L22.2834 38.0164L22.5513 38.2609L25.8864 34.3203C26.0553 34.4805 26.2343 34.6299 26.4223 34.7675L23.0872 38.7011Z"
                    fill="#171517"
                />
                <path
                    d="M37.0163 21.3738C36.9865 21.3469 36.9535 21.3237 36.918 21.3047L37.0163 21.3738Z"
                    fill="#171517"
                />
                <path
                    d="M39.5007 24.1604L39.4375 24.043C39.4613 24.0806 39.4824 24.1199 39.5007 24.1604Z"
                    fill="#171517"
                />
                <path
                    d="M40.0138 25.0693C39.9644 24.9645 39.908 24.8666 39.8516 24.7617C39.9128 24.8604 39.9671 24.9632 40.0138 25.0693Z"
                    fill="#171517"
                />
                <path
                    d="M41.0239 28.0688C41.0239 27.9569 40.9816 27.8451 40.9534 27.7332C40.9251 27.6213 40.911 27.5025 40.8828 27.3906L40.9675 27.7332C40.9745 27.8451 41.0239 27.9569 41.0239 28.0688Z"
                    fill="#171517"
                />
                <path
                    d="M41.1197 28.7653C41.1197 28.6534 41.1197 28.5415 41.0703 28.4297L41.1197 28.7653Z"
                    fill="#171517"
                />
                <path
                    d="M41.0239 31.9297C41.0239 32.0415 41.0239 32.1534 40.9675 32.2653C40.911 32.3771 40.9181 32.496 40.8828 32.6079C40.8828 32.496 40.9393 32.3841 40.9604 32.2653C40.9816 32.1464 41.0239 32.0415 41.0239 31.9297Z"
                    fill="#171517"
                />
                <path
                    d="M41.1166 31.2344L41.0742 31.577C41.0742 31.4651 41.1095 31.3462 41.1236 31.2344H41.1166Z"
                    fill="#171517"
                />
                <path
                    d="M39.412 35.9844C39.384 36.0348 39.3534 36.0839 39.3203 36.1312L39.412 35.9844Z"
                    fill="#171517"
                />
                <path
                    d="M39.505 35.8507C39.505 35.8997 39.4415 35.9486 39.4062 35.9976L39.4909 35.8438L39.505 35.8507Z"
                    fill="#171517"
                />
                <path
                    d="M40.0146 34.9297C39.9652 35.0346 39.9158 35.1324 39.8594 35.2303C39.9189 35.1331 39.9731 35.0327 40.0217 34.9297H40.0146Z"
                    fill="#171517"
                />
                <path
                    d="M37.7133 38.0226L37.4593 38.2533L37.2617 38.4211L37.4522 38.2463C37.5369 38.1764 37.6215 38.0925 37.7062 38.0156L37.7133 38.0226Z"
                    fill="#171517"
                />
                <path
                    d="M33.8263 40.4062L33.5017 40.5251L33.2266 40.609L33.5017 40.5181L33.8263 40.4062Z"
                    fill="#171517"
                />
                <path
                    d="M34.4741 40.1484L34.1636 40.2813L33.832 40.4071L34.1566 40.2743C34.2648 40.2384 34.3708 40.1964 34.4741 40.1484Z"
                    fill="#171517"
                />
                <path
                    d="M30.3637 41.0625H30.0039C30.1237 41.0698 30.2439 41.0698 30.3637 41.0625Z"
                    fill="#171517"
                />
                <path
                    d="M33.8285 19.5845C33.7226 19.5498 33.6168 19.5082 33.5039 19.4805L33.8285 19.5845Z"
                    fill="#171517"
                />
                <path
                    d="M37.0163 21.3738C36.9865 21.3469 36.9535 21.3237 36.918 21.3047L37.0163 21.3738Z"
                    fill="#171517"
                />
                <path
                    d="M40.9516 27.7344C40.9516 27.8462 40.9939 27.9581 41.0081 28.07C41.0081 27.9581 40.9657 27.8462 40.9375 27.7344H40.9516Z"
                    fill="#171517"
                />
                <path
                    d="M49.9466 26.8757H49.9324L49.8125 26.1836H49.8196L49.9466 26.8757Z"
                    fill="#171517"
                />
                <path
                    d="M41.0213 31.9297C41.0213 32.0415 41.0213 32.1534 40.9648 32.2653C40.9648 32.1534 41.0213 32.0415 41.0213 31.9297Z"
                    fill="#171517"
                />
                <path
                    d="M37.4983 38.257L37.3008 38.4248L37.4913 38.25L37.4983 38.257Z"
                    fill="#171517"
                />
                <path
                    d="M33.5039 40.5172L33.8285 40.4062L33.5039 40.5242V40.5172Z"
                    fill="#171517"
                />
                <path
                    d="M34.1636 40.2804L33.832 40.4063L34.1566 40.2734L34.1636 40.2804Z"
                    fill="#171517"
                />
                <path
                    d="M26.4223 25.2309C26.2343 25.3685 26.0553 25.5179 25.8864 25.6781L22.5513 21.7375L22.4033 21.8772C22.361 21.8772 22.3257 21.9471 22.2834 21.982L16.4805 15.1279L17.5593 14.2266L23.3904 21.0807L23.1154 21.2973L26.4223 25.2309Z"
                    fill="#171517"
                />
                <path
                    d="M28.2898 24.3006C28.0659 24.3675 27.8469 24.4492 27.634 24.5451L25.8572 19.7102C25.7874 19.7339 25.7191 19.7619 25.6527 19.794L25.5258 19.8429L22.4375 11.4587L23.756 10.9766L26.8443 19.3608L26.52 19.4656L28.2898 24.3006Z"
                    fill="#171517"
                />
                <path
                    d="M30.7149 10V18.9432H30.3694V24.0786H29.6643V18.9293H29.3047V10H30.7149Z"
                    fill="#171517"
                />
                <path
                    d="M37.5616 11.4466L34.4733 19.8308C34.3746 19.7889 34.2688 19.74 34.163 19.7051L32.3862 24.533C32.1734 24.4371 31.9543 24.3554 31.7305 24.2885L33.5003 19.4675L33.6624 19.5164L33.5003 19.4605L33.331 19.4116H33.2535H33.1689L36.2572 11.0273L36.5956 11.1531L37.2584 11.3907L37.5616 11.4466Z"
                    fill="#171517"
                />
                <path
                    d="M43.5143 15.1279L37.7114 21.975L37.4999 21.7445L34.1648 25.6851C33.9996 25.5209 33.8203 25.3713 33.6289 25.2379L36.964 21.3043C36.9996 21.3235 37.0328 21.347 37.0627 21.3742L36.964 21.2973L36.6749 21.0807L42.4355 14.2266L42.7176 14.4571L43.2534 14.9043L43.5143 15.1279Z"
                    fill="#171517"
                />
                <path
                    d="M24.9936 26.7277C24.8649 26.9224 24.7495 27.1255 24.6481 27.3356L20.1496 24.7644L19.9804 25.0788L12.168 20.6072L12.3513 20.2997L12.8731 19.3984L20.6855 23.8701C20.6291 23.9679 20.5656 24.0587 20.5022 24.1565L24.9936 26.7277Z"
                    fill="#171517"
                />
                <path
                    d="M24.1724 28.6182C24.1159 28.8483 24.0735 29.0816 24.0455 29.3168L18.9406 28.4225C18.9406 28.5343 18.9406 28.6531 18.8912 28.7649L10 27.2138L10.2468 25.8164L19.1662 27.3885C19.1662 27.4933 19.1169 27.5981 19.1028 27.7029L24.1724 28.6182Z"
                    fill="#171517"
                />
                <path
                    d="M19.0957 32.3999C19.0957 32.4698 19.0957 32.5396 19.138 32.6095L10.2468 34.1606L10 32.7632L18.8912 31.2121C18.8912 31.3239 18.8912 31.4427 18.9406 31.5545L24.0525 30.6602C24.0747 30.8957 24.1148 31.1293 24.1724 31.3588L19.0675 32.2532C19.0641 32.281 19.0641 32.3092 19.0675 32.337C19.0689 32.3607 19.0789 32.383 19.0957 32.3999Z"
                    fill="#171517"
                />
                <path
                    d="M20.5022 35.8501C20.5656 35.9479 20.6291 36.0457 20.6855 36.1435L12.8731 40.6012L12.168 39.3924L19.9804 34.9208L20.1496 35.2352L24.6411 32.6641C24.7459 32.8714 24.8637 33.0721 24.9936 33.2649L20.5022 35.8501Z"
                    fill="#171517"
                />
                <path
                    d="M47.8248 20.6041L40.0124 25.0687C39.9653 24.965 39.9111 24.8646 39.8502 24.7683L35.3799 27.3325C35.2751 27.1251 35.1573 26.9245 35.0273 26.7316L39.5047 24.1674C39.4838 24.1286 39.4602 24.0913 39.4342 24.0556C39.3989 23.9927 39.3566 23.9368 39.3143 23.874L47.1268 19.4023L47.303 19.7098L47.6556 20.3106L47.8248 20.6041Z"
                    fill="#171517"
                />
                <path
                    d="M50.0036 27.2146L41.1194 28.7657L41.0771 28.4233L35.9863 29.3107C35.9592 29.0753 35.9168 28.8419 35.8594 28.612L40.9572 27.7177C40.9572 27.8295 41.0066 27.9412 41.0277 28.053C41.0277 27.9412 41.0277 27.8295 40.9713 27.7177L40.8867 27.3753L49.7709 25.8242L49.8343 26.1666L49.9542 26.8653L50.0036 27.2146Z"
                    fill="#171517"
                />
                <path
                    d="M49.9981 32.7827L49.9347 33.1251L49.8148 33.8238L49.7514 34.1662L40.8672 32.6151C40.8672 32.5033 40.9236 32.3915 40.9518 32.2727C40.98 32.1539 40.9941 32.0491 41.0082 31.9373C41.0082 32.0491 40.9659 32.1609 40.9377 32.2727L35.8398 31.3784C35.8978 31.1489 35.9379 30.9153 35.9597 30.6797L41.0575 31.574L41.0998 31.2317L49.9981 32.7827Z"
                    fill="#171517"
                />
                <path
                    d="M47.8272 39.3955L47.658 39.689L47.2984 40.2968L47.1221 40.6043L39.3167 36.1326C39.3498 36.0853 39.3804 36.0364 39.4084 35.9859C39.4084 35.937 39.4789 35.8881 39.5071 35.8392L35.0156 33.268C35.144 33.0718 35.2617 32.8689 35.3682 32.6602L39.8455 35.2243C39.9019 35.1265 39.9513 35.0287 40.0006 34.9239L47.8272 39.3955Z"
                    fill="#171517"
                />
                <path
                    d="M43.2871 44.6041L43.0262 44.8277L42.4903 45.2748L42.2083 45.5054L36.4054 38.6512L36.6945 38.4346L36.7932 38.3578L36.6945 38.4277L33.3594 34.494C33.5507 34.3606 33.73 34.211 33.8952 34.0469L37.2303 37.9805L37.04 38.1552L37.2374 37.9875L37.4912 37.7569L43.2871 44.6041Z"
                    fill="#171517"
                />
                <path
                    d="M37.564 48.553L37.2467 48.6647L36.5839 48.9023L36.2455 49.0211L33.1571 40.6368H33.2135L33.4885 40.553L33.8129 40.4342L33.4885 40.546L31.7188 35.725C31.9426 35.6581 32.1617 35.5764 32.3745 35.4805L34.1443 40.3014L33.8199 40.4342L34.1513 40.3084L34.4616 40.1757L37.564 48.553Z"
                    fill="#171517"
                />
                <path
                    d="M30.7149 41.055V49.9982H29.3047V41.055H29.6643V35.9336H30.3694V41.062H30.7149V41.055Z"
                    fill="#171517"
                />
                <path
                    d="M26.7738 40.6095H26.8443L23.756 48.9937L23.4387 48.875L22.7759 48.6374L22.4375 48.5186L25.5258 40.1343L25.829 40.2531L26.1463 40.3719L25.829 40.2461L27.627 35.4531C27.8398 35.5491 28.0589 35.6308 28.2827 35.6977L26.5129 40.5186L26.7738 40.6095Z"
                    fill="#171517"
                />
            </g>
            <defs>
                <clipPath id="clip0_17666_128226">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(10 10)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RecrowdlyAppIcon;
