import { gql } from '@apollo/client';

export const CREATE_SEQUENCE_PROMPT = gql`
  mutation CreateSequencePrompt($input: PromptInput!) {
    createSequencePrompt(input: $input) {
      _id
    }
  }
`;

export const UPDATE_PROMPT = gql`
  mutation UpdatePrompt($input: PromptInput!, $promptId: String!) {
    updatePrompt(input: $input, promptId: $promptId) {
      _id
      title
      prompt
      isActive
      user {
        _id
      }
      coreSequence {
        _id
      }
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_PROMPT = gql`
  mutation DeletePrompt($promptId: String!) {
    deletePrompt(promptId: $promptId)
  }
`;

export const TOGGLE_ACTIVE_PROMPT = gql`
  mutation ToggleActivePrompt($sequenceId: String!, $promptId: String!) {
    toggleActivePrompt(sequenceId: $sequenceId, promptId: $promptId) {
      _id
      createdAt
    }
  }
`;
