import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk';
import { baseServerUrl } from '../config/apiConfig';
import useCallTimer from '../pages/twilio-caller/hooks/useCallTimer';

const TwilioCallContext = createContext();

export const TwilioCallProvider = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const userId = user?._id;
  const [callStatus, setCallStatus] = useState('');
  const [twilioError, setTwilioError] = useState('');
  const [incomingCall, setIncomingCall] = useState(null);
  const [acceptedCall, setAcceptedCall] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false); 
  const [nextCall, setNextCall] = useState('');
  const callingToken = useRef(null);
  const device = useRef(null);
  const callInstance = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { stopTimer, resetTimer } = useCallTimer();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post(`${baseServerUrl}/api/v1/twilio-v1/token`, { identity: userId });
        callingToken.current = response.data.token;
        initializeDevice();
      } catch (error) {
        console.error('Error fetching token:', error);
        // enqueueSnackbar('Failed to fetch token.', { variant: 'error' });
        setCallStatus('Error fetching token');
      }
    };

    const initializeDevice = () => {
      try {
        if (!callingToken.current) {
          throw new Error('Token is missing or invalid');
        }
    
        device.current = new Device(callingToken.current, {
          codecPreferences: ['opus', 'pcmu'],
          tokenRefreshMs: 30000,
        });
    
        device?.current.on('ready', () => {
          console.log('Device is ready');
          setCallStatus('Device is ready');
          setIsRegistered(true);
        });
    
        device?.current.on('error', (error) => {
          console.error('Device error:', error);
          setCallStatus(`Device error: ${error.message}`);
          setTwilioError(error.message);
          // enqueueSnackbar(`Device error: ${error.message}`, { variant: 'error' });
        });
    
        device?.current.on('incoming', (call) => {
          console.log('Incoming call from:', call.parameters.From);
          setIncomingCall(call);
          setCallStatus('Incoming call');
    
          call.on('accept', () => {
            console.log('Call accepted');
            setCallStatus('Call accepted');
            setAcceptedCall(call);
            setIncomingCall(null);
    
            call.on('disconnect', () => {
              console.log('Call disconnected');
              setCallStatus('Call ended');
              setAcceptedCall(null);
            });
          });
    
          call.on('cancel', () => {
            console.log('Call canceled');
            setCallStatus('Call canceled');
            setIncomingCall(null);
          });
        });
    
        device?.current.on('tokenWillExpire', async () => {
          try {
            const response = await axios.post(`${baseServerUrl}/api/v1/twilio-v3/token`, { identity: userId });
            callingToken.current = response.data.token;
            device?.current.updateToken(callingToken.current);
            console.log('Token updated');
          } catch (error) {
            console.error('Error updating token:', error);
            setCallStatus('Error updating token');
            enqueueSnackbar('Error updating token.', { variant: 'error' });
          }
        });
    
        device?.current.register();
      } catch (error) {
        console.error('Error initializing device:', error);
        setCallStatus('Error initializing device');
        enqueueSnackbar('Error initializing device.', { variant: 'error' });
      }
    };
    

    if (userId) {
      fetchToken();
    }

    return () => {
      if (device?.current) {
        device.current.disconnectAll();
        if (isRegistered) {
          device.current.unregister(); 
        }
      }
    };
  }, [userId]);

  const handleCall = async (phoneNumber, userId, leadId, callDialType) => {
    try {
      if (!callingToken.current) {
        enqueueSnackbar('No token available', { variant: 'error' });
        throw new Error('No token available');
      }

      const params = {
        To: phoneNumber,
        From: '+19033286481',
        userId,
        leadId,
        callDialType: callDialType || '',
      };

      setNextCall('');
      if (device?.current) {
        callInstance.current = await device.current.connect({ params });

        callInstance.current.on('accept', () => {
          console.log('Call accepted');
          setCallStatus('Call accepted');
        });

        callInstance.current.on('ringing', () => {
          console.log('Ringing...');
          setCallStatus('Ringing...');
        });

        callInstance.current.on('answered', () => {
          console.log('Call answered');
          setCallStatus('Call answered');
        });

        callInstance.current.on('connect', () => {
          console.log('Connected');
          setCallStatus('Connected');
        });

        callInstance.current.on('disconnect', () => {
          console.log('Call ended');
          setCallStatus('Call ended');
          setNextCall('Call ended');
          device?.current.disconnectAll();
          callInstance.current = null;
          resetTimer();
        });

        callInstance.current.on('cancel', () => {
          console.log('Call cancelled');
          setCallStatus('Call cancelled');
          setNextCall('Call canceled');
        });

        console.log('Device state after connect:', device?.current);
        console.log('Call instance state after connect:', callInstance?.current);
      } else {
        throw new Error('Unable to make call');
      }
    } catch (error) {
      console.error('Error making call:', error);
      setCallStatus(`Error making call: ${error.message}`);
      enqueueSnackbar(`Error making call: ${error.message}`, { variant: 'error' });
    }
  };

  const handleEndCall = () => {
    if (callInstance.current) {
      callInstance.current.disconnect();
      setCallStatus('Call ended');
    }
  };

  return (
    <TwilioCallContext.Provider
      value={{
        device: device?.current,
        callStatus,
        callingToken,
        callInstance,
        incomingCall,
        acceptedCall,
        twilioError,
        nextCall,
        setNextCall,
        handleCall,
        handleEndCall,
        setCallStatus,
        setIncomingCall,
        setAcceptedCall,
      }}
    >
      {children}
    </TwilioCallContext.Provider>
  );
};

export const useTwilioCall = () => useContext(TwilioCallContext);
