import { gql } from '@apollo/client';

export const CREATE_PITCH = gql`
  mutation createPitch($input: PitchInput!) {
    createPitch(input: $input) {
      _id
      title
      createdAt
      jobTitle
      notes
      subject
    }
  }
`;

export const UPDATE_PITCH = gql`
  mutation updatePitch($input: PitchInput!, $_id: String!) {
    updatePitch(input: $input, _id: $_id) {
      _id
      title
      createdAt
      jobTitle
      notes
      subject
    }
  }
`;

export const DELETE_PITCH = gql`
  mutation DeletePitch($id: String!) {
    deletePitch(_id: $id) {
      _id
    }
  }
`;
