import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Button, Container, Divider, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
// components
// mock
import { useNavigate } from 'react-router-dom';
import TestingCamp from '../sections/@dashboard/learning/TestingCamp';
import TrainingCamp from '../sections/@dashboard/learning/TrainingCamp';
import { LABELS } from '../utils/constants';
import { a11yProps } from "../utils/helperFunctions";

// ---------------------------------------------------------------------- 

export default function LearningPage() {
    const [value, setValue] = useState(0);
    const [activeChat, setActiveChat] = useState(null);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/">
            Sequence
        </Link>,
        <Typography key="3" color="text.primary">
            Learning
        </Typography>,
    ];

    const handleEditChat = useCallback((chat) => {
        setActiveChat(chat);
        setValue(1);
    }, []);

    return (
        <>
            <Helmet>
                <title> Sequence: Learning | Sales App </title>
            </Helmet>

            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction="column" spacing={0} alignItems="flex-start">
                            <Typography variant="h3">{LABELS.LearningCampus}</Typography>
                            <Typography variant="caption">{LABELS.LearningCampusDescription}</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Stack direction='row' justifyContent="space-between" alignItems="center">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Training Camp" {...a11yProps(0)} />
                                <Tab label="Testing Camp" {...a11yProps(1)} />
                            </Tabs>
                            <Button variant='outlined' onClick={()=>navigate('/sequence/training/history')}>
                                Training History
                            </Button>
                        </Stack>
                     
                        <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{display: value === 0 ? 'block' : 'none'}}>
                        <TrainingCamp value={value} handleEditChat={handleEditChat}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{display: value === 1 ? 'block' : 'none' }}>
                        <TestingCamp activeChat={activeChat} setActiveChat={setActiveChat} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
