import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// @mui
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  LinearProgress,
  NativeSelect,
  TextField,
  Tooltip,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
// components
import { useLazyQuery, useMutation } from '@apollo/client';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { useDropzone } from 'react-dropzone';
import Editor from '../../../../components/editor';
import Iconify from '../../../../components/iconify';
import { SEND_EMAIL } from '../../../../graphql/queries/email';
import { createParams, filePath, s3 } from '../../../../utils/s3';

// hooks
import { GET_ALL_PITCH } from '../../../../graphql/queries/pitch';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useResponsive } from '../../../../hooks/use-responsive';
import SelectAssetsDialog from '../../assets/SelectAssetsDialog';
import { isValidEmail } from '../../../../utils/helperFunctions';

// ----------------------------------------------------------------------

const ZINDEX = 1998;

const POSITION = 20;

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '7px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 1,
}));

export default function AutoMailerCompose({
  onCloseCompose,
  defaultRecipients,
  handleCloseInteraction,
  activeLead,
  contact,
  activeLeadTemplate,
  setNextEmail,
  sequenceId,
  onViewPrompt,
  onRegenerateTemplate
}) {
  const smUp = useResponsive('up', 'sm');

  const [progress, setProgress] = useState(0);

  const [openSelectAsset, setSelectAsset] = useState(false);

  const [pitches, setPitches] = useState([]);

  const [getPitches, { data }] = useLazyQuery(GET_ALL_PITCH);

  const [sendEmail, { data: emailData, loading: emailLoading }] = useMutation(SEND_EMAIL);

  const { enqueueSnackbar } = useSnackbar();

  const [message, setMessage] = useState(activeLeadTemplate?.template?.notes || '');

  const [subject, setSubject] = useState(activeLeadTemplate?.template?.subject || '');

  const [selectedPitch, setSelectedPitch] = useState('');

  const [recipients, setRecipients] = useState(defaultRecipients || []);

  console.log({ recipients });

  const [attachments, setAttachments] = useState([]);

  const handleAddToAttachments = useCallback(
    (file) => {
      if (!attachments.includes(file)) setAttachments((chips) => [...chips, file]);
    },
    [attachments]
  );

  const handleDelete = (chipToDelete) => () => {
    setAttachments((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleClose = useCallback(() => {
    setSelectAsset(false);
  }, []);

  const handleOpen = useCallback(() => {
    setSelectAsset(true);
  }, []);

  const replaceTagsInPitchFromContactAndActiveLead = useCallback(
    (pitch) => {
      const combined = { ...contact, ...activeLead };
      return pitch.replace(/{{(.*?)}}/g, (match, p1) => combined[p1] || match);
    },
    [activeLead, contact]
  );

  const fullScreen = useBoolean();

  const handleChangeMessage = useCallback((value) => {
    setMessage(value);
  }, []);

  const handleChangeSubject = useCallback((event) => {
    setSubject(event.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      if (recipients.length < 1) {
        enqueueSnackbar('Please add at least one recipient to send the email.', {
          variant: 'error',
          autoHideDuration: 2000,
        });
        return;
      }

      const data = {
        recipients,
        subject,
        message,
        attachments,
        lead: activeLead._id,
        coreSequence: sequenceId,
        isAutoMailer: true,
      };

      const emailData = await sendEmail({ variables: { input: data } });
      if (emailData) {
        enqueueSnackbar('Email sent successfully', { variant: 'success' });
        setNextEmail(emailData);
        handleCloseInteraction({
          emailSent: 'Yes',
          emailPersonalized: 'Yes',
        });
        onCloseCompose();
      }
    } catch (error) {
      enqueueSnackbar('Failed to send the email. Please try again later.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('Error sending email:', error);
    } finally {
      console.log("cleanup...");
    }
  }, [recipients, subject, message, attachments, sendEmail]);

  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];

    const params = createParams(file);

    const command = new PutObjectCommand(params);

    setProgress(10);

    try {
      const data = await s3.send(command, {
        onUploadProgress: (progressEvent) => {
          const { loaded } = progressEvent;
          const { total } = progressEvent;
          const percentage = Math.round((loaded / total) * 100);
          setProgress(percentage);
        },
      });

      if (params.Key && data.$metadata.httpStatusCode === 200) {
        setAttachments((chips) => [...chips, filePath(params.Key)]);
        setProgress(0);
      }
    } catch (error) {
      enqueueSnackbar('Error uploading file', { variant: 'error' });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleButtonClick = () => {
    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleChange = useCallback(
    (event) => {
      setSelectedPitch(event.target.value);
      const pitch = pitches.find((p) => p._id === event.target.value);
      if (pitch) {
        const fullMessage = pitch.notes;
        setMessage(replaceTagsInPitchFromContactAndActiveLead(fullMessage));
        setSubject(replaceTagsInPitchFromContactAndActiveLead(pitch.subject));
      }
    },
    [pitches, replaceTagsInPitchFromContactAndActiveLead]
  );

  useEffect(() => {
    if (defaultRecipients?.length > 0) {
      setRecipients(defaultRecipients);
    }
  }, [defaultRecipients]);

  useEffect(() => {
    if (defaultRecipients) {
      getPitches();
    }
  }, [defaultRecipients, getPitches]);

  useEffect(() => {
    if (data?.getPitch) {
      setPitches(data?.getPitch);
    }
  }, [data?.getPitch]);

  useEffect(() => {
    if (activeLeadTemplate) {
      setSubject(activeLeadTemplate?.template?.subject);
      setMessage(activeLeadTemplate?.template?.notes);
    }
  }, [activeLeadTemplate]);

  return (
    <>
      <Portal>
        {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}

        <Paper
          sx={{
            right: 0,
            bottom: 0,
            borderRadius: 2,
            display: 'flex',
            position: 'fixed',
            zIndex: ZINDEX + 1,
            m: `${POSITION}px`,
            overflow: 'hidden',
            flexDirection: 'column',
            boxShadow: (theme) => theme.customShadows.dropdown,
            width: '47.5%',
            ...(fullScreen.value && {
              m: 0,
              right: POSITION / 2,
              bottom: POSITION / 2,
              width: `calc(100% - ${POSITION}px)`,
              height: `calc(100% - ${POSITION}px)`,
            }),
          }}
        >
          {/* <Stack
            direction="row"
            alignItems="center"
            sx={{
              bgcolor: 'background.neutral',
              p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              New Message
            </Typography>

            <IconButton onClick={fullScreen.onToggle}>
              <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
            </IconButton>
            <StyledTooltip title="Send Message" arrow PopperProps={{ sx: { zIndex: 67300 } }}>
              <IconButton
                onClick={onSubmit}
                disabled={emailLoading || recipients.length < 1}
                sx={{
                  color: emailLoading ? 'grey.500' : recipients.length > 0 ? 'primary.main' : 'default',
                  '&:hover': {
                    color: emailLoading ? 'grey.400' : recipients.length > 0 ? 'primary.dark' : 'default',
                  },
                }}
              >
                {emailLoading ? <CircularProgress size={24} /> : <Iconify icon="mdi:send" />}
              </IconButton>
            </StyledTooltip>
          </Stack> */}

<Stack
  direction="row"
  alignItems="center"
  sx={{
    bgcolor: 'background.neutral',
    p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
  }}
>
  <Typography variant="h6" sx={{ flexGrow: 1 }}>
    New Message
  </Typography>

  <StyledTooltip title="View Prompt" arrow PopperProps={{ sx: { zIndex: 67300 } }}>
    <IconButton onClick={onViewPrompt}>
      <Iconify icon="mdi:eye" />
    </IconButton>
  </StyledTooltip>

  <StyledTooltip title="Regenerate Template" arrow PopperProps={{ sx: { zIndex: 67300 } }}>
    <IconButton onClick={onRegenerateTemplate}> 
      <Iconify icon="mdi:autorenew" />
    </IconButton>
  </StyledTooltip>

  {/* Full Screen Toggle */}
  <IconButton onClick={fullScreen.onToggle}>
    <Iconify icon={fullScreen ? 'eva:collapse-fill' : 'eva:expand-fill'} />
  </IconButton>

  {/* Send Message Button */}
  <StyledTooltip title="Send Message" arrow PopperProps={{ sx: { zIndex: 67300 } }}>
    <IconButton
      onClick={onSubmit}
      disabled={emailLoading || recipients.length < 1}
      sx={{
        color: emailLoading ? 'grey.500' : recipients.length > 0 ? 'primary.main' : 'default',
        '&:hover': {
          color: emailLoading ? 'grey.400' : recipients.length > 0 ? 'primary.dark' : 'default',
        },
      }}
    >
      {emailLoading ? <CircularProgress size={24} /> : <Iconify icon="mdi:send" />}
    </IconButton>
  </StyledTooltip>
</Stack>


          <Autocomplete
            multiple
            id="tags-filled"
            options={[]}
            freeSolo
            value={recipients}
            onChange={(event, newValue) => {
              const validEmails = newValue.filter((email) => isValidEmail(email));
              setRecipients(validEmails);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                type="email"
                label=""
                placeholder="Enter a valid email, press Enter to add"
                sx={{
                  fieldset: {
                    border: 'none',
                    borderRadius: 'none',
                  },
                }}
              />
            )}
          />

          <InputBase
            placeholder="Subject"
            value={subject}
            onChange={handleChangeSubject}
            sx={{
              px: 2,
              height: 48,
              borderBottom: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '45vh', overflowY: 'auto' }}>
            <Stack
              spacing={2}
              direction="column"
              flexGrow={1}
              sx={{ p: 2, maxWidth: !fullScreen.value ? '100%' : '100%' }}
            >
              <Editor
                simple
                id="compose-mail"
                value={message}
                onChange={handleChangeMessage}
                placeholder="Type a message"
                sx={{
                  '& .ql-editor': {},
                  ...(fullScreen.value && {
                    height: 1,
                    '& .quill': {
                      height: 1,
                    },
                    '& .ql-editor': {
                      maxHeight: 'unset',
                    },
                  }),
                }}
              />
              <Stack direction="column" alignItems="center" spacing={1}>
                <div {...getRootProps()} style={{ cursor: 'pointer' }}>
                  <input {...getInputProps()} id="file-input" />
                  <p>Drag & drop a file here, or click to select a file</p>
                </div>
              </Stack>
              {progress > 0 && <LinearProgress variant="determinate" value={progress} />}

              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  listStyle: 'none',
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {attachments.map((data) => (
                  <ListItem key={data}>
                    <Chip
                      label={data}
                      onDelete={handleDelete(data)}
                      sx={{ maxWidth: 500, textOverflow: 'ellipsis', overflow: 'hidden' }}
                    />
                  </ListItem>
                ))}
              </Paper>

              <Stack direction="row" alignItems="center">
                <Stack direction="row" alignItems="center" flexGrow={1}>
                  <IconButton onClick={handleButtonClick}>
                    <Iconify icon="solar:gallery-add-bold" />
                  </IconButton>
                  <IconButton onClick={handleButtonClick}>
                    <Iconify icon="eva:attach-2-fill" />
                  </IconButton>
                </Stack>

                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={emailLoading}
                  disabled={emailLoading || recipients.length < 1}
                  onClick={onSubmit}
                  endIcon={<Iconify icon="iconamoon:send-fill" />}
                >
                  Send
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Portal>
      <SelectAssetsDialog
        openSelectAsset={openSelectAsset}
        handleAddToAttachments={handleAddToAttachments}
        handleClose={handleClose}
      />
    </>
  );
}

AutoMailerCompose.propTypes = {
  onCloseCompose: PropTypes.func.isRequired,
  defaultRecipients: PropTypes.array,
  handleCloseInteraction: PropTypes.func,
  activeLead: PropTypes.object,
  contact: PropTypes.object,
  setNextEmail: PropTypes.func,
  sequenceId: PropTypes.string,
  onViewPrompt: PropTypes.func,
  onRegenerateTemplate: PropTypes.func,
};
