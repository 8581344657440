import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { CallToAction } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  List,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { io } from 'socket.io-client';
import { useMutation } from '@apollo/client';
// hooks
import { useDispatch, useSelector } from 'react-redux';
import { NavItem } from '../../../components/nav-section/NavSection';
import useResponsive from '../../../hooks/useResponsive';
// components
import NavSection from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
//
import Iconify from '../../../components/iconify/Iconify';
import { isAdmin } from '../../../utils/helperFunctions';
import Timer from '../timer';
import { adminOnly, navConfig, settingsOnly } from './config';
import {
  addActiveAgent,
  addLeadToQueue,
  addToConnectedCall,
  removeActiveAgent,
  removeLeadFromQueue,
  setActiveLead,
} from '../../../redux/twilio.slice';
import useTwilio from '../../../hooks/useTwilio';
import { truncateText } from '../../../utils/textHelper';
import { LOGOUT_QUERY } from '../../../graphql/queries/auth';
import { POOL_TIMER_LOG } from '../../../graphql/mutations/log';
import CoreSequenceMenu from '../../../sections/@dashboard/core-sequence/CoreSequenceMenu';
import useCrossAppLogoutListener from '../../../hooks/useCrossAppLogoutListener';
import useLogout from '../../../hooks/use-logout';
import { socket as socket2 } from '../../../services/socket';
import useCheckAuth from '../../../hooks/use-check-auth-status';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  useCrossAppLogoutListener();
  const connectionURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';
  const socket = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const agentId = user?.twilioAgent?._id;
  const [lead, setLead] = useState(null);
  const [showOptOut, setShowOptOut] = useState(false);
  const [poolTimerLog, { data }] = useMutation(POOL_TIMER_LOG);
  const { activeLead, activeAgents, queuedLeads, connectedCalls } = useSelector((state) => state.twilio);
  const { device, callStatus, setCallStatus, incomingCall, acceptedCall, setAcceptedCall, setIncomingCall } = useTwilio(
    agentId,
    lead,
    socket
  );

  const [isInCallPool, setIsInCallPool] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [callSid, setCallSid] = useState(null);

  const firstName = truncateText(lead?.firstName || activeLead?.firstName, 10) || '';
  const lastName = truncateText(lead?.lastName || activeLead?.lastName, 10) || '';
  const company = truncateText(lead?.company || activeLead?.company, 10) || '';

  // ----------------------------------------------------------------------
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const handleLogout = useLogout(socket2, user);
  const [userAvailability, setUserAvailability] = useState('AVAILABLE');
  const [userInChat, setUserInChat] = useState(0);
  const isDesktop = useResponsive('up', 'lg');
  const adminNav = [...navConfig, ...adminOnly];
  const { enqueueSnackbar } = useSnackbar();
  useCheckAuth(socket2, user);

  //
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // ------------------------------Twilio Start----------------------------------------
  useEffect(() => {
    if (connectionURL) {
      socket.current = io(connectionURL, { path: '/socket.io', transports: ['websocket', 'polling'] });

      socket.current.on('connect', () => {
        console.log('Connected to socket');
      });

      socket.current.on('disconnect', () => {
        console.log('Disconnected from socket');
        if (agentId) {
          socket.current.emit('leaveChatPool', agentId);
          dispatch(removeActiveAgent(agentId));
          setIsInCallPool(false);
          setCallSid(null);
          dispatch(setActiveLead(null));
        }
      });

      socket.current.on('userCount', (users) => {
        setUserInChat(users);
      });

      socket.current.on('setCallSid', (data) => {
        setCallSid(data.callSid);
      });

      socket.current.on('incomingCall', (data) => {
        console.log('Incoming call:', data);
        socket.current.emit('callAccepted', { callSid: data?.callSid, agentId, leadId: data?.lead?._id });
        setLead(data.lead);
        if (data?.callSid) {
          setCallSid(data?.callSid);
        }
        dispatch(setActiveLead(data.lead));
      });

      socket.current.on('activeAgentsUpdate', (agent) => {
        dispatch(addActiveAgent(agent));
      });

      socket.current.on('removeAgentUpdate', (agentId) => {
        dispatch(removeActiveAgent(agentId));
      });

      socket.current.on('queueLead', (lead) => {
        console.log('Adding this lead to queue...', lead);
        dispatch(addLeadToQueue(lead));
      });

      socket.current.on('callConnected', (data) => {
        dispatch(addToConnectedCall(data));
      });

      socket.current.on('removeLeadFromQueue', (leadId) => {
        dispatch(removeLeadFromQueue(leadId));
      });

      return () => {
        socket.current.off('incomingCall');
        socket.current.off('queueLead');
        socket.current.off('removeLeadFromQueue');
        socket.current.off('activeAgentsUpdate');
        socket.current.off('activeAgentsUpdate');
        setIsInCallPool(false);
        socket.current.disconnect();
      };
    }

    return () => {};
  }, [connectionURL, device, agentId]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isInCallPool && startTime) {
        // Calculate the elapsed time before leaving the page
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        const startDate = new Date(startTime).toISOString();
        const endDate = new Date().toISOString();

        // Store the data in localStorage before unloading the page
        localStorage.setItem(
          'timeSpentData',
          JSON.stringify({
            timeSpent: elapsedTime,
            startDate,
            endDate,
          })
        );

        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isInCallPool, startTime]);

  useEffect(() => {
    const storedData = localStorage.getItem('timeSpentData');
    if (storedData) {
      const { timeSpent, startDate, endDate } = JSON.parse(storedData);

      // Send the saved data to your server
      const sendSavedData = async () => {
        try {
          await poolTimerLog({
            variables: {
              input: {
                timeSpent,
                startDate,
                endDate,
              },
            },
          });
          // After successfully sending the data, clear the localStorage
          localStorage.removeItem('timeSpentData');
        } catch (error) {
          console.error('Error logging time after page reload:', error);
        }
      };

      sendSavedData();
    }
  }, []);

  const joinCallPool = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleJoinSequence = (sequenceId) => {
    if (socket.current) {
      if (sequenceId) {
        socket.current.emit('joinChatPool', agentId, sequenceId);
      } else {
        enqueueSnackbar('The sequence is currently unavailable for joining.', { variant: 'error' });
        return;
      }
      setIsInCallPool(true);
      setStartTime(Date.now());
    }
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const leaveCallPool = async () => {
    if (socket.current) {
      socket.current.emit('leaveChatPool', agentId);
    setIsInCallPool(false);

    // Calculate elapsed time in seconds
    const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
    setTotalTimeSpent((prev) => prev + elapsedTime);

    // Prepare dates for logging
    const startDate = new Date(startTime).toISOString();
    const endDate = new Date().toISOString();
    try {
      await poolTimerLog({
        variables: {
          input: {
            timeSpent: elapsedTime,
            startDate,
            endDate,
          },
        },
      });
    } catch (error) {
      console.error('Error logging time:', error);
    }
    }
    dispatch(removeActiveAgent(agentId));
    dispatch(setActiveLead(null));
    // handleRejectIncomingCall(true);
    handleEndCall();
  };

  const handleAcceptIncomingCall = () => {
    if (incomingCall) {
      const callSid_ = incomingCall.parameters.CallSid;
      incomingCall.accept();
      setAcceptedCall(incomingCall);
      setCallStatus('Incoming call accepted');
      setIncomingCall(null);
      socket.current.emit('callAccepted', { callSid: callSid_, agentId, leadId: lead?._id });
    }
  };

  const handleRejectIncomingCall = (isLeavePool) => {
    if (incomingCall) {
      const callSid_ = incomingCall.parameters.CallSid;
      incomingCall.reject();
      setCallStatus('Incoming call rejected');
      setIncomingCall(null);
      socket.current.emit('callRejected', {
        callSid: callSid_,
        agentId,
        leadId: lead?._id,
        isLeavePool: isLeavePool || false,
      });
    }
  };

  const handleEndCall = (answeredBy, optOut = false) => {
    if (acceptedCall) {
      acceptedCall.disconnect();
      setCallStatus('Call ended');
      setAcceptedCall(null);
      dispatch(setActiveLead(null));
      const callSid_ = acceptedCall?.parameters?.CallSid;
      socket.current.emit('callEnded', { callSid, answeredBy, callSid2: callSid_, optOut });
      setCallSid(null);
    }
  };

  const handleOptOut = () => {
    handleEndCall('human', true);
  };

  const viewCallPoolDetails = () => {
    navigate(`/sequence/pool-details`);
  };

  // -------------------------Twilio End---------------------------------------------

  const getRoleName = (role) => {
    switch (role) {
      case 'SUPERADMIN':
        return 'Super Admin';
      case 'ADMIN':
        return 'Admin';
      case 'ADMIN_AGENT':
        return 'Admin, Agent';
      case 'AGENT_ADMIN':
        return 'Agent, Admin';
      case 'SUPERADMIN_AGENT':
        return 'Super Admin, Agent';
      default:
        return 'Agent';
    }
  };

  const gotoProfile = () => {
    navigate('/sequence/profile');
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!user?.organization?.name) {
      handleLogout();
    }
  }, [user, handleLogout]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      {isMenuOpen && (
        <CoreSequenceMenu
          onJoinSequence={handleJoinSequence}
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
        />
      )}
      {acceptedCall && (
        <Paper sx={{ p: 3, mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }} elevation={2}>
          <Row>
            {' '}
            <Typography variant="h6" sx={{ fontSize: '0.8rem', color: 'gray' }}>
              {`${firstName} ${lastName} `}
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '0.1rem', ml: 0.6, color: '#282833' }}>
              ✈from✈
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '0.8rem', ml: 0.6, color: 'gray' }}>
              {` ${company}`}
            </Typography>
          </Row>
          <Divider />
          <CustomText onClick={viewCallPoolDetails}>View more Details</CustomText>
          <Divider />
          <Button
            onClick={() => handleEndCall('human')}
            variant="contained"
            color="success"
            sx={{ color: '#FFF', bgcolor: '#0b8c18' }}
          >
            End Call -Human
          </Button>
          <Button onClick={() => handleEndCall('machine')} variant="contained" color="error">
            End Call -Voice Mail
          </Button>
          <Divider />
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={showOptOut}
                  onChange={(e) => setShowOptOut(e.target.checked)}
                  color="warning"
                />
              }
              label={
                <Typography sx={{ fontSize: '0.8rem', ml: 0.6, color: 'gray' }}>
                  {showOptOut ? 'Opt-Out Enabled' : 'Opt-Out Disabled'}
                </Typography>
              }
            />
          </FormControl>

          {/* Conditionally Render the Opt-Out Button */}
          {showOptOut && (
            <Button
              onClick={() => handleOptOut()}
              variant="contained"
              color="warning"
              sx={{ color: '#FFF', bgcolor: '#baa809' }}
            >
              Opt-Out Prospect
            </Button>
          )}
        </Paper>
      )}
      {/* {acceptedCall && (
            <Button onClick={handleEndCall} variant="contained" color="error">
              End Call
            </Button>
          )}
      {incomingCall && (
        <Stack direction="row" spacing={2}>
          <>
            <Button startIcon={<Call />} onClick={handleAcceptIncomingCall} variant="contained" color="success">
              Answer
            </Button>
            <Button
              startIcon={<CallEndOutlined />}
              onClick={handleRejectIncomingCall}
              variant="contained"
              color="error"
            >
              Reject
            </Button>
          </>
        </Stack>
      )} */}

      <Stack direction="column" alignItems="flex-start" sx={{ pt: 1, px: 2.5, pb: 2, width: '100%' }} spacing={1}>
        <Typography variant="caption" noWrap>
          Engage with clients on the Go: {userAvailability}
        </Typography>
        <Timer isRunning={isInCallPool} setIsRunning={setIsInCallPool} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Button
            startIcon={<CallToAction />}
            onClick={isInCallPool ? leaveCallPool : joinCallPool}
            variant="contained"
            color={isInCallPool ? 'error' : 'primary'}
          >
            {isInCallPool ? 'Leave Call Pool' : 'Join Call Pool'}
          </Button>
          <Typography variant="h6" noWrap>
            {activeAgents?.length}
            {/* {userInChat} */}
          </Typography>
        </Stack>
      </Stack>
      {/* 
      {sessionError && (
        <Alert severity="error">
          <pre>{JSON.stringify(sessionError)}</pre>
        </Alert>
      )} */}
      <Divider />

      <NavSection data={isAdmin(user?.role) ? adminNav : navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Divider />
      <Box sx={{ pt: 1, px: 2.5, pb: 2, width: '100%' }}>
        <Stack spacing={1} direction="row">
          <Avatar alt={user?.firstName} src={user?.avatar} sx={{ width: 56, height: 56 }} />
          <Stack spacing={0} sx={{ overflow: 'hidden', maxWidth: 160 }}>
            <Link underline="hover" color="inherit" href="#" onClick={gotoProfile}>
              <Typography variant="subtitle1" noWrap>
                {user?.firstName} {user?.lastName}
              </Typography>
            </Link>
            <Typography variant="caption" noWrap>
              {`${user?.organization?.name} (${getRoleName(user?.role)})`}
            </Typography>
          </Stack>
          <IconButton onClick={handleLogout} disableFocusRipple disableRipple>
            <Iconify icon="mdi-light:logout" />
          </IconButton>
        </Stack>
      </Box>
      {/* {user?.twilioAgent?._id && (
        <List disablePadding sx={{ p: 1 }}>
          <NavItem item={twilioOnly} />
        </List>
      )} */}
      {isAdmin(user?.role) && (
        <List disablePadding sx={{ p: 1 }}>
          <NavItem item={settingsOnly} />
        </List>
      )}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

const CustomText = styled(Typography)(() => ({
  fontSize: '1rem',
  color: '#282833',
  fontWeight: 500,
  cursor: 'pointer',
  '&:hover': {
    color: '#50589F',
    textDecoration: 'underline',
  },
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
}));
