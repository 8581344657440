import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Alert, Chip, Stack, TextField, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { GET_ALL_AGENTS } from '../../../graphql/queries/user';
import { ADD_AGENT_TO_SEQUENCE } from '../../../graphql/mutations/core.sequence';
import { isAgent } from '../../../utils/helperFunctions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function AddAgentToSequence({ open, handleClose, sequence, refetch }) {
  const { handleSubmit, register, reset } = useForm();
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [availableAgents, setAvailableAgents] = useState([]);
  const [inputAgent, setInputAgent] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading: agentLoading, error } = useQuery(GET_ALL_AGENTS);
  const [updateSequenceMembers, { loading: updating, error: updateError }] = useMutation(ADD_AGENT_TO_SEQUENCE);

  
  // Fetch and filter agents based on their roles
  useEffect(() => {
    if (data?.getAllAgents) {
      const myAgents = data.getAllAgents.filter((user) => isAgent(user.role));
      setAvailableAgents(myAgents);
    }
  }, [data]);

  // Handle selecting an agent
  const handleSelectAgent = (e) => {
    const selectedAgentId = e.target.value;
    const agentExists = selectedAgents.some((agent) => agent._id === selectedAgentId);

    if (!agentExists) {
      const selectedAgent = availableAgents.find((agent) => agent._id === selectedAgentId);
      setSelectedAgents((prev) => [...prev, selectedAgent]);
    }
  };

  // Handle removing a selected agent
  const handleRemoveAgent = (agentToRemove) => {
    setSelectedAgents((prev) => prev.filter((agent) => agent._id !== agentToRemove._id));
  };


  const onSubmit = async () => {
    const agentIds = selectedAgents.map((agent) => agent._id);
    await updateSequenceMembers({
      variables: {
        input: {
        sequenceId: sequence._id,
        members: agentIds,
        }
      },
    });
    enqueueSnackbar('Agents added successfully!', { variant: 'success' });
    refetch(); 
    handleDialogClose();
  };

  const handleDialogClose = () => {
    reset();
    setSelectedAgents([]);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog onClose={handleDialogClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Agents to <span style={{color: '#212B36'}}>{sequence?.title}</span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ width: { sm: '100%', md: '500px' } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {updateError && <Alert severity="error">{updateError?.message}</Alert>}

              {/* Agent selection dropdown */}
              <TextField
                select
                label="Select Agents"
                value={inputAgent}
                onChange={handleSelectAgent}
                disabled={agentLoading}
                fullWidth
                size="small"
              >
                <MenuItem value="" disabled>Select an agent</MenuItem>
                {availableAgents?.map((agent) => (
                  <MenuItem key={agent._id} value={agent._id}>
                    {agent.firstName} {agent.lastName}
                  </MenuItem>
                ))}
              </TextField>

              {/* Display selected agents as chips */}
              <Box>
                {selectedAgents?.map((agent) => (
                  <Chip
                    key={agent._id}
                    label={`${agent.firstName} ${agent.lastName}`}
                    onDelete={() => handleRemoveAgent(agent)}
                    sx={{ margin: '4px' }}
                  />
                ))}
              </Box>

              <LoadingButton loading={updating} type="submit" variant="contained">
                Add Agents
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

AddAgentToSequence.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AddAgentToSequence;
