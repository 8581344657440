import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import AppBoxIcon from '../../../components/svg-icons/app-header/AppBoxIcon';
import AppBoxPopover from './AppMenuPopover';
//
// import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

// const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: 0,
    padding: theme.spacing(0, 5),
  },
}));

const AppBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 50,
  width: 50,
  minWidth: 50,
  border: '1px solid #E0E0E9',
  cursor: 'pointer',
  borderRadius: 16,
  transition: 'none',
  '&:hover': {
      background: '#F4F4FA',
      border: '1px solid #B8B8CD',
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
};

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          p={2}
          pr={0}
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>

          <AppBox onClick={handleClick}>
              <AppBoxIcon />
          </AppBox>
        </Stack>
        <AppBoxPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
      />
        {/* <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}>
          <NotificationsPopover />
          <AccountPopover />
        </Stack> */}
      </StyledToolbar>
    </StyledRoot>
  );
}
