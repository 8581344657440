import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// mock
import LeaderboardListHead from './LeaderboardListHead';
import LeaderboardListToolbar from './LeaderboardListToolbar';


const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'callLogCount', label: <img src='/assets/icons/ic_call.svg' alt='Calls Made'/>, alignRight: false },
  { id: 'smsLogCount', label: <img src='/assets/icons/ic_sms_icon.svg' alt='Emails Sent'/>, alignRight: false },
  { id: 'emailLogCount', label:  <img src='/assets/icons/ic_email.svg' alt='SMS sent'/>, alignRight: false },
  { id: 'linkedinMessageLogCount', label: <img src='/assets/icons/ic_linkedin_message.svg' alt='Linkedin Message'/>, alignRight: false },
  { id: 'linkedinConnectLogCount', label: <img src='/assets/icons/ic_linkedin_request_icon.svg' alt='Linkedin Connect'/>, alignRight: false },
  { id: 'totalLogCount', label: 'Total Logs', alignRight: false },
  { id: 'timeLogged', label: 'Time Logged', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LeaderboardTable({ data }) {
  const [userList, setUserList] = useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    if (data) {
      setUserList(data);
    }
  }, [data]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Card>
        <LeaderboardListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <LeaderboardListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { name, emailLogCount, callLogCount,smsLogCount,totalLogCount, linkedinMessageLogCount, linkedinConnectLogCount, timeLogged } = row;
                  const selectedUser = selected.indexOf(name) !== -1;

                  return (
                    <TableRow hover key={name} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell component="th" scope="row">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{callLogCount}</TableCell>
                      <TableCell align="left">{smsLogCount}</TableCell>
                      <TableCell align="left">{emailLogCount}</TableCell>
                      <TableCell align="left">{linkedinMessageLogCount}</TableCell>
                      <TableCell align="left">{linkedinConnectLogCount}</TableCell>
                      <TableCell align="left">
                        <Label color={(totalLogCount < 100 && 'error') || 'success'}>{totalLogCount}</Label>
                      </TableCell>
                      <TableCell align="left">{timeLogged}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Grid>
  );
}
