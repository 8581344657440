import { filter } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

// @mui
import {
  Card,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useMutation } from '@apollo/client';
// components
import { useSnackbar } from 'notistack';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { fDate } from '../../../utils/formatTime';
import TemplateListHead from './TemplateListHead';
import TemplateListToolbar from './TemplateListToolbar';
import { TEMPLATES_TYPE } from '../../../utils/constants';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import { DELETE_PITCH } from '../../../graphql/mutations/pitch';

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'jobTitle', label: 'Job Title', alignRight: false },
  {id: 'sms', label: 'SMS', alignRight: false},
  { id: 'notes', label: 'Notes', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function formatPhoneNumber(phoneNumber) {
  // Remove non-numeric characters and spaces for phone number
  const formattedNumber = phoneNumber.replace(/[^0-9]/g, '');
  if (formattedNumber.length) {
    return formattedNumber;
  }
  return phoneNumber;
}


function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (contact) => contact?.company?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.firstName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.lastName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.emailAddress?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || formatPhoneNumber(contact?.prospectPhoneNumber)?.indexOf(formatPhoneNumber(query.toLowerCase())) !== -1 || contact?.prospectLocation?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || contact?.prospectDesignation?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);

}

function TemplatesTableView({ pitches, handleUpdatePitch, type }) {
  const { enqueueSnackbar } = useSnackbar();
  const [allPitchs, setAllPitchs] = useState([]);
  const [open, setOpen] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [activePitch, setActivePitch] = useState(null);

  const [deletePitch] = useMutation(DELETE_PITCH);

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setOpen(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleConfirmDelete = async () => {
    setAllPitchs((prevPitchs) => prevPitchs.filter((pitch) => pitch._id !== activePitch._id));
    setOpenDelete(false); 
    enqueueSnackbar('Template Deleted!', { variant: 'success' });
    try {
      await deletePitch({ variables: { id: activePitch._id } });
    } catch (error) {
      console.error('Delete failed:', error);
    }
    
  };

  useEffect(() => {
    if (pitches?.length > 0) {
      setAllPitchs(pitches);
    }
  }, [pitches]);

  const handleEditCompanyDialogOpen = useCallback(() => {
    handleUpdatePitch(activePitch);
    handleCloseMenu();
  }, [activePitch, handleUpdatePitch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allPitchs.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const filteredPitchs = applySortFilter(allPitchs, getComparator(order, orderBy), filterName);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allPitchs.length) : 0;
  const isNotFound = !filteredPitchs.length && !!filterName;

  return (
    <>
      <Card>
        <TemplateListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={(e) => setFilterName(e.target.value)}
          type={type}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TemplateListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={allPitchs.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                type={type}
              />
              <TableBody>
                {filteredPitchs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const {
                    _id: id,
                    title,
                    sms,
                    createdAt,
                    jobTitle,
                    notes
                  } = row;

                  const selectedLead = selected.indexOf(id) !== -1;

                  return (
                    <TableRow key={id} tabIndex={-1} role="checkbox" selected={selectedLead} sx={{ cursor: 'pointer' }}>
                      <TableCell align="left">{title}</TableCell>
                      <TableCell align="left">{jobTitle}</TableCell>
                      {type === TEMPLATES_TYPE.SMS && (
                        <TableCell align="left">{sms}</TableCell>
                      )}
                     {type === TEMPLATES_TYPE.EMAIL &&  <TableCell align="left">{notes}</TableCell>}
                      <TableCell align="left">{fDate(createdAt)}</TableCell>
                      <TableCell align="right">
                        <Iconify
                          icon="eva:more-vertical-fill"
                          onClick={(event) => {
                            setActivePitch(row);
                            setOpen(event.currentTarget);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={allPitchs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditCompanyDialogOpen}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {openDelete && (
        <ConfirmationModal
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          onConfirm={handleConfirmDelete}
          title="Delete Confirmation"
          description="Are you sure you want to delete this template?"
          subDescription="This action cannot be undone."
        />
      )}
    </>
  );
}

export default TemplatesTableView;