import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useMutation } from '@apollo/client';
import { CREATE_SEQUENCE_PROMPT, UPDATE_PROMPT } from '../../../graphql/mutations/prompt';
import { FormProvider, RHFSwitch, RHFTextField, RHFEditor } from '../../../components/hook-form';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

SequencePromptForm.propTypes = {
  isEdit: PropTypes.bool,
  currentPrompt: PropTypes.object,
  sequenceId: PropTypes.string,
};

export default function SequencePromptForm({ isEdit, currentPrompt, sequenceId }) {
  const { enqueueSnackbar } = useSnackbar();

  const dynamicFieldsExplanation =
    'Placeholders inside double curly brackets {{}} will be replaced by dynamic values. Examples: {{missingCount}}, {{jobTitle}}.';

  const NewPromptSchema = Yup.object().shape({
    name: Yup.string().required('Prompt Name is required'),
    description: Yup.string().required('Prompt Details are required'),
    isActive: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentPrompt?.title || '',
      description: currentPrompt?.prompt || '',
      isActive: currentPrompt?.isActive || false,
    }),
    [currentPrompt]
  );

  const methods = useForm({
    resolver: yupResolver(NewPromptSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const [createPrompt, { loading: creating }] = useMutation(CREATE_SEQUENCE_PROMPT, {
    onCompleted: () => {
      enqueueSnackbar('Prompt created successfully!', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    },
  });

  const [updatePrompt, { loading: updating }] = useMutation(UPDATE_PROMPT, {
    onCompleted: () => {
      enqueueSnackbar('Prompt updated successfully!', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    },
  });

  useEffect(() => {
    if (isEdit && currentPrompt) {
      reset(defaultValues);
    }
  }, [isEdit, currentPrompt, reset, defaultValues]);

  const onSubmit = async (data) => {
    try {
      const input = {
        title: data.name,
        prompt: data.description,
        isActive: data.isActive,
        sequenceId,
      };

      if (isEdit && currentPrompt?._id) {
        await updatePrompt({
          variables: { input, promptId: currentPrompt._id },
        });
      } else {
        await createPrompt({
          variables: { input },
        });
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderDynamicFieldsInfo = () => (
    <Tooltip
      title={
        <div>
          <Typography variant="subtitle2">Dynamic Fields</Typography>
          <Typography variant="body2">{dynamicFieldsExplanation}</Typography>
        </div>
      }
      arrow
    >
      <IconButton sx={{ mb: 1 }}>
        <InfoIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="name" label="Prompt Name" required fullWidth />

              <div>
                <Stack direction="row" alignItems="center">
                  <LabelStyle>Prompt Details</LabelStyle>
                  {renderDynamicFieldsInfo()}
                </Stack>
                <RHFEditor
                  simple
                  name="description"
                  placeholder="Use placeholders like {{clientName}}, {{organizationName}}, or any other custom field."
                />
              </div>

              <div>
                <LabelStyle>Set as Active Prompt</LabelStyle>
                <RHFSwitch name="isActive" label="Active" />
              </div>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={creating || updating || isSubmitting}
            >
              {!isEdit ? 'Create Prompt' : 'Save Changes'}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
