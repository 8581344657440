import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Card,
  CardContent,
  TextField,
  Collapse,
  Paper,
  CircularProgress,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachFile from '@mui/icons-material/AttachFile';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_PITCH } from '../../../graphql/queries/pitch';

const TemplateDrawer = ({ open, onClose, onSelect, selectedTemplates, selectedType }) => {
  const [getPitches, { data: allPitches, loading: loadingAllPitches }] = useLazyQuery(GET_ALL_PITCH);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedTemplate, setExpandedTemplate] = useState(null);

  useEffect(() => {
    if (open) {
      getPitches({ variables: { type: null } });
    }
  }, [open, getPitches]);

  const handleSelectTemplate = (template) => {
    const updatedSelectedTemplates = selectedTemplates.includes(template._id)
      ? selectedTemplates.filter((id) => id !== template._id)
      : [...selectedTemplates, template._id]; 

    onSelect(updatedSelectedTemplates); 
  };

  const handleConfirm = () => {
    onClose();
  };

  const pitchesArray = Array.isArray(allPitches?.getPitch) ? allPitches.getPitch : [];

  const types = ['EMAIL', 'SMS', 'CALL'];
  const filteredTypes = types.filter((type) => type === selectedType);
  
  const currentType = filteredTypes[activeTab] || selectedType;

  const filteredTemplates = pitchesArray
    .filter((template) => currentType === template.type)
    .filter(
      (template) =>
        template.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        template.subject?.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const selected = filteredTemplates.filter((template) => selectedTemplates.includes(template._id));
  const unselected = filteredTemplates.filter((template) => !selectedTemplates.includes(template._id));

  const sortedTemplates = [...selected, ...unselected];

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 400, padding: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Select Templates
        </Typography>

        {/* Tabs for template types */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          sx={{ '& .MuiTab-root': { flex: 1 } }}
        >
          {filteredTypes.map((type, index) => (
            <Tab key={type} label={type} />
          ))}
        </Tabs>

        {/* Search input */}
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search templates..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginTop: 2 }}
        />

        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          Selected: {selectedTemplates.length}
        </Typography>

        {loadingAllPitches ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Template List */}
            <Box sx={{ marginTop: 2, maxHeight: '400px', overflowY: 'auto' }}>
              {sortedTemplates.length === 0 ? (
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  No templates available for this tab.
                </Typography>
              ) : (
                sortedTemplates.map((template) => (
                  <Paper key={template._id} elevation={2} sx={{ marginBottom: 1, border: '1px solid #F4F6F8' }}>
                    <Card
                      sx={{
                        padding: 1,
                        '&:hover': {
                          boxShadow: 1,
                          backgroundColor: '#F4F6F8',
                        },
                        transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
                      }}
                    >
                      <CardContent>
                        <Typography variant="h6">{template.title}</Typography>
                        <Typography variant="subtitle2">{template.subject}</Typography>

                        <Box>
                          <Button
                            onClick={() => setExpandedTemplate(expandedTemplate === template._id ? null : template._id)}
                            endIcon={<ExpandMore />}
                            sx={{ textTransform: 'none', padding: 0 }}
                          >
                            {expandedTemplate === template._id ? 'Hide Preview' : 'Show Preview'}
                          </Button>
                        </Box>
                        <Collapse in={expandedTemplate === template._id}>
                          <Typography
                            variant="body2"
                            sx={{ marginTop: 1 }}
                            dangerouslySetInnerHTML={{ __html: template.notes }}
                          />
                        </Collapse>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedTemplates.includes(template._id)}
                              onChange={() => handleSelectTemplate(template)}
                            />
                          }
                          label="Select"
                        />
                      </CardContent>
                    </Card>
                  </Paper>
                ))
              )}
            </Box>
            {/* Buttons for Cancel and Confirm */}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button variant="outlined" onClick={onClose} sx={{ marginRight: 1 }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirm}
                startIcon={selectedTemplates.length > 0 ? <CheckCircle /> : <AttachFile />}
              >
                {selectedTemplates.length > 0 ? 'Confirm Selection' : 'Attach'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default TemplateDrawer;
