import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  AvatarGroup,
  Avatar,
  Divider,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  styled,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { TOGGLE_FAVORITE_SEQUENCE, TOGGLE_ARCHIVE_SEQUENCE } from '../../../graphql/mutations/core.sequence';

const ArchivedCoreSequenceOverview = ({ allArchivedSequence, refetch }) => {
  const [toggleFavoriteSequence] = useMutation(TOGGLE_FAVORITE_SEQUENCE);
  const [toggleArchiveSequence] = useMutation(TOGGLE_ARCHIVE_SEQUENCE);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddAgent, setOpenAddAgent] = useState(false);
  const [sequences, setSequences] = useState(allArchivedSequence || []);
  const [selectedSequence, setSelectedSequence] = useState(null);
  const [favorites, setFavorites] = useState(new Set());
  const navigate = useNavigate();
  const maxVisibleAvatars = 4;

  const getProgressPercentage = (completed, total) => (total === 0 ? 0 : (completed / total) * 100);

  useEffect(() => {
    setSequences(allArchivedSequence || []);
  }, [allArchivedSequence]);

  const handleManageClick = (event, sequence) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedSequence(sequence);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleArchiveSequence = (e) => {
    e.stopPropagation();

    setSequences((prevSequences) => 
      prevSequences.filter(sequence => sequence._id !== selectedSequence._id)
    );
    enqueueSnackbar(`${selectedSequence?.title} sequence removed from archive`, { variant: 'success' });
    toggleArchiveSequence({ variables: { sequenceId: selectedSequence._id } });

    handleMenuClose();
  };

  const handleViewSequence = (e) => {
    e.stopPropagation();
    navigate('/sequence/sequence-overview');
  };

  const handleClose = () => {
    setOpenAddAgent(false);
    setSelectedSequence(null);
  };

  const toggleFavorite = (e, sequenceId) => {
    e.stopPropagation();
    setFavorites((prev) => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(sequenceId)) {
        newFavorites.delete(sequenceId);
      } else {
        newFavorites.add(sequenceId);
      }
      return newFavorites;
    });
    enqueueSnackbar(`${selectedSequence?.title} sequence favorited`, { variant: 'success' });
    toggleFavoriteSequence({ variables: { sequenceId } });
  };

  const sortedSequences = [...sequences].sort((a, b) => {
    const isAFavorite = favorites.has(a._id);
    const isBFavorite = favorites.has(b._id);
    return isAFavorite === isBFavorite ? 0 : isAFavorite ? -1 : 1;
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card sx={{ width: '100%', cursor: 'pointer' }}>
          <CardContent>
            <Divider />

            {sortedSequences.length === 0 ? (
              // Message for no archived sequences
              <Typography variant="h6" align="center" sx={{ py: 3 }}>
                No archived sequences available.
              </Typography>
            ) : (
              sortedSequences.map((sequence) => {
                const progressPercentage = getProgressPercentage(
                  sequence?.leadStats?.contactedLeads || 0,
                  sequence?.leadStats?.totalLeads || 0
                );

                return (
                  <Grid item xs={12} key={sequence._id}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ py: 2, px: 3, borderBottom: '1px solid #e0e0e0', '&:hover': { backgroundColor: '#F9FAFB' } }}
                      onClick={handleViewSequence}
                    >
                      {/* Sequence Info */}
                      <Stack direction="column" spacing={1}>
                        <Typography variant="h6">{sequence.title}</Typography>
                        <Typography variant="caption">
                          {`${sequence?.leadStats?.totalLeads || 0} Leads - ${
                            sequence?.leadStats?.contactedLeads || 0
                          } Completed, ${sequence?.leadStats?.remainingLeads || 0} Remaining`}
                        </Typography>

                        {/* Agents Avatar Group */}
                        <Row>
                          {sequence?.members?.length > 0 && (
                            <AvatarGroup max={maxVisibleAvatars} sx={{ cursor: 'pointer' }}>
                              {sequence.members.slice(0, maxVisibleAvatars).map((agent) => (
                                <Tooltip
                                  key={agent.id}
                                  title={`${agent.firstName} ${agent.lastName}`}
                                  sx={{
                                    tooltip: {
                                      borderRadius: '15px',
                                    },
                                  }}
                                  arrow
                                >
                                  <Avatar
                                    alt={`${agent.firstName} ${agent.lastName}`}
                                    sx={{ width: 30, height: 30, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                                  />
                                </Tooltip>
                              ))}
                              {sequence.members.length > maxVisibleAvatars && (
                                <Avatar
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    cursor: 'pointer',
                                    '&:hover': { opacity: 0.8 },
                                    fontSize: 16,
                                  }}
                                >
                                  +{sequence.members.length - maxVisibleAvatars}
                                </Avatar>
                              )}
                            </AvatarGroup>
                          )}
                        </Row>
                      </Stack>

                      {/* Leads Stats */}
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="body1">Total Leads: {sequence?.leadStats?.totalLeads || 0}</Typography>
                        <Typography variant="body1">Completed: {sequence?.leadStats?.contactedLeads || 0}</Typography>
                        <Typography variant="body1">Remaining: {sequence?.leadStats?.remainingLeads || 0}</Typography>
                      </Stack>

                      {/* Progress Bar */}
                      <Stack direction="column" spacing={1} sx={{ width: 150 }}>
                        <LinearProgress variant="determinate" value={progressPercentage} />
                        <Typography variant="caption">{`${sequence?.leadStats?.contactedLeads || 0} of ${
                          sequence?.leadStats?.totalLeads || 0
                        } leads called`}</Typography>
                      </Stack>

                      {/* Favorite Button */}
                      <IconButton
                        onClick={(e) => toggleFavorite(e, sequence._id)}
                        sx={{ color: favorites.has(sequence._id) ? 'gold' : 'grey' }}
                      >
                        {favorites.has(sequence._id) ? <StarIcon /> : <StarBorderIcon />}
                      </IconButton>

                      {/* Manage Button */}
                      <Button
                        onClick={(event) => handleManageClick(event, sequence)}
                        variant="outlined"
                        sx={{ color: '#080708' }}
                      >
                        Manage
                      </Button>

                      {/* Navigation Button */}
                      <IconButton onClick={(e) => handleViewSequence(e)} sx={{ color: '#080708' }}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </Stack>
                  </Grid>
                );
              })
            )}

            {/* Menu for Management Options */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem onClick={handleArchiveSequence}>Unarchive Sequence</MenuItem>
            </Menu>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ArchivedCoreSequenceOverview;

const Row = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  gap: 10,
  alignItems: 'center',
}));
