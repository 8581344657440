import { useEffect } from 'react';

const ExternalLoginRedirect = () => {
  useEffect(() => {
    const environment = process.env.REACT_APP_ENVIRONMENT;

    if (environment !== 'local') {
      window.location.href = process.env.REACT_APP_DEFAULT_LOGIN_URL
    } else {
    window.location.href = process.env.REACT_APP_DEFAULT_LOGIN_URL
    }
  }, []);

  return null; 
};

export default ExternalLoginRedirect;
