// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

export const navConfig = [
  {
    title: 'Sequence',
    path: '/sequence/app',
    icon: icon('ic_sequence'),
  },
  {
    title: 'Appointments',
    path: '/sequence/appointments',
    icon: icon('ic_calender_appointments'),
  },
  {
    title: 'Emails',
    path: '/sequence/email',
    icon: icon('ic_email'),
  },
  {
    title: 'Calls',
    path: '/sequence/calls',
    icon: icon('ic_phone'),
  },
  {
    title: 'Call Analytics',
    path: '/sequence/call-analytics',
    icon: icon('ic_analytics'),
  },
  {
    title: 'SMS',
    path: '/sequence/sms',
    icon: icon('ic_sms_icon'),
  },
  {
    title: 'Assets',
    path: '/sequence/assets',
    icon: icon('ic_tokens'),
  },
  {
    title: 'Training',
    path: '/sequence/training',
    icon: icon('ic_training'),
  }, 
  // {
  //   title: 'Campaigns',
  //   path: '/sequence/campaigns',
  //   icon: icon('ic_campaign'),
  // }, 
  {
    title: 'Triggers',
    path: '/sequence/triggers',
    icon: icon('ic_trigger'),
  }, 
  {
    title: 'Leaderboard',
    path: '/sequence/leaderboard',
    icon: icon('ic_leaderboard'),
  }, 
  {
    title: 'Companies',
    path: '/sequence/companies',
    icon: icon('ic_companies'),
  }
];

export const settingsOnly = 
  {
    title: 'Users',
    path: '/sequence/user',
    icon: icon('ic_agents'),
  };

export const twilioOnly = 
  {
    title: 'Twilio Call-Pooling',
    path: '/sequence/call-pool-playground',
    icon: icon('ic_phone'),
  };

export const adminOnly = [
 
];

