import { gql } from '@apollo/client';

export const FETCH_USER = gql`
  query {
    me {
      _id
      punchId
      email
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    getUser {
      _id
      punchId
      availability
      callPollingState
      email
      firstName
      gender
      lastName
      hubSpotId
      phoneNumber
      role
      socketId
      status
      username
      verified
      vonageUsername
      twilioAgent {
        _id
        assignedTwilioPhone
        name
        socketId
      }
      organization {
        _id
        name
      }
      created_at
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    getAllUsers {
      _id
      punchId
      firstName
      lastName
      email
      role
      status
      hubSpotId
      created_at
    }
  }
`;

export const GET_ALL_AGENTS = gql`
  query GetAllAgents {
    getAllAgents {
      _id
      punchId
      firstName
      lastName
      email
      role
      status
      hubSpotId
      created_at
    }
  }
`;

export const GET_USER_TOKEN = gql`
  query {
    getUserVonageToken
  }
`;
