import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  Typography,
  Grid,
  Divider,
  TextField,
  Radio,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import { Group as GroupIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { GET_CORE_SEQUENCE } from '../../../../graphql/queries/core.sequence';
import { fNumber } from '../../../../utils/formatNumber';

const Step0 = ({ setSelectedSequence }) => {
  const {
    data: coreSequenceData,
    loading: coreSequenceLoading,
    error: coreSequenceError,
  } = useQuery(GET_CORE_SEQUENCE);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedId, setSelectedId] = useState(null);

  const handleUploadToSequence = (sequenceId) => {
    setSelectedSequence(sequenceId);
    setSelectedId(sequenceId);
  };

  const filteredSequences = coreSequenceData?.getCoreSequence?.filter((sequence) =>
    sequence.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: '20px', margin: '0 auto' }}>
      <Typography variant="h5" fontWeight="bold" color="grey.800" textAlign="center">
      Select a Sequence for Lead Upload
      </Typography>
      <Divider sx={{ margin: '16px 0' }} />

      <TextField
        variant="outlined"
        placeholder="Search Sequences..."
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          marginBottom: '20px',
          borderRadius: '5px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#3f51b5',
            },
            '&:hover fieldset': {
              borderColor: '#1e88e5',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1e88e5',
            },
          },
        }}
      />

      {coreSequenceLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '150px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : coreSequenceError ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            height: '150px',
          }}
        >
          <Typography variant="body2" color="error" textAlign="center">
            Error loading sequences. Please try again later.
          </Typography>
        </Box>
      ) : filteredSequences.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
            height: '150px',
          }}
        >
          <Typography variant="body2" color="grey.600" textAlign="center">
            No sequences found for your search criteria.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredSequences.map((sequence) => (
            <Grid item xs={12} sm={6} md={4} key={sequence._id}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  boxShadow: 3,
                  borderRadius: '10px',
                  backgroundColor: selectedId === sequence._id ? '#e3f2fd' : '#fff',
                  height: '160px',
                  transition: 'box-shadow 0.2s, background-color 0.2s',
                  '&:hover': {
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#F0F2F4', 
                  },
                }}
              >
                <CardContent>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedId === sequence._id}
                        onChange={() => handleUploadToSequence(sequence._id)}
                        sx={{ color: '#3f51b5' }}
                      />
                    }
                    label={
                      <Tooltip title={sequence.title} arrow>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          color="grey.800"
                          noWrap 
                        >
                          {sequence.title.length > 25 ? `${sequence.title.slice(0, 25)}...` : sequence.title }
                        </Typography>
                      </Tooltip>
                    }
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                    <GroupIcon fontSize="small" sx={{ color: '#3f51b5', marginRight: '4px' }} />
                    <Typography variant="body2" color="grey.600">
                      Total Leads: {fNumber(sequence?.leadStats?.totalLeads || 0)}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <ArrowForwardIcon
                    onClick={() => handleUploadToSequence(sequence._id)}
                    sx={{
                      color: '#3f51b5',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#1e88e5',
                      },
                    }}
                  />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <br/>
    </Box>
  );
};

export default Step0;
