import { Helmet } from 'react-helmet-async';
// @mui
import { Breadcrumbs, Button, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// sections

import { useLazyQuery, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import { GET_ALL_CONTACTS, GET_ALL_LEADS } from '../graphql/queries/lead';
import { GET_ALL_PITCH } from '../graphql/queries/pitch';
import CompaniesTable from '../sections/@dashboard/company/CompaniesTable';
import ContactTable from '../sections/@dashboard/contact/ContactTable';
import AddPitchDialog from '../sections/@dashboard/pitch/AddPitchDialog';
import PitchTable from '../sections/@dashboard/pitch/PitchTable';
import { LABELS } from '../utils/constants';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
         {children}
        </>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AllCompaniesPage() {
  const navigate = useNavigate();
  const { data: allLeads, loading: loadinAllLeads } = useQuery(GET_ALL_LEADS);
  const { data: allContacts, loading: loadingAllContacts } = useQuery(GET_ALL_CONTACTS);
  const [getPitches, { data: allPitches, loading: loadingAllPitches}] = useLazyQuery(GET_ALL_PITCH);
  const [leads, setLeads] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [newPitch, setNewPitch] = useState(null);
  const [pitches, setPitches] = useState([]);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [activePitch, setActivePitch] = useState(null);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (allLeads?.getAllLeads) {
      setLeads(allLeads?.getAllLeads);
    }
  }, [allLeads]);

  useEffect(() => {
    if (allContacts?.getAllContacts) {
      setContacts(allContacts?.getAllContacts);
    }
  }, [allContacts]);

  useEffect(() => {
    if (allPitches?.getPitch) {
      setPitches(allPitches?.getPitch);
    }
  }, [allPitches]);


  useEffect(() => {
    getPitches();
  }, [newPitch, getPitches]);

  const handleUpdatePitch = useCallback((pitch) => {
    setActivePitch(pitch);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Typography key="3" color="text.primary">
      All Companies
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> All Companies | Sales App </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <Stack direction="column" spacing={0} alignItems="flex-start">
                <Typography variant="h3">{LABELS.Companies}</Typography>
                <Typography variant="caption">{LABELS.CompanyDescription}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  startIcon={<img width="30" src="/assets/icons/ic_leads.svg" alt="import leads" />}
                  onClick={() => navigate('/sequence/companies/import')}
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    border: '1px solid rgba(145, 158, 171, 0.24)',
                  }}
                >
                  Import leads
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Companies" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  {/* <Tab label="Pitch" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {loadinAllLeads ? (
                  <Loader />
                ) : (
                  <CompaniesTable leads={leads} />
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {loadingAllContacts ? (
                  <Loader />
                ) : (
                  <ContactTable contacts={contacts} />
                )}
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={2}>
                {loadingAllPitches ? (
                  <Loader />
                ) : (
                  <PitchTable pitches={pitches} handleUpdatePitch={handleUpdatePitch} />
                )}
              </CustomTabPanel> */}
            </Box>
          </Grid>
        </Grid>
        <AddPitchDialog open={open} handleClose={handleClose} contacts={contacts} setPitches={setNewPitch} activePitch={activePitch}/>
      </Container>
    </>
  );
}
