import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from '../components/breadcrumbs/HeaderBreadcrumbs';
import Iconify from '../components/iconify';
import TriggersList from '../sections/@dashboard/triggers/TriggerList';

// ----------------------------------------------------------------------

export default function TriggersPage() {
  return (
    <>
      <Helmet>
        <title> Triggers | Sales App </title>
      </Helmet>
      <Container maxWidth={'lg'}>
      <HeaderBreadcrumbs
          heading="Triggers List"
          links={[
            { name: 'Dashboard', href: '' },
            { name: 'List', href: '' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={'/sequence/create-trigger'}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Trigger
            </Button>
          }
        />
        <TriggersList />
      </Container>
    </>
  );
}
