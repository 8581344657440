import { Helmet } from 'react-helmet-async';
// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
// components
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
// import EmailIcon from '@mui/icons-material/Email';
// sections
import { useLazyQuery, useQuery } from '@apollo/client';
import { CallOutlined, EmailRounded, ExpandMoreOutlined } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_LEAD_CALL_LOGS, GET_LEAD_EMAIL_LOGS } from '../graphql/mutations/log';
import { GET_ALL_LEADS, GET_LEAD_CONTACTS, GET_LEAD_OWNER } from '../graphql/queries/lead';
import EditCompanyDetails from '../sections/@dashboard/company/EditCompanyDetails';
import ContactTable from '../sections/@dashboard/contact/ContactTable';
import Notes from '../sections/@dashboard/details/Notes';
import { fDateTime } from '../utils/formatTime';
// ----------------------------------------------------------------------

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CompanyDetailsPage() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: allLeads, loading: loadinAllLeads } = useQuery(GET_ALL_LEADS);
  const [getLeadContacts, { loading: loadingGettingContacts, data: leadsContact }] = useLazyQuery(GET_LEAD_CONTACTS);
  const [getLeadEmailLogs, { loading: loadingGettingEmailLogs, data: emailLogs }] = useLazyQuery(GET_LEAD_EMAIL_LOGS);
  const [getLeadCallLogs, { loading: loadingGettingCallLogs, data: callLogs }] = useLazyQuery(GET_LEAD_CALL_LOGS);
  const [findLeadOwer, { data: leadOwner }] = useLazyQuery(GET_LEAD_OWNER);
  const [callLogsData, setCallLogsData] = useState([]);
  const [emailLogsData, setEmailLogsData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [value, setValue] = useState(0);
  const activeLead = useMemo(() => allLeads?.getAllLeads?.find(lead => lead?._id === slug) || null, [ slug, allLeads?.getAllLeads]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  useEffect(() => {
    if (callLogs?.getLeadCallLogs) {
      setCallLogsData(callLogs?.getLeadCallLogs);
    }
  }, [callLogs?.getLeadCallLogs]);

  useEffect(() => {
    if (emailLogs?.getLeadEmailLogs) {
      setEmailLogsData(emailLogs?.getLeadEmailLogs);
    }
  }, [emailLogs?.getLeadEmailLogs]);

  useEffect(() => {
    if (activeLead?.simplifiedCompanyName) {
      getLeadContacts({
        variables: {
          company: activeLead?.simplifiedCompanyName,
        },
      });
    }
  }, [activeLead?.simplifiedCompanyName, getLeadContacts]);

  useEffect(() => {
    if (activeLead) {
      getLeadEmailLogs({
        variables: {
          lead: activeLead._id,
        },
      });
    }
  }, [activeLead, getLeadEmailLogs]);

  useEffect(() => {
    if (activeLead) {
      getLeadCallLogs({
        variables: {
          lead: activeLead._id,
        },
      });
    }
  }, [activeLead, getLeadCallLogs]);

  useEffect(() => {
    if (activeLead) {
      findLeadOwer({
        variables: {
          contactOwnerId: activeLead?.contactOwnerId,
        },
      });
    }
  }, [activeLead, findLeadOwer]);

  useEffect(() => {
    if (leadsContact?.getLeadContacts) {
      setContacts(leadsContact?.getLeadContacts);
    }
  }, [leadsContact?.getLeadContacts]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Sequence
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/sequence/companies">
      Companies
    </Link>,
    <Typography key="3" color="text.primary">
       {activeLead?.simplifiedCompanyName}
    </Typography>,
  ];

  return (
    <>
      <Helmet>
        <title> Sequence | Sales App </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>

              <Button
                size="small"
                startIcon={<img src="/assets/icons/ic_calender.svg" width="20" alt="call" />}
                onClick={() => navigate('/sequence/companies')}
              >
                See all companies
              </Button>
            </Stack>
          </Grid>
          {loadinAllLeads &&   <Grid item xs={12} sm={12} md={12}> <CircularProgress /> </Grid>}

          {activeLead && (
            <>
              <Grid item xs={12} sm={12} md={8}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar sx={{ width: 100, height: 100 }} src={activeLead?.companyLogo} />
                  <Stack direction="column">
                    {activeLead?.simplifiedCompanyName && (
                      <Typography variant="h3">{activeLead?.simplifiedCompanyName}</Typography>
                    )}
                    <Typography variant="body1">Manage your flow for this reach campaigns</Typography>
                    {leadOwner?.getLeadOwner && (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption">
                          {`Assigned to `}
                          <span style={{ color: '#3772FF' }}>
                            {` ${leadOwner?.getLeadOwner?.firstName} ${leadOwner?.getLeadOwner?.lastName}`}
                          </span>
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Prospects" {...a11yProps(1)} />
                  <Tab label="Activities" {...a11yProps(2)} />
                  <Tab label="Notes" {...a11yProps(3)} />
                </Tabs>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <CustomTabPanel value={value} index={0}>
                    <EditCompanyDetails targetCompany={activeLead} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <ContactTable contacts={contacts} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    <Stack direction="column" spacing={2}>
                      {loadingGettingCallLogs && <CircularProgress />}
                      {callLogsData?.map((log) => (
                        <Accordion key={log._id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                              <CallOutlined />
                              <Typography>
                                {log?.name} ({log?.phoneNumber})
                              </Typography>
                              <Typography>{`on ${fDateTime(log?.createdAt)}`}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="column"
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">Name: {log?.name}</Typography>
                              <Typography variant="body1">Phone Number: {log?.phoneNumber}</Typography>
                              <Typography variant="body1">Call Done: {log?.callDone}</Typography>
                              <Typography variant="body1">Talk Done: {log?.talkDone}</Typography>
                              <Typography variant="body1">Voicemail Done: {log?.voicemailDone}</Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                      {loadingGettingEmailLogs && <CircularProgress />}
                      {emailLogsData?.map((log) => (
                        <Accordion key={log._id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                              <EmailRounded />
                              <Typography>
                                {log?.name} ({log?.email})
                              </Typography>
                              <Typography>{`on ${fDateTime(log?.createdAt)}`}</Typography>
                            </Stack>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack
                              direction="column"
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography variant="body1">Name: {log?.name}</Typography>
                              <Typography variant="body1">Email: {log?.email}</Typography>
                              <Typography variant="body1">Email Sent: {log?.emailSent}</Typography>
                              <Typography variant="body1">Email Personalized: {log?.emailPersonalized}</Typography>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Stack>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={3}>
                      <Notes activeLead={activeLead} />
                  </CustomTabPanel>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
