import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ADD_MANUAL_CONNECTIONS } from '../../graphql/mutations/call.analytics';

const ManualConnectionDialog = ({ open, onClose, agentId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const initialState = {
    date: getTodayDate(),
    timeSlot: '3 PM - 4 PM',
    connections: 0,
    agentId,
  };

  const [manualConnection, setManualConnection] = useState(initialState);
  const [addManualConnection, { loading, error }] = useMutation(ADD_MANUAL_CONNECTIONS);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newValue = name === 'connections' ? parseFloat(value) : value;
    setManualConnection((prevState) => ({ ...prevState, [name]: newValue }));
  };

  const handleSubmit = async () => {
    try {
      const selectedDate = new Date(manualConnection.date);
      const hours = selectedDate.getHours();
      const minutes = selectedDate.getMinutes();
      const localDateTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), hours, minutes);
      const formattedDate = localDateTime.toISOString();

      const inputData = {
        ...manualConnection,
        date: formattedDate,
      };

      await addManualConnection({
        variables: {
          input: inputData,
        },
      });

      console.log('Manual connection added:', inputData);
      enqueueSnackbar('Logged successfully', { variant: 'success' });
      onClose();
    } catch (e) {
      console.error('Error adding manual connection:', e);
      enqueueSnackbar('Error adding manual connection:', { variant: 'error' });
    }
  };

  const handleClose = () => {
    setManualConnection(initialState);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Manual Connection</DialogTitle>
      <DialogContent>
        <TextField
          label="Date"
          type="date"
          name="date"
          value={manualConnection.date}
          onChange={handleInputChange}
          sx={{ mt: 1, mb: 2 }}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Time Slot"
          name="timeSlot"
          value={manualConnection.timeSlot}
          onChange={handleInputChange}
          fullWidth
          select
          SelectProps={{
            native: true,
          }}
          sx={{ mb: 2 }}
        >
          {[
            '12 PM - 1 PM',
            '1 PM - 2 PM',
            '2 PM - 3 PM',
            '3 PM - 4 PM',
            '4 PM - 5 PM',
            '5 PM - 6 PM',
            '6 PM - 7 PM',
            '7 PM - 8 PM',
            '8 PM - 9 PM',
          ].map((slot) => (
            <option key={slot} value={slot}>
              {slot}
            </option>
          ))}
        </TextField>
        <TextField
          label="Connections"
          type="number"
          name="connections"
          value={manualConnection.connections}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading}>
          {loading ? 'Adding...' : 'Add'}
        </Button>
      </DialogActions>
      {error && <p>Error adding manual connection: {error.message}</p>}
    </Dialog>
  );
};

export default ManualConnectionDialog;
