import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { useLazyQuery, useQuery } from '@apollo/client';
import { Call } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Divider, IconButton, MenuItem, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from '../../../hooks/use-boolean';
import { useResponsive } from '../../../hooks/use-responsive';

// components
import { GET_RETRYABLE_CALLS } from '../../../graphql/queries/log';
import { GET_ALL_USERS } from '../../../graphql/queries/user';
import { CALL_STATUSES } from '../../../utils/constants';
import { isAgent } from '../../../utils/helperFunctions';
import CallDetailsRetry from '../call-details-retry';
import CallInitiateManual from '../call-initiate-manual';
import CallList from '../call-list';

// ----------------------------------------------------------------------

export default function RetryableCallsView() {
  const { data: allUsers } = useQuery(GET_ALL_USERS);
  const [state, setState] = useState({
    calls: {
      byId: {},
      allIds: []
    },
    call: null,
    labelsLoading: false,
    mailsLoading: false,
    mailLoading: false,
    mailsError: false,
    mailError: false,
    mailsEmpty: true
  });

  const [selectedLabelId, setSelectedLabelId] = useState(0);
  const [agents, setAgents] = useState([]);
  const [status, setStatus] = useState('all');
  const limit = 100;
  const [skip, setSkip] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [getRetryableCallsPaginate, { data: paginatedData, loading }] = useLazyQuery(GET_RETRYABLE_CALLS);



  const selectedCallId = 'inbox';

  const router = useNavigate();

  const [selectedAgent, setSelectedAgent] = useState('all');

  const mdUp = useResponsive('up', 'md');

  const openNav = useBoolean();

  const openCall = useBoolean();

  const openCompose = useBoolean();

  const { mailsError, mailError } = state;

  const firstCallId = '';

  const handleToggleCompose = useCallback(() => {
    if (openNav.value) {
      openNav.onFalse();
    }
    openCompose.onToggle();
  }, [openCompose, openNav]);

  const handleChange = (event) => {
    setSelectedAgent(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleCloseInteraction = useCallback((data) => {
    openCompose.onFalse();
  }, [openCompose]);

  const handleClickCall = useCallback((mailId) => {
    if (!mdUp && mailId) {
      openCall.onFalse();
    }
  },
    [openCall, mdUp]
  );

  const onClickCall = useCallback((mailId) => {
    setState((prevState) => ({
      ...prevState,
      call: prevState.calls.byId[mailId]
    }));
    const index = state.calls.allIds.indexOf(mailId);
    setSelectedLabelId(index);
  }, [state.calls.allIds]);

  useEffect(() => {
    if (allUsers?.getAllUsers) {
      const myAgents = allUsers?.getAllUsers?.filter((dt) => isAgent(dt.role));
      setAgents(myAgents);
    }
  }, [allUsers?.getAllUsers]);

  useEffect(() => {
    if (paginatedData?.fetchRetryableCalls) {
      const byId = {};
      const allIds = [];
      paginatedData?.fetchRetryableCalls?.forEach((call) => {
        byId[call._id] = {
          id: call._id,
          to: call?.to,
          from: {
            name: `${call?.agentId?.user?.firstName} ${call?.agentId?.user?.lastName}`,
            email: call?.user?.email,
            avatarUrl: null
          },
          folder: 'inbox',
          labelIds: [],
          attachments: [],
          createdAt: call?.createdAt,
          subject: call?.leadId?.firstName,
          isUnread: false,
          isImportant: true,
          isStarred: true,
          ...call
        };
        allIds.push(call._id);
      });

      setState((prevState) => ({
        ...prevState,
        calls: {
          byId,
          allIds
        },
        call: byId[allIds[0]],
        mailsLoading: false,
        mailsError: false,
        mailsEmpty: !allIds.length
      }));
    }
  }, [paginatedData]);

  useEffect(() => {
    if (mailsError || mailError) {
      router.push('/');
    }
  }, [mailError, mailsError, router]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getRetryableCallsPaginate({
        variables: {
          input: {
            limit,
            skip,
            keyword,
            userID: selectedAgent === 'all' ? null : selectedAgent,
            status: status === 'all' ? null : status
          }
        }
      });
    }, 1000);

    return () => {
      clearTimeout(handler);
    }
  }, [getRetryableCallsPaginate, limit, skip, status, selectedAgent, keyword]);

  const dataCount = useMemo(() => paginatedData?.fetchRetryableCalls?.length || 0, [paginatedData?.fetchRetryableCalls]);

  useEffect(() => {
    if (firstCallId) {
      handleClickCall(firstCallId);
    }
  }, [firstCallId, handleClickCall]);

  useEffect(() => {
    if (openCompose.value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompose.value]);

  const renderCallList = (
    <CallList
      calls={state.calls}
      loading={loading}
      //
      keyword={keyword}
      setKeyword={setKeyword}
      openCall={openCall.value}
      onCloseCall={openCall.onFalse}
      onClickCall={onClickCall}
      //
      selectedLabelId={selectedLabelId}
      selectedCallId={selectedCallId}
    />
  );

  const renderCallDetails = (
    <CallDetailsRetry
      call={state.call}
      renderLabel={(id) => state.labels.filter((label) => label.id === id)[0]}
    />

  );

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`Page -${(skip / 100) + 1}`}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <TextField select size="small" value={selectedAgent} onChange={handleChange}>
            <MenuItem value="all">All agents</MenuItem>
            {agents?.map((user) => (
              <MenuItem value={user?._id} key={user?._id}>
                {user?.firstName} {user?.lastName}
              </MenuItem>
            ))}
          </TextField>
          <TextField select size="small" value={status} onChange={handleChangeStatus}>
            <MenuItem value="all">All status</MenuItem>
            {CALL_STATUSES?.map((stat) => (
              <MenuItem value={stat?.value} key={stat.value}>
                {stat?.label}
              </MenuItem>
            ))}
          </TextField>
          <Button variant="contained" onClick={handleToggleCompose} startIcon={<Call />}>
            New Call
          </Button>
          <IconButton
            disabled={(skip < 100) || loading}
            onClick={() => {
              setSkip(skip - 100);
            }}
            aria-label="refresh">
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setSkip(skip + 100);
            }}
            disabled={(dataCount < 100) || loading}
            aria-label="refresh">
            <ArrowForwardIosIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Stack
        spacing={1}
        direction="row"
        // flexGrow={1}
        sx={{
          height: {
            xs: '72vh',
            md: 'calc(100vh - 100px)',
          },
        }}
      >
        {renderCallList}
        <Divider orientation="vertical" flexItem />
        {renderCallDetails}
      </Stack>

      {openCompose.value && (
        <CallInitiateManual
          fullScreen={openCompose}
          onCloseCompose={openCompose.onFalse}
        />
      )}
    </>
  );
}
