import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from '../components/breadcrumbs/HeaderBreadcrumbs';
import Iconify from '../components/iconify';
import SequencePromptList from '../sections/@dashboard/prompts/SequencePromptList';

// ----------------------------------------------------------------------

export default function SequencePromptPage() {
 const { sequenceId, sequenceTitle, totalSteps } = useParams();
  return (
    <>
      <Helmet>
        <title> Sequence Prompt | Sales App </title>
      </Helmet>
      <Container maxWidth={'lg'}>
      <HeaderBreadcrumbs
          heading="Sequence Prompt List"
          links={[
            { name: 'Sequence Overview', href: `/sequence/sequence-overview/${sequenceId}/${sequenceTitle}/${totalSteps}` },
            { name: 'Prompt List', href: '' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={`/sequence/create-update-prompt/${sequenceId}/${sequenceTitle}/${totalSteps}`}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Prompt
            </Button>
          }
        />
        <SequencePromptList />
      </Container>
    </>
  );
}

