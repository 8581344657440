import { gql } from '@apollo/client';

export const SEND_EMAIL = gql`
  mutation sendEmail($input: EmailInput!) {
    sendEmail(input: $input) {
      _id
      subject
      recipients
      attachments
    }
  }
`;

export const GET_EMAILS = gql`
  query GetEmails($skip: Float, $limit: Float) {
    getEmails(skip: $skip, limit: $limit) {
      _id
      subject
      attachments
      recipients
      message
      createdAt
      names
      companies
      isAutoMailer
      user {
        firstName
        lastName
        email
      }
      coreSequence {
        _id
      }
      lead {
        _id
      }
      children {
        _id
        subject
        attachments
        recipients
        message
        createdAt
        names
        companies
        isAutoMailer
        user {
          firstName
          lastName
          email
        }
        coreSequence {
          _id
        }
        lead {
          _id
        }
      }
    }
  }
`;

export const GET_EMAIL_STATS = gql`
  query Query($sequenceId: String!) {
    getEmailStats(sequenceId: $sequenceId)
  }
`;
