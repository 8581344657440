import PropTypes from 'prop-types';
// @mui
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { alpha, darken, lighten, styled } from '@mui/material/styles';
// utils
// hooks
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { useBoolean } from '../../hooks/use-boolean';
// components
import Editor from '../../components/editor/editor';
import EmptyContent from '../../components/empty-content';
import FileThumbnail from '../../components/file-thumbnail';
import Iconify from '../../components/iconify';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import TextMaxLine from '../../components/text-max-line';
import { fDateTime } from '../../utils/formatTime';
import { scrollbarStyle } from '../../utils/cssStyles';
import { truncateHtmlText } from '../../utils/textHelper';
import { createParams, filePath, s3 } from '../../utils/s3';
import { SEND_EMAIL } from '../../graphql/queries/email';

// ----------------------------------------------------------------------

export default function MailDetails({ mail, renderLabel, state, setState }) {
  const showAttachments = useBoolean(true);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const emailListRef = useRef(null);
  const [visibleContentIndex, setVisibleContentIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [subject, setSubject] = useState('');
  const truncatedMessage = truncateHtmlText(mail?.message || '', 90);
  const emailArray = useMemo(() => {
    return mail?.to?.map((person) => person.email);
  }, [mail?.to]);

  const [sendEmail, { data: emailData, loading: emailLoading }] = useMutation(SEND_EMAIL);

  useEffect(() => {
    if (mail) {
      setRecipients(emailArray);
      setSubject(mail.subject);
    }
  }, [emailArray]);

  useEffect(() => {
    if (emailListRef?.current && state?.mail?.children && emailLoading) {
      emailListRef.current.scrollTop = emailListRef.current.scrollHeight;
    }
  }, [state?.mail?.children]);

  const handleContentClick = (index) => {
    setVisibleContentIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleChangeMessage = useCallback((value) => {
    const cleanedValue = value.trim();

    if (cleanedValue === '<p><br></p>' || cleanedValue === '') {
      setMessage('');
    } else {
      setMessage(value);
    }
  }, []);

  const handleThreadClick = () => {};

  const onSubmit = useCallback(async () => {
    try {
      if (recipients.length < 1) {
        enqueueSnackbar('Please add at least one recipient to send the email.', {
          variant: 'error',
          autoHideDuration: 2000,
        });
        return;
      }

      const data = {
        recipients,
        subject,
        message,
        attachments,
        lead: mail.leadId,
        coreSequence: mail?.sequenceId || '',
        isAutoMailer: false,
        parentEmail: mail?.id || '',
      };

      setState((prevState) => ({
        ...prevState,
        mail: {
          ...prevState.mail,
          children: [
            ...prevState.mail.children,
            {
              ...data,
              _id: Date.now().toString(),
              user: {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
              },
            },
          ],
        },
      }));

      const emailData = await sendEmail({ variables: { input: data } });
      if (emailData) {
        enqueueSnackbar('Email sent successfully', { variant: 'success', autoHideDuration: 3000 });
        setMessage('');
      }
    } catch (error) {
      enqueueSnackbar('Failed to send the email. Please try again later.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
      console.error('Error sending email:', error);
    } finally {
      setMessage('');
    }
  }, [subject, message, attachments, sendEmail]);

  if (!mail) {
    return (
      <EmptyContent
        title="No Conversation Selected"
        description="Select a conversation to read"
        imgUrl="/assets/icons/empty/ic_email_selected.svg"
        sx={{
          borderRadius: 1.5,
          bgcolor: 'background.default',
        }}
      />
    );
  }

  const renderHead = (
    <Stack direction="row" alignItems="center" flexShrink={0} sx={{ height: 56, pl: 2, pr: 1 }}>
      <Stack direction="row" spacing={1} flexGrow={1}>
        {mail.labelIds.map((labelId) => {
          const label = renderLabel(labelId);

          return label ? (
            <Label
              key={label.id}
              sx={{
                bgcolor: alpha(label.color, 0.16),
                color: (theme) =>
                  theme.palette.mode === 'light' ? darken(label.color, 0.24) : lighten(label.color, 0.24),
              }}
            >
              {label.name}
            </Label>
          ) : null;
        })}
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography sx={{ color: 'black', fontSize: 15 }}>{`To: `}</Typography>
          {mail.to.map((person) => (
            <Typography key={person.email} sx={{ color: 'text.secondary', display: 'inline', fontSize: 13 }}>
              {`${person.email}${mail.to.indexOf(person) !== mail.to.length - 1 ? ', ' : ''}`}
            </Typography>
          ))}
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center">
        <Checkbox
          color="warning"
          icon={<Iconify icon="eva:star-outline" />}
          checkedIcon={<Iconify icon="eva:star-fill" />}
          checked={mail.isStarred}
        />

        <Checkbox
          color="warning"
          icon={<Iconify icon="material-symbols:label-important-rounded" />}
          checkedIcon={<Iconify icon="material-symbols:label-important-rounded" />}
          checked={mail.isImportant}
        />

        <Tooltip title="Archive">
          <IconButton>
            <Iconify icon="solar:archive-down-minimlistic-bold" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Mark Unread">
          <IconButton>
            <Iconify icon="fluent:mail-unread-20-filled" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Trash">
          <IconButton>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Tooltip>

        <IconButton>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>
    </Stack>
  );

  const renderSubject = (
    <Stack spacing={2} direction="row" flexShrink={0} sx={{ p: 2 }}>
      <TextMaxLine variant="subtitle2" sx={{ flexGrow: 1 }}>
        Re: {mail.subject}
      </TextMaxLine>

      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <IconButton size="small">
            <Iconify width={18} icon="solar:reply-bold" />
          </IconButton>

          <IconButton size="small">
            <Iconify width={18} icon="solar:multiple-forward-left-broken" />
          </IconButton>

          <IconButton size="small">
            <Iconify width={18} icon="solar:forward-bold" />
          </IconButton>
        </Stack>

        <Typography variant="caption" noWrap sx={{ color: 'text.disabled' }}>
          {fDateTime(mail.createdAt)}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSender = (
    <Stack
      flexShrink={0}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: (theme) => theme.spacing(2, 2, 1, 2),
        borderBottom: '1px solid #E8EBEE',
        cursor: 'pointer',
        backgroundColor: visibleContentIndex === 0 ? '#f2f4f7' : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
      onClick={() => handleContentClick(0)}
    >
      <Stack direction="row" alignItems="center" sx={{ width: '85%', minWidth: '85%'}}>
        <Avatar
          alt={mail.from.name}
          src={`${mail.from.avatarUrl}`}
          sx={{
            width: 30,
            height: 30,
            mr: 2,
          }}
        >
          {mail.from.name.charAt(0).toUpperCase()}
        </Avatar>
        
        <ListItemText
          sx={{ maxWidth: '90%' }}
          primary={
            <>
              <Stack direction="row" gap={0.5} alignItems="center">
                <Typography sx={{ fontSize: 14 }}>
                  {mail?.from?.name === 'undefined  undefined' ? 'Unknown' : mail?.from?.name || 'Unknown'}
                </Typography>

                <Box component="span" sx={{ typography: 'body2', color: 'black' }}>
                  {` <${mail?.from?.email || 'Unknown'}>`}
                </Box>
              </Stack>
            </>
          }
          secondary={
            <Stack spacing={1}>
              {visibleContentIndex === 0 ? (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>{`To: `}</Typography>
                  {mail.to.map((person) => (
                    <Typography key={person.email} sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
                      {`${person.email}, `}
                    </Typography>
                  ))}
                </Stack>
              ) : (
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {truncatedMessage}
                </Typography>
              )}
            </Stack>
          }
          secondaryTypographyProps={{
            mt: 0.5,
            noWrap: true,
            component: 'span',
            typography: 'caption',
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center">
        <IconButton onClick={handleThreadClick}>
          <Iconify icon="eva:message-square-outline" />
        </IconButton>
        <IconButton size="small">
          <Iconify width={18} icon="solar:reply-bold" />
        </IconButton>
        <IconButton>{visibleContentIndex === 0 ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </Stack>
    </Stack>
  );

  const renderSubSender = (subMail, index) => {
    const name = `${subMail?.user?.firstName}  ${subMail?.user?.lastName}`;
    const truncatedSubMessage = truncateHtmlText(subMail?.message || '', 90);
    return (
      <Stack
        flexShrink={0}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          p: (theme) => theme.spacing(2, 2, 1, 2),
          borderBottom: '1px solid #E8EBEE',
          cursor: 'pointer',
          backgroundColor: visibleContentIndex === index + 1 ? '#f2f4f7' : 'transparent',
          transition: 'background-color 0.3s ease',
        }}
        onClick={() => handleContentClick(index + 1)}
      >
         <Stack direction="row" alignItems="center" sx={{ width: '85%', minWidth: '85%'}}>
          <Avatar
            alt={name}
            src={`${subMail?.user?.avatarUrl}`}
            sx={{
              width: 30,
              height: 30,
              mr: 2,
            }}
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>

          <ListItemText
            sx={{ maxWidth: '90%' }}
            primary={
              <>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Typography sx={{ fontSize: 14 }}>{name === 'undefined  undefined' ? 'Unknown' : name || 'Unknown'}</Typography>
                  <Box component="span" sx={{ typography: 'body2', color: 'black' }}>
                    {` <${subMail?.user?.email || 'Unknown'}>`}
                  </Box>
                </Stack>
              </>
            }
            secondary={
              <Stack spacing={1}>
                {visibleContentIndex === index + 1 ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>{`To: `}</Typography>
                    {mail.to.map((person) => (
                      <Typography key={person.email} sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
                        {`${person.email}, `}
                      </Typography>
                    ))}
                  </Stack>
                ) : (
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: 12,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {truncatedSubMessage}
                  </Typography>
                )}
              </Stack>
            }
            secondaryTypographyProps={{
              mt: 0.5,
              noWrap: true,
              component: 'span',
              typography: 'caption',
            }}
          />
        </Stack>

        <Stack direction="row" alignItems="center">
          <IconButton onClick={handleThreadClick}>
            <Iconify icon="eva:message-square-outline" />
          </IconButton>
          <IconButton size="small">
            <Iconify width={18} icon="solar:reply-bold" />
          </IconButton>
          <IconButton>{visibleContentIndex === index + 1 ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Stack>
      </Stack>
    );
  };

  const renderAttachments = (
    <Stack
      spacing={1}
      sx={{
        p: 1,
        borderRadius: 1,
        bgcolor: 'background.neutral',
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ButtonBase
          onClick={showAttachments.onToggle}
          sx={{
            color: 'text.secondary',
            typography: 'caption',
            borderRadius: 0.5,
          }}
        >
          <Iconify icon="eva:attach-2-fill" sx={{ mr: 0.5 }} />
          {mail.attachments.length} attachments
          <Iconify
            icon={showAttachments.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            width={16}
            sx={{ ml: 0.5 }}
          />
        </ButtonBase>

        <Button startIcon={<Iconify icon="eva:cloud-download-fill" />}>Download</Button>
      </Stack>

      <Collapse in={showAttachments.value} unmountOnExit timeout="auto">
        <Stack direction="row" flexWrap="wrap" spacing={1}>
          {mail.attachments.map((attachment) => (
            <Stack
              key={attachment}
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 40,
                height: 40,
                flexShrink: 0,
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'background.neutral',
              }}
            >
              <FileThumbnail
                tooltip
                imageView
                file={attachment}
                onDownload={() => window.open(attachment, '_blank')}
                sx={{ width: 24, height: 24 }}
              />
            </Stack>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );

  const renderContent = (
    <Box sx={{ py: 3, pt: 0, pb: 0, overflow: 'hidden', flexGrow: 1 }}>
      <Scrollbar>
        <Box sx={{ p: 2, pt: 0, overflow: 'hidden' }}>
          <div dangerouslySetInnerHTML={{ __html: mail.message }} />
        </Box>
      </Scrollbar>
    </Box>
  );

  const renderSubContent = (subMail) => {
    return (
      <Box sx={{ py: 3, pt: 0, pb: 0, overflow: 'hidden', flexGrow: 1 }}>
        <Scrollbar>
          <Box sx={{ p: 2, pt: 0, overflow: 'hidden' }}>
            <div dangerouslySetInnerHTML={{ __html: subMail.message }} />
          </Box>
        </Scrollbar>
      </Box>
    );
  };

  const renderEditor = (
    <Stack
      spacing={2}
      sx={{
        p: (theme) => theme.spacing(2, 2, 2, 2),
        minHeight: '180px',
        marginTop: 'auto',
      }}
    >
      <Editor simple id="reply-mail" value={message} onChange={handleChangeMessage} />

      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center" flexGrow={1}>
          <IconButton>
            <Iconify icon="solar:gallery-add-bold" />
          </IconButton>

          <IconButton>
            <Iconify icon="eva:attach-2-fill" />
          </IconButton>
        </Stack>

        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={!message || recipients.length < 1 || emailLoading}
          endIcon={emailLoading ? <CircularProgress size={20} /> : <Iconify icon="iconamoon:send-fill" />}
          sx={{ marginLeft: 'auto' }}
        >
          {emailLoading ? 'Sending...' : 'Send'}
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <Stack
      flexGrow={1}
      sx={{
        width: 1,
        minWidth: 0,
        borderRadius: 1.5,
        bgcolor: 'background.default',
        border: '0.5px dashed grey',
      }}
    >
      {renderHead}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderSubject}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Box sx={{ overflowY: 'auto', ...scrollbarStyle.scrollbar }} ref={emailListRef}>
        {renderSender}

        {!!mail.attachments.length && <Stack sx={{ px: 2 }}> {renderAttachments} </Stack>}

        <Collapse in={visibleContentIndex === 0} timeout="auto" sx={{ overflow: 'hidden' }}>
          {renderContent}
        </Collapse>
        {mail.children.length > 0 &&
          mail.children.map((subMail, index) => (
            <Fragment key={index}>
              {renderSubSender(subMail, index)}
              <Collapse in={visibleContentIndex === index + 1} timeout="auto" sx={{ overflow: 'hidden' }}>
                {renderSubContent(subMail)}
              </Collapse>
            </Fragment>
          ))}
      </Box>

      {renderEditor}
    </Stack>
  );
}

MailDetails.propTypes = {
  mail: PropTypes.object,
  renderLabel: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
};
