import PropTypes from 'prop-types';
import { Stack, Typography, CircularProgress, Skeleton } from '@mui/material';
import { fShortenNumber } from '../../utils/formatNumber';
import Iconify from '../../components/iconify';

StepAnalytic.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  percent: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
};
export default function StepAnalytic({ title, total, icon, color, percent, loading}) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: 1, minWidth: 200 }}>
      <Stack alignItems="center" justifyContent="center" sx={{ position: 'relative' }}>
        <Iconify icon={icon} sx={{ color, width: 24, height: 24, position: 'absolute' }} />

        <CircularProgress
          variant="determinate"
          value={percent}
          size={56}
          thickness={4} 
          sx={{ color, opacity: 1 }}
        />

        <CircularProgress
          variant="determinate"
          value={100}
          size={56}
          thickness={4} 
          sx={{ color: 'grey.500', position: 'absolute', top: 0, left: 0, opacity: 0.2 }}
        />
      </Stack>

      <Stack spacing={0.5} sx={{ ml: 2 }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>{title}</Typography>
        {loading ?  <Skeleton variant="circular" width={25} height={25} /> : <Typography variant="subtitle2">
          {total === 0 ? 0 : fShortenNumber(total)}{' '}
        </Typography>}
        
      </Stack>
    </Stack>
  );
}

