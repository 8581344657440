import PropTypes from 'prop-types';
// @mui
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TEMPLATES_TYPE } from '../../../utils/constants';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

TemplateListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  type: PropTypes.string,
};

export default function TemplateListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  type,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  
  const headers = headLabel.map((headCell) => {
    if (headCell.label === 'Notes' && type !== TEMPLATES_TYPE.EMAIL) {
      return null;
    }

    if (headCell.label === TEMPLATES_TYPE.SMS && type !== TEMPLATES_TYPE.SMS) {
      return null;
    }

    return (
      <TableCell
        key={headCell.id}
        align={headCell.alignRight ? 'right' : 'left'}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          hideSortIcon
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          <b>{headCell.label}</b>
          {orderBy === headCell.id ? (
            <Box sx={{ ...visuallyHidden }}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  });

  return (
    <TableHead>
      <TableRow>{headers}</TableRow>
    </TableHead>
  );
}